import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

interface SubWindowStateContextType {
  windowObjectReferences: Array<Window>;
  setWindowObjectReferences: Dispatch<SetStateAction<Array<Window>>>;
}

const SubWindowStateContext = createContext({} as SubWindowStateContextType);

export const SubWindowStateProvider: FC = ({ children }) => {
  const [windowObjectReferences, setWindowObjectReferences] = useState<
    Array<Window>
  >([]);

  return (
    <SubWindowStateContext.Provider
      value={{
        windowObjectReferences,
        setWindowObjectReferences,
      }}
    >
      {children}
    </SubWindowStateContext.Provider>
  );
};

export const useSubWindowStateContext = (): SubWindowStateContextType =>
  useContext(SubWindowStateContext);
