export const useTextFile = (): {
  createTextFile: (fileName: string, message: string) => void;
} => {
  const createTextFile = (fileName: string, message: string) => {
    const blob = new Blob([message], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    document.body.appendChild(a);
    a.download = fileName;
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  return { createTextFile };
};
