import React, { FC, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import Label from "../../../atoms/Label";
import Button from "../../../atoms/Button";
import Textarea from "../../../atoms/Textarea";
import { useErrorStateContext } from "../../../../context/ErrorStateProvider";
import { useTextFile } from "../../../../hooks/useTextFile";
import { useDate } from "../../../../hooks/useDate";
import { useError } from "../../../../hooks/useError";

const ErrorDialog: FC = () => {
  const { error, errorMessage } = useErrorStateContext();
  const { deleteError } = useError();
  const { createTextFile } = useTextFile();
  const { getDate } = useDate();

  const handleOnClose = useCallback(() => deleteError(), [deleteError]);
  const onClickDownLoadErrorLog = () => {
    if (error && errorMessage !== undefined) {
      const today = getDate("YYYYMMDDhhmmss");
      const fileName = `ImageBoxErrorLog_${today}.txt`;
      createTextFile(fileName, errorMessage);
    }
  };

  return (
    <Modal
      isOpen={error}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent animation="none" m={0} maxWidth={"600px"}>
        <ModalHeader>
          <Label className="font-bold">エラーが発生しました</Label>
        </ModalHeader>
        <ModalBody>
          <Textarea
            className="text-10px resize-none border border-black p-1 w-full h-100px"
            defaultValue={errorMessage}
            wrap="off"
            readOnly={true}
          />
        </ModalBody>
        <ModalFooter>
          <div className="w-full flex flex-row justify-between">
            <Button
              className="px-2"
              size="small"
              primary={false}
              onClick={onClickDownLoadErrorLog}
            >
              送信用ファイル作成
            </Button>
            <Button
              className="px-4"
              size="small"
              primary={false}
              onClick={handleOnClose}
            >
              閉じる
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorDialog;
