import React, { ChangeEvent, FC, memo, useCallback } from "react";
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  Controller,
} from "react-hook-form";

import Label from "../../atoms/Label";
import CheckBoxText from "../CheckBoxText";
import { CategoryGroup } from "../../../types/types";

interface Props {
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  data: CategoryGroup;
}

const SelectTags: FC<Props> = ({ control, setValue, watch, data }) => {
  const onChange = useCallback(
    (id: number, e: ChangeEvent<HTMLInputElement>) => {
      switch (e.target.checked) {
        case true:
          setValue(`tags.${id}`, 1);
          break;
        case false:
          setValue(`tags.${id}`, 0);
      }
    },
    []
  );

  return (
    <>
      <Label>{data.group_name}</Label>
      {data.tags.map((tag) => (
        <Controller
          render={() => (
            <CheckBoxText
              key={tag.id}
              id={tag.name}
              label={tag.name}
              spacing="0.15rem"
              checked={watch(`tags.${tag.id}`) === 1}
              onChange={(e) => onChange(tag.id, e)}
            />
          )}
          name={`tags.${tag.id}`}
          control={control}
          key={tag.id}
        />
      ))}
    </>
  );
};

export default memo(SelectTags);
