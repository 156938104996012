import React, { FC, memo, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { Item, Menu, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

import { useSubWindow } from "../../../hooks/useSubWindow";
import { useModalStateContext } from "../../../context/ModalStateProvider";
import { useSearchStateContext } from "../../../context/SearchStateProvider";
import { useSelectedItemStateContext } from "../../../context/SelectedItemStateProvider";
import { useDeleteImage } from "../../../hooks/useDeleteImage";
import { useDetailWindow } from "../../../hooks/useDetailWindow";
import { useAuthContext } from "../../../context/AuthProvider";

interface Props {
  id: number;
}

const ContextMenu: FC<Props> = memo(({ id }) => {
  const { showImageWindows } = useSubWindow();
  const { showDetailWindows } = useDetailWindow();
  const { client_id } = useParams<{ client_id: string }>();
  const { setModal } = useModalStateContext();
  const { searchData, setSearchData } = useSearchStateContext();
  const { setSelectedItem, selectedItem, setFocusItem } =
    useSelectedItemStateContext();
  const { post } = useDeleteImage();
  const { user } = useAuthContext();

  const onOpenUpdateUseClient = useCallback(
    () =>
      setModal((prevState) => {
        return { ...prevState, patternSelectModal: true };
      }),
    []
  );

  const onOpenUpdateSharePattern = useCallback(
    () =>
      setModal((prevState) => {
        return {
          ...prevState,
          sharePatternSelectModal: true,
        };
      }),
    []
  );
  const onOpenUpdateTagModal = useCallback(
    () =>
      setModal((prevState) => {
        return { ...prevState, tagSelectModal: true };
      }),
    []
  );
  const onOpenBulkEditModal = useCallback(
    () =>
      setModal((prevState) => {
        return { ...prevState, bulkEditModal: true };
      }),
    []
  );

  /**
   * 画像IDコピー
   */
  const handleCopyImageIdItemClick = useCallback(async () => {
    if (navigator.clipboard) {
      const text = searchData
        ?.filter((data) => selectedItem.includes(data.id))
        .map((data) => data.image_code)
        .join("\n");
      await navigator.clipboard.writeText(text ?? "");
    } else {
      alert("このブラウザでサポートされていない機能です。");
    }
  }, [searchData, selectedItem]);

  const handleRemoveSelectItemForResults = useCallback(async () => {
    const data =
      searchData?.filter((data) => !selectedItem.includes(data.id)) ?? [];
    setSearchData(data);
    setSelectedItem([]);
  }, [searchData, selectedItem]);

  /**
   * 選択を反転
   */
  const handleReverseSelectItemClick = useCallback(async () => {
    const ids =
      searchData
        ?.filter((data) => !selectedItem.includes(data.id))
        .map((data) => data.id) ?? [];
    setSelectedItem(ids);
  }, [searchData, selectedItem]);

  /**
   * 使用クライアントを変更
   */
  const handleOpenUpdateTagModalClick = useCallback(async () => {
    const ids =
      searchData
        ?.filter((data) => selectedItem.includes(data.id))
        .map((data) => data.share_pattern_id) ?? [];
    const uniqueIds = Array.from(new Set(ids));

    if (uniqueIds.length > 1) {
      alert("異なる使用権の画像が複数選択されているため変更できません。");
      return;
    }
    onOpenUpdateUseClient();
  }, [searchData, selectedItem]);

  /**
   * 使用権変更
   */
  const handleOpenUpdateUsePropertyClick = useCallback(async () => {
    const ids =
      searchData
        ?.filter((data) => selectedItem.includes(data.id))
        .map((data) => data.master_client_id) ?? [];
    const uniqueIds = Array.from(new Set(ids));

    if (uniqueIds.length > 1) {
      alert("異なる版権の画像が複数選択されているため変更できません。");
      return;
    }
    onOpenUpdateSharePattern();
  }, [searchData, selectedItem]);

  /**
   * 画像削除
   */
  const handleDeleteImageClick = useCallback(() => {
    if (confirm(`選択した画像を削除しますか？`)) {
      selectedItem.map((id) => {
        post(id);
      });
      // 画面から画像を削除する
      if (searchData !== null) {
        const filteredData = searchData.filter(
          (data) => !selectedItem.includes(data.id)
        );
        setSearchData(filteredData);
      }
      setFocusItem(null);
      setSelectedItem([]);
      alert("画像を削除しました。");
    }
  }, [searchData, selectedItem]);

  const handleOnDetailDisplay = useCallback(() => {
    const setting = localStorage.getItem("detail/display");

    if (setting !== null && ["single", "multi"].includes(setting)) {
      showDetailWindows(
        `${process.env.REACT_APP_URL}/clients/${client_id}/image/${id}?mode=${setting}`,
        client_id
      );
    } else {
      showDetailWindows(
        `${process.env.REACT_APP_URL}/clients/${client_id}/image/${id}?mode=single`,
        client_id
      );
    }
  }, [showDetailWindows]);

  return (
    <ContextMenuPortal>
      <Menu
        className="font-sans z-50"
        id={String(id)}
        animation={false}
        style={{ fontSize: "17px", borderRadius: 0, padding: 4 }}
      >
        <Item onClick={handleOnDetailDisplay}>表示</Item>
        {user?.permission?.includes("ImageAdmin") && (
          <>
            <Item
              onClick={() =>
                showDetailWindows(
                  `${process.env.REACT_APP_URL}/clients/${client_id}/image/${id}?mode=edit`,
                  client_id
                )
              }
            >
              編集
            </Item>
            <Item onClick={onOpenBulkEditModal}>一括編集</Item>
            <Item onClick={onOpenUpdateTagModal}>タグ変更</Item>
          </>
        )}
        <Separator />
        <Item
          onClick={() =>
            showImageWindows(
              `${process.env.REACT_APP_URL}/images/${id}?cid=${client_id}`,
              client_id,
              id
            )
          }
        >
          拡大画像を表示
        </Item>
        <Separator />
        <Item onClick={handleCopyImageIdItemClick}>画像IDコピー</Item>
        <Separator />
        <Item onClick={handleRemoveSelectItemForResults}>検索結果から削除</Item>
        <Separator />
        <Item onClick={handleReverseSelectItemClick}>選択を反転</Item>
        {user?.permission?.includes("ImageAdmin") && (
          <>
            <Separator />
            <Item onClick={handleOpenUpdateTagModalClick}>
              使用クライアントを変更
            </Item>
            <Item onClick={handleOpenUpdateUsePropertyClick}>使用権変更</Item>
          </>
        )}
        {user?.permission?.includes("Delete") && (
          <>
            <Separator />
            <Item onClick={handleDeleteImageClick}>システムから削除</Item>
          </>
        )}
      </Menu>
    </ContextMenuPortal>
  );
});

const ContextMenuPortal: FC = ({ children }) => {
  const el = document.getElementById("contextMenu") as HTMLElement;
  return ReactDOM.createPortal(children, el);
};

ContextMenu.displayName = "ContextMenu";

export default ContextMenu;
