import { useCallback } from "react";
import axios from "axios";

import { Client } from "../types/types";

interface ClientsResponse {
  data: Client[];
  data_format: string;
  status: number;
  type: string;
}

interface SpecialClientsResponse {
  data: string[];
  data_format: string;
  status: number;
  type: string;
}

interface ClientResponse {
  data: Client;
  data_format: string;
  status: number;
  type: string;
}

export const useClient = (): {
  getSpecialsClients: () => Promise<string[]>;
  getAllClients: () => Promise<Client[]>;
  getClient: (client_id: number | string) => Promise<Client>;
  getSharePatternClientsById: (
    sharePatternId: number | string
  ) => Promise<Client[]>;
} => {
  const config = { withCredentials: true };

  const getAllClients = useCallback(async (): Promise<Client[]> => {
    const { data } = await axios.get<ClientsResponse>(
      `${process.env.REACT_APP_API_URL}/api/clients`,
      config
    );
    return data.data;
  }, []);

  const getSpecialsClients = useCallback(async (): Promise<string[]> => {
    const { data } = await axios.get<SpecialClientsResponse>(
      `${process.env.REACT_APP_API_URL}/api/clients/specials`,
      config
    );
    return data.data;
  }, []);

  const getClient = useCallback(
    async (client_id: number | string): Promise<Client> => {
      const { data } = await axios.get<ClientResponse>(
        `${process.env.REACT_APP_API_URL}/api/clients/${client_id}`,
        config
      );
      return data.data;
    },
    []
  );

  const getSharePatternClientsById = useCallback(
    async (sharePatternId: number | string) => {
      const { data } = await axios.get<ClientsResponse>(
        `${process.env.REACT_APP_API_URL}/api/sharePattern/listClient?sharePatternId=${sharePatternId}`,
        config
      );
      return data.data;
    },
    []
  );

  return {
    getAllClients,
    getSpecialsClients,
    getClient,
    getSharePatternClientsById,
  };
};
