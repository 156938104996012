import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

interface SelectedItemStateContextType {
  selectedItem: number[];
  setSelectedItem: Dispatch<SetStateAction<number[]>>;
  focusItem: number | null;
  setFocusItem: Dispatch<SetStateAction<number | null>>;
}

const SelectedItemStateContext = createContext(
  {} as SelectedItemStateContextType
);

export const SelectedItemStateProvider: FC = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<number[]>([]);
  const [focusItem, setFocusItem] = useState<number | null>(null);

  return (
    <SelectedItemStateContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        focusItem,
        setFocusItem,
      }}
    >
      {children}
    </SelectedItemStateContext.Provider>
  );
};

export const useSelectedItemStateContext = (): SelectedItemStateContextType =>
  useContext(SelectedItemStateContext);
