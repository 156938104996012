import React, { memo, FC, HTMLAttributes } from "react";
import { Property } from "csstype";

interface Props extends HTMLAttributes<HTMLDivElement> {
  spacing?: Property.Margin;
  className?: string;
  htmlFor?: string;
}

const Label: FC<Props> = ({
  spacing = "0.25rem",
  className = "",
  children,
  ...props
}) => {
  const defaultClassName = "font-sans";

  return (
    <div
      className={[defaultClassName, className].join(" ")}
      style={{ margin: spacing }}
      {...props}
    >
      {children}
    </div>
  );
};

export default memo(Label);
