export const details = {
  title: "",
  image_code: "",
  maker: "",
  locality: "",
  product_name: "",
  product_name2: "",
  spec: "",
  jan_code: "",
  product_filename: "",
  new_old_type_now: "",
  new_old_type_maybe: "",
  new_old_type_old: "",
  plan_end_date: "",
  note: "",
  price: "",
  use_start_date: "",
  various_code: "",
  color: "",
  supplier_name: "",
  base_image_id: "",
  base_image_change: "",
  share_pattern: "",
  create_date: "",
  update_date: "",
  file_create_date: "",
  file_update_date: "",
  file_size: "",
  offering_date: "",
};
