import { useReducer } from "react";

interface ConfirmState {
  isOpen: boolean;
  handleOnOk?: () => void;
  handleOnCancel?: () => void;
  message?: string;
  headerMessage?: string;
}

const initialState: ConfirmState = {
  isOpen: false,
  handleOnOk: undefined,
  handleOnCancel: undefined,
  message: undefined,
  headerMessage: undefined,
};

const confirmReducer = (
  state: {
    isOpen: boolean;
    handleOnOk?: () => void;
    handleOnCancel?: () => void;
    message?: string;
    headerMessage?: string;
  },
  action: {
    type: "OPEN" | "CLOSE";
    handleOnOk?: () => void;
    handleOnCancel?: () => void;
    message?: string;
    headerMessage?: string;
  }
): ConfirmState => {
  if (action.type === "OPEN") {
    return {
      isOpen: true,
      handleOnOk: action.handleOnOk,
      handleOnCancel: action.handleOnCancel,
      message: action.message,
      headerMessage: action.headerMessage,
    };
  } else {
    return {
      isOpen: false,
      handleOnOk: undefined,
      handleOnCancel: undefined,
      message: undefined,
      headerMessage: undefined,
    };
  }
};

const useConfirm = (): {
  onCloseConfirm: () => void;
  onOpenConfirm: ({
    handleOnOk,
    handleOnCancel,
    message,
    headerMessage,
  }: {
    handleOnOk?: () => void;
    handleOnCancel?: () => void;
    message?: string;
    headerMessage?: string;
  }) => void;
  confirmState: ConfirmState;
} => {
  const [confirmState, dispatchConfirm] = useReducer(
    confirmReducer,
    initialState
  );

  const onOpenConfirm = ({
    handleOnOk,
    handleOnCancel,
    message,
    headerMessage,
  }: {
    handleOnOk?: () => void;
    handleOnCancel?: () => void;
    message?: string;
    headerMessage?: string;
  }) => {
    dispatchConfirm({
      type: "OPEN",
      handleOnOk: handleOnOk,
      handleOnCancel: handleOnCancel,
      message: message,
      headerMessage: headerMessage,
    });
  };

  const onCloseConfirm = () => {
    dispatchConfirm({ type: "CLOSE" });
  };
  return { confirmState, onOpenConfirm, onCloseConfirm };
};

export default useConfirm;
