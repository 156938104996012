import React, {
  FC,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  ApiError,
  Client,
  ClientAttribute,
  ImageBoxImageDetail,
  Mode,
} from "../../types/types";
import SelectMode from "../molecules/SelectMode";
import SelectBox from "../atoms/SelectBox";
import Image from "../atoms/Image";
import Label from "../atoms/Label";
import Input from "../atoms/Input";
import Textarea from "../atoms/Textarea";
import { Controller, useForm } from "react-hook-form";
import NewOldTypeSelect from "../atoms/NewOldTypeSelect";
import LabelSelectBox from "../atoms/LabelSelectBox";
import { AxiosError } from "axios";
import { useError } from "../../hooks/useError";
import { useSubWindow } from "../../hooks/useSubWindow";
import { NewOldType } from "../../util/NewOldType";
import { useScreenLock } from "../../hooks/useScreenLock";
import { useDate } from "../../hooks/useDate";
import { useImageDetails } from "../../hooks/useImageDetails";
import { useClient } from "../../hooks/useClient";
import { useUsesClient } from "../../hooks/useUsesClient";
import { useSharePattern } from "../../hooks/useSharePattern";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthProvider";
import FileSize from "../molecules/FileSize";
import { DetailRepository } from "../../repositories/DetailRepository";
import useConfirm from "../../hooks/useConfirm";
import Confirm from "../organisms/Modal/Confirm";

interface Props {
  imageData: ImageBoxImageDetail | undefined;
  mode?: Mode;
  onReload: (v: boolean) => void;
  client: Client | undefined;
}

interface attributeData {
  useStartDate: Array<string | undefined>;
  title: Array<string | undefined>;
  productName: Array<string | undefined>;
  spec: Array<string | undefined>;
  productFilename: Array<string | undefined>;
  price: Array<string | undefined>;
  variousCode: Array<string | undefined>;
}

export interface EditableData extends ImageBoxImageDetail {
  title2: string;
  title3: string;
}

const ImageDetailTemp: FC<Props> = ({ imageData, mode, onReload, client }) => {
  const history = useHistory();
  const location = useLocation();

  const isFirstRender = useRef(true);
  const [comboData, setComboData] = useState<Map<number, string>>(new Map());
  const [selectedCombo, setSelectedCombo] = useState<string>("");

  const [sharePattern, setSharePattern] = useState<string>("");
  const [tagList, setTagList] = useState<Array<string>>([]);
  const [useClients, setUseClients] = useState<Array<string>>([]);
  const [attributeData, setAttributeData] = useState<attributeData>({
    useStartDate: [],
    title: [],
    productName: [],
    spec: [],
    productFilename: [],
    price: [],
    variousCode: [],
  });

  const {
    register,
    reset,
    getValues,
    control,
    formState: { isDirty },
  } = useForm<EditableData>();

  const { setError } = useError();
  const { showImageWindows } = useSubWindow();
  const { getList, putSession, deleteSession, deleteSessionBeforeUnload } =
    useScreenLock();
  const { formatDate } = useDate();
  const { post } = useImageDetails();
  const { getAllClients, getClient } = useClient();
  const { getSharePatternById, useCheck } = useSharePattern();
  const getUseClient = useUsesClient().get;
  const { confirmState, onOpenConfirm, onCloseConfirm } = useConfirm();

  const { client_id, image_id } =
    useParams<{ client_id: string; image_id: string }>();
  const { user } = useAuthContext();

  const handleOfUpdateDetail = useCallback(
    async (event: StorageEvent) => {
      if (
        event.key?.startsWith("client/" + client_id + "/select/image") &&
        event.newValue
      ) {
        const push = () => {
          const settings = localStorage.getItem("detail/display");
          if (settings === "multi") {
            window.location.href =
              "/clients/" +
              client_id +
              "/image/" +
              event.newValue +
              "?mode=multi";
          } else {
            window.location.href =
              "/clients/" +
              client_id +
              "/image/" +
              event.newValue +
              "?mode=single";
          }
        };

        if (isDirty) {
          const handleOnOk = async () => {
            await endEdit(true);
            push();
          };
          onOpenConfirm({
            handleOnOk: handleOnOk,
            handleOnCancel: push,
            message: "データが変更されています。変更を保存しますか？",
          });
        } else {
          push();
        }
      }
    },
    [isDirty]
  );

  /**
   * 編集可能か確かめる非同期関数
   */
  const isEditable = async (): Promise<boolean> => {
    if (getValues("image_code")) {
      const accounts = await getList();

      const filteredAccount = accounts.filter((account) => {
        const screens = account.lock_screens.split(",");
        return (
          screens.includes("画像詳細") &&
          account.lock_images === getValues("image_code") &&
          account.id !== user?.username
        );
      });

      if (filteredAccount.length !== 0) {
        alert(`この画面は${filteredAccount[0].name}によって現在編集中です。`);
        return false;
      }
    }

    if (getValues("share_pattern_id")) {
      const checkList = await useCheck(getValues("share_pattern_id"));

      if (checkList !== "") {
        alert("使用権修正中です。時間をおいてから実施してください");
        return false;
      }
    }

    return true;
  };

  // 編集終了
  const endEdit = async (save: boolean) => {
    if (save && imageData && client) {
      // 画像IDが変更された場合、画面ロックの情報も更新する
      const id = imageData.id;
      const imageCode = imageData.image_code;
      const masterClient = await getClient(imageData.master_client_id);

      //新規作成フラグ
      let bUpd = false;
      //画像のみ更新フラグ
      let bBUpd = false;

      //画像独自の情報を更新
      imageData.previous_image_id = id;
      imageData.previous_image_code = imageCode;
      imageData.image_code = getValues("image_code");
      imageData.plan_end_date = getValues("plan_end_date");
      imageData.maker = getValues("maker");
      imageData.locality = getValues("locality");
      imageData.jan_code = getValues("jan_code");
      imageData.supplier_name = getValues("supplier_name");
      imageData.new_old_type = getValues("new_old_type");
      imageData.color = getValues("color");
      imageData.base_image_id = getValues("base_image_id");
      imageData.note = getValues("note");
      imageData.base_image_change = getValues("base_image_change");
      imageData.offering_date = getValues("offering_date");
      imageData.product_name2 = getValues("product_name2");

      if (selectedCombo === "標準") {
        //標準情報を更新した場合
        imageData.use_start_date = getValues("use_start_date");
        imageData.price = getValues("price");
        imageData.title = getValues("title");
        imageData.product_name = getValues("product_name");
        imageData.product_filename = getValues("product_filename");
        imageData.various_code = getValues("various_code");
        imageData.spec = getValues("spec");
      } else {
        // eslint-disable-next-line
        const shortName = selectedCombo
          .replace("＊", "")
          .replace("　", "")
          .substr(0, 3);
        const selectedClients = await getSelectedClient(
          shortName ?? client?.short_name
        );

        //版権情報を更新する場合は画像の基本情報も更新する
        if (masterClient.short_name === selectedClients[0].short_name) {
          imageData.title = getValues("title");
          imageData.product_name = getValues("product_name");
          imageData.product_filename = getValues("product_filename");
          imageData.spec = getValues("spec");
        }

        //差分情報を更新・作成した場合
        if (imageData.client_attributes) {
          //差分情報が存在する場合
          for (const [, value] of Object.entries(imageData.client_attributes)) {
            //版権情報で中身がない限りは差分情報を作成しない
            if (
              masterClient.short_name === value.client_short_name &&
              masterClient.short_name === shortName
            ) {
              let bFlag = false;

              if (getValues("title2").length > 0) {
                bFlag = true;
              } else {
                if (
                  `${getValues("title")}/${getValues("title2")}` ===
                  value?.title
                ) {
                  bFlag = true;
                }
              }
              if (imageData.price === "" || imageData.price === null) {
                bFlag = true;
              }
              if (
                imageData.use_start_date === "" ||
                imageData.use_start_date === null
              ) {
                bFlag = true;
              }
              if (
                imageData.various_code === "" ||
                imageData.various_code === null
              ) {
                bFlag = true;
              }

              if (bFlag) {
                value.use_start_date = getValues("use_start_date");
                value.price = getValues("price");

                if (getValues("title2") === "") {
                  value.title = getValues("title");
                } else {
                  value.title = `${getValues("title")}/${getValues("title2")}`;
                }
                value.product_name = getValues("product_name");
                value.product_filename = getValues("product_filename");
                value.various_code = getValues("various_code");
                value.spec = getValues("spec");
                value.new_set = true;
                value.valid_flag = true;
                value.delete_flag = false;
                bUpd = true;
                break;
              } else {
                bBUpd = true;
              }
            } else {
              if (value.client_short_name === shortName) {
                value.use_start_date = getValues("use_start_date");
                value.price = getValues("price");
                if (getValues("title2") === "") {
                  value.title = getValues("title");
                } else {
                  value.title = `${getValues("title")}/${getValues("title2")}`;
                }
                value.product_name = getValues("product_name");
                value.product_filename = getValues("product_filename");
                value.various_code = getValues("various_code");
                value.spec = getValues("spec");
                value.new_set = true;
                value.valid_flag = true;
                value.delete_flag = false;
                bUpd = true;
                break;
              } else if (
                value.new_set === null ||
                value.valid_flag === null ||
                value.delete_flag === null
              ) {
                // ignore
              }
            }
          }
          if (!bUpd && !bBUpd) {
            const ca: ClientAttribute = {
              client_id: selectedClients[0].id,
              client_name: "",
              client_short_name: "",
              image_id: getValues("id"),
              valid_flag: true,
              title:
                getValues("title2") === ""
                  ? getValues("title")
                  : `${getValues("title")}/${getValues("title2")}`,
              title_search: "",
              product_name: getValues("product_name"),
              product_name_search: "",
              spec: getValues("spec"),
              spec_search: "",
              product_filename: getValues("product_filename"),
              product_filename_search: "",
              price: getValues("price"),
              price_search: "",
              use_start_date: getValues("use_start_date"),
              use_start_date_search: "",
              various_code: getValues("various_code"),
              various_code_search: "",
              create_account_id: "",
              create_date: "",
              update_account_id: "",
              update_date: "",
              delete_flag: false,
              product_name2: "",
              product_name2_search: "",
              new_set: true,
              fazzy_word: "",
            };
            imageData.client_attributes[selectedClients[0].short_name] = ca;
          }
        }
      }
      const result = await post(imageData);

      if (result) {
        if (
          user?.username !== undefined &&
          imageCode !== undefined &&
          imageCode !== getValues("image_code")
        ) {
          deleteSession(user.username, imageCode);
          putSession(user.username, getValues("image_code"));
        }

        onReload(true);
      }
    } else {
      reset({ ...imageData, title2: "" });
    }
  };

  // 編集モード
  const handleOnEdit = useCallback(async () => {
    if (!user?.permission?.includes("ImageAdmin")) return;

    if (await isEditable()) {
      history.push(`${location.pathname}?mode=edit`);
    } else {
      const setting = localStorage.getItem("display");
      if (setting === null || !["multi", "single"].includes(setting)) {
        history.push(`${location.pathname}?mode=single`);
      } else {
        history.push(`${location.pathname}?mode=${setting}`);
      }
    }
  }, [history.location, isEditable]);

  // 保存
  const handleOnSave = useCallback(() => {
    if (!user?.permission?.includes("ImageAdmin")) return;

    if (mode === "edit" && imageData !== undefined) {
      // eslint-disable-next-line
      const shortName = selectedCombo
        .replace("＊", "")
        .replace("　", "")
        .substr(0, 3);

      const push = () => {
        const settings = localStorage.getItem("detail/display");
        if (settings === "multi") {
          window.location.href =
              "/clients/" +
              client_id +
              "/image/" +
              imageData.id +
              "?mode=multi";
        } else {
          window.location.href =
              "/clients/" +
              client_id +
              "/image/" +
              imageData.id +
              "?mode=single";
        }
      };

      if (
        isDirty ||
        !Object.keys(imageData.client_attributes).includes(shortName)
      ) {
        const handleOnOk = async () => {
          await endEdit(true);
          push();
        };
        onOpenConfirm({
          handleOnOk: handleOnOk,
          message: "データが変更されています。変更を保存しますか？",
        });
      }
    }
  }, [endEdit, mode, isDirty]);

  // 参照
  const handleOnReference = useCallback(() => {
    if (imageData === undefined || client === undefined) return;
    const setting = localStorage.getItem("detail/display");

    if (mode === "edit") {
      const push = () => {
        if (
          imageData?.image_code !== undefined &&
          user?.username !== undefined
        ) {
          deleteSession(user.username, imageData.image_code);
        }
        setSelectedCombo(comboData.get(client.id) ?? "");

        if (setting === null || !["multi", "single"].includes(setting)) {
          history.push(`${location.pathname}?mode=single`);
          localStorage.setItem("detail/display", "single");
        } else {
          history.push(`${location.pathname}?mode=${setting}`);
        }
      };
      if (isDirty) {
        const handleOnOk = async () => {
          await endEdit(true);
          push();
        };
        onOpenConfirm({
          handleOnOk: handleOnOk,
          handleOnCancel: push,
          message: "データが変更されています。変更を保存しますか？",
        });
      } else {
        push();
      }
    }
  }, [endEdit, mode, isDirty, user, imageData]);

  // 単数表示・複数表示
  const handleOnMulti = useCallback(() => {
    if (imageData === undefined || client === undefined) return;
    switch (mode) {
      case "multi":
        history.push(`${location.pathname}?mode=single`);
        localStorage.setItem("detail/display", "single");
        break;
      case "single":
        history.push(`${location.pathname}?mode=multi`);
        localStorage.setItem("detail/display", "multi");
        break;
    }
  }, [mode, isDirty, endEdit, imageData, user, deleteSession]);

  const getKeyNewOldType = (value: NewOldType | undefined): string => {
    if (value === undefined) return "";
    return NewOldType[value];
  };

  const getSelectedClient = async (shortName: string): Promise<Client[]> => {
    const allClient = await getAllClients();

    return allClient.filter((client) => {
      return client.short_name === shortName;
    });
  };

  // 画像詳細データを各部分に設定する
  useLayoutEffect(() => {
    (async () => {
      try {
        if (imageData === undefined || client === undefined) return;

        const detailRepository = new DetailRepository(imageData, client);

        // 使用権データ取得
        if (imageData?.share_pattern_id) {
          const sharePattern = await getSharePatternById(
            imageData?.share_pattern_id
          );
          setSharePattern([sharePattern.id, sharePattern.name].join("："));
        }

        // タグ一覧データ作成
        const imageTags = detailRepository.createTagList();
        setTagList(imageTags);

        // 使用クライアント一覧
        const useClients = await getUseClient(image_id);
        const useClientShortName = useClients.map(
          (client) => client.short_name
        );
        setUseClients(useClientShortName);

        // 初期データ作成
        const initData = detailRepository.createInitialData(client.id);
        reset(initData);

        // コンボデータの作成
        const comboData = detailRepository.createComboList(useClients);

        setComboData(comboData);
        setSelectedCombo(comboData.get(client?.id) ?? "");

        // 複数表示用の顧客属性データの作成
        const attributeData = detailRepository.createAttributeDataList();
        setAttributeData(attributeData);

        if (imageData?.image_code !== undefined)
          document.title = `ImageBox - ${imageData?.image_code}`;
      } catch (e) {
        const response = (e as AxiosError<ApiError>).response;
        if (response) {
          setError(response.data);
        }
      } finally {
        isFirstRender.current = false;
      }
    })();
  }, [imageData, client]);

  useLayoutEffect(() => {
    (async () => {
      if (
        isFirstRender.current ||
        user?.username === undefined ||
        imageData === undefined ||
        mode === undefined
      )
        return;
      if (mode === "edit") {
        if (await isEditable()) {
          putSession(user.username, imageData.image_code);

          window.onbeforeunload = function () {
            if (user.username !== undefined)
              deleteSessionBeforeUnload(user.username, imageData.image_code);
          };
        }
      } else {
        window.onbeforeunload = null;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useLayoutEffect(() => {
    if (
      isFirstRender.current ||
      imageData === undefined ||
      client === undefined
    )
      return;

    const detailRepository = new DetailRepository(imageData, client);

    (async () => {
      // eslint-disable-next-line
      const shortName = selectedCombo
        .replace("＊", "")
        .replace("　", "")
        .substr(0, 3);
      const selectClient = await getSelectedClient(shortName);
      if (selectClient.length !== 0) {
        const initData = detailRepository.createInitialData(selectClient[0].id);
        reset(initData);
      } else {
        const initData = detailRepository.createInitialData(null);
        reset(initData);
      }
    })();
  }, [selectedCombo]);

  return (
    <div
      className="flex flex-col font-sans text-10px"
      style={{ minWidth: "420px" }}
    >
      <SelectMode
        handleOnSave={handleOnSave}
        handleOnEdit={handleOnEdit}
        handleOnMulti={handleOnMulti}
        handleOnReference={handleOnReference}
        mode={mode}
        handleOfUpdateDetail={handleOfUpdateDetail}
      />
      <div
        className={`${
          mode === "edit" ? "bg-green-pale" : "bg-gray-background"
        } flex flex-col py-2 px-4 shadow-md`}
      >
        {mode === "edit" && (
          <SelectBox
            className="m-1"
            options={Array.from(comboData.values())}
            selectSize={"small"}
            width="250px"
            value={selectedCombo}
            disabled={isDirty}
            onChange={(e) => setSelectedCombo(e.target.value)}
          />
        )}
        <div className="flex flex-row">
          <div className="inline-flex items-center">
            <Image
              className="cursor-pointer"
              src={`${process.env.REACT_APP_API_URL}/api/images/${image_id}/thumbnail?scale=small`}
              width="150px"
              height="150px"
              onClick={() =>
                showImageWindows(
                  `${process.env.REACT_APP_URL}/images/${image_id}?cid=${client_id}`,
                  client_id,
                  image_id
                )
              }
            />
          </div>
          <div className="flex-grow ml-2">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  画像ID
                </Label>
                <Input
                  className="flex-grow"
                  type="string"
                  inputSize="small"
                  spacing="0"
                  {...register("image_code")}
                  readOnly={mode !== "edit"}
                />
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  利用開始日
                </Label>
                {mode !== "multi" ? (
                  <Input
                    className="flex-grow"
                    type="text"
                    inputSize="small"
                    spacing="0.1rem 0"
                    bg="skyblue"
                    {...register("use_start_date")}
                    readOnly={mode !== "edit"}
                  />
                ) : (
                  <Textarea
                    className="flex-grow"
                    bg="skyblue"
                    defaultValue={attributeData.useStartDate.join("\n")}
                    readOnly
                  />
                )}
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  新旧区分
                </Label>
                {mode !== "edit" ? (
                  <Input
                    type="text"
                    inputSize="small"
                    width="80px"
                    spacing="0.2rem 0 0 0"
                    value={getKeyNewOldType(getValues("new_old_type"))}
                    readOnly={true}
                  />
                ) : (
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <NewOldTypeSelect
                        selectSize="small"
                        width="80px"
                        spacing="0.2rem 0 0 0"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name={"new_old_type"}
                    control={control}
                  />
                )}
              </div>
              <div className="flex flex-row items-center">
                <Label className="inline-flex items-center w-70px">
                  ラベル
                </Label>
                {mode !== "edit" ? (
                  <LabelSelectBox
                    value={getValues("color")}
                    onChange={() => void 0}
                    disabled={true}
                  />
                ) : (
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <LabelSelectBox value={value} onChange={onChange} />
                    )}
                    name={"color"}
                    control={control}
                  />
                )}
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  企画終了日
                </Label>
                <Input
                  className="flex-grow"
                  type="text"
                  inputSize="small"
                  spacing="0.2rem 0 0.2rem 0"
                  {...register("plan_end_date")}
                  readOnly={mode !== "edit"}
                />
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  JANコード
                </Label>
                <Input
                  className="flex-grow"
                  type="text"
                  inputSize="small"
                  spacing="0"
                  {...register("jan_code")}
                  readOnly={mode !== "edit"}
                />
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  支給元名
                </Label>
                <Input
                  className="flex-grow"
                  type="text"
                  inputSize="small"
                  spacing="0.2rem 0"
                  {...register("supplier_name")}
                  readOnly={mode !== "edit"}
                />
              </div>
              <div className="flex flex-row">
                <Label className="inline-flex items-center w-70px">
                  売出日
                </Label>
                <Input
                  className="flex-grow"
                  type="text"
                  inputSize="small"
                  spacing="0"
                  {...register("offering_date")}
                  readOnly={mode !== "edit"}
                />
              </div>
            </div>
          </div>
        </div>
        <Label spacing={0}>画像タイトル</Label>
        {mode !== "multi" ? (
          <div className="flex flex-row">
            {mode === "edit" ? (
              <>
                <Input
                  type={"text"}
                  inputSize="small"
                  width="100%"
                  spacing="0"
                  bg="skyblue"
                  {...register("title")}
                />
                <Input
                  type="text"
                  inputSize="small"
                  width="100%"
                  spacing="0"
                  bg="skyblue"
                  {...register("title2")}
                />
              </>
            ) : (
              <Input
                type={"text"}
                inputSize="small"
                width="100%"
                spacing="0"
                bg="skyblue"
                {...register("title3")}
                readOnly={true}
              />
            )}
          </div>
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.title.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>メーカー</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          {...register("maker")}
          readOnly={mode !== "edit"}
        />
        <Label spacing={0}>産地</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          {...register("locality")}
          readOnly={mode !== "edit"}
        />
        <Label spacing={0}>品名・イメージ１</Label>
        {mode !== "multi" ? (
          <Input
            type="text"
            inputSize="small"
            width="100%"
            spacing="0"
            bg="skyblue"
            {...register("product_name")}
            readOnly={mode !== "edit"}
          />
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.productName.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>品名・イメージ２</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Textarea
              className="text-10px p-1"
              readOnly={mode !== "edit"}
              onChange={onChange}
              value={value}
            />
          )}
          name="product_name2"
          control={control}
        />
        <Label spacing={0}>規格・商品詳細</Label>
        {mode !== "multi" ? (
          <Input
            type="text"
            inputSize="small"
            width="100%"
            spacing="0"
            bg="skyblue"
            {...register("spec")}
            readOnly={mode !== "edit"}
          />
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.spec.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>品名ファイル名</Label>
        {mode !== "multi" ? (
          <Input
            type="text"
            inputSize="small"
            width="100%"
            spacing="0"
            bg="skyblue"
            {...register("product_filename")}
            readOnly={mode !== "edit"}
          />
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.productFilename.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>備考</Label>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Textarea
              className="text-10px p-1"
              readOnly={mode !== "edit"}
              onChange={onChange}
              value={value}
            />
          )}
          name="note"
          control={control}
        />
        <Label spacing={0}>参考価格</Label>
        {mode !== "multi" ? (
          <Input
            type="text"
            inputSize="small"
            width="100%"
            spacing="0"
            bg="skyblue"
            {...register("price")}
            readOnly={mode !== "edit"}
          />
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.price.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>各種コード</Label>
        {mode !== "multi" ? (
          <Input
            type="text"
            inputSize="small"
            width="100%"
            spacing="0"
            bg="skyblue"
            {...register("various_code")}
            readOnly={mode !== "edit"}
          />
        ) : (
          <Textarea
            className="flex-grow"
            bg="skyblue"
            value={attributeData.variousCode.join("\n")}
            readOnly
          />
        )}
        <Label spacing={0}>ベース画像ID</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          {...register("base_image_id")}
          readOnly={mode !== "edit"}
        />
        <Label spacing={0}>ベース画像変更点</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          {...register("base_image_change")}
          readOnly={mode !== "edit"}
        />
        <Label spacing={0}>タグ</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          value={tagList.join(",")}
          readOnly
        />
        <Label spacing={0}>使用権画像区分／使用クライアント</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0.1rem 0"
          value={sharePattern}
          readOnly
        />
        <Textarea
          className="text-10px p-1"
          readOnly
          value={useClients.join(",")}
        />
        <Label spacing="0">システム登録日時／ユーザー</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          readOnly
          value={[
            formatDate(getValues("create_date"), "YYYY/MM/DD HH:mm:ss"),
            `／${getValues("create_account_id")}`,
          ].join("")}
        />
        <Label spacing={0}>システム更新日時／ユーザー</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          readOnly
          value={[
            formatDate(getValues("update_date"), "YYYY/MM/DD HH:mm:ss"),
            `／${getValues("update_account_id")}`,
          ].join("")}
        />
        <FileSize size={getValues("original_file_size")} />
        <Label spacing={0}>ファイル作成日時</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          value={formatDate(
            getValues("original_file_creation"),
            "YYYY/MM/DD HH:mm:ss"
          )}
          readOnly
        />
        <Label spacing={0}>ファイル更新日時</Label>
        <Input
          type="text"
          inputSize="small"
          width="100%"
          spacing="0"
          value={formatDate(
            getValues("original_file_last_modify"),
            "YYYY/MM/DD HH:mm:ss"
          )}
          readOnly
        />
      </div>
      <Confirm
        isOpen={confirmState.isOpen}
        onClose={onCloseConfirm}
        message={confirmState.message}
        headerMessage={confirmState.headerMessage}
        onOk={confirmState.handleOnOk}
        onCancel={confirmState.handleOnCancel}
      />
    </div>
  );
};
export default memo(ImageDetailTemp);
