import { ImageBoxImageSimple } from "../types/types";

export interface DownloadOption {
  no?: number;
  branch?: string;
  product?: boolean;
}

export class DownloadStorageRepository {
  async download(
    dh: FileSystemDirectoryHandle,
    data: ImageBoxImageSimple[],
    historyId: number,
    option?: DownloadOption
  ): Promise<void> {
    const chunks = this.chunk(data, 10);
    for (const chunk of chunks) {
      const requests = DownloadStorageRepository.makeRequests(
        dh,
        chunk,
        historyId,
        option
      );
      await Promise.all(requests);
    }
  }

  private static makePrefix(option?: DownloadOption | null) {
    if (option == null) return "";

    let noPrefix = "";
    if (option?.no) {
      const no = "000" + option?.no ?? "";
      if (option.no >= 1000) {
        noPrefix = no.slice(-4);
      } else {
        noPrefix = no.slice(-3);
      }
    }

    let branchPrefix = "";
    if (noPrefix.length > 0 && option?.branch) {
      branchPrefix = option?.branch ?? "";
    }

    let joinedStr = "";
    if (noPrefix.length > 0 || branchPrefix.length > 0) {
      joinedStr = ".";
    }

    return `${noPrefix}${branchPrefix}${joinedStr}`;
  }

  public static makeRequests(
    dh: FileSystemDirectoryHandle,
    chunk: ImageBoxImageSimple[],
    historyId: number,
    option?: DownloadOption
  ): Promise<void>[] {
    const requests = [];
    for (const data of chunk) {
      const request = DownloadStorageRepository.writeDownloadFile(
        dh,
        data,
        historyId,
        option
      );
      requests.push(request);
    }
    return requests;
  }

  private static async writeDownloadFile(
    dh: FileSystemDirectoryHandle,
    data: ImageBoxImageSimple,
    historyId: number,
    option?: DownloadOption
  ) {
    const prefix = DownloadStorageRepository.makePrefix(option);
    const name = option?.product ? data.product_filename : data.image_code;
    const filename = await DownloadStorageRepository.createFileName(
      dh,
      prefix + name,
      "." + data.extension
    );

    const url = `${process.env.REACT_APP_API_URL}/api/images/${data.id}/download`;
    const response = await fetch(url, { credentials: "include" });

    if (!response.ok) {
      throw new Error(
        [
          `Filename: ${filename}`,
          `ErrorCode: ${response.status}`,
          `${
            response.statusText.length > 0
              ? `ErrorMessage: ${response.statusText}`
              : ""
          }`,
        ].join("\n")
      );
    }

    const fh = await dh.getFileHandle(filename, { create: true });
    const writable = await fh.createWritable();

    await response.body?.pipeTo(writable);

    const requestData = JSON.stringify({
      history_id: historyId,
      image_id: data.id,
    });
    navigator.sendBeacon(
      `${process.env.REACT_APP_API_URL}/api/downloadHistory/setImg`,
      requestData
    );
  }

  public chunk(
    arr: ImageBoxImageSimple[],
    size: number
  ): ImageBoxImageSimple[][] {
    return arr.reduce<ImageBoxImageSimple[][]>((results, _, i) => {
      return i % size
        ? results
        : ([...results, arr.slice(i, i + size)] as ImageBoxImageSimple[][]);
    }, []);
  }

  private static async createFileName(
    dh: FileSystemDirectoryHandle,
    fileName: string,
    extension: string
  ): Promise<string> {
    // 重複してるかのフラグ
    let isExists = true;

    while (isExists) {
      try {
        await dh.getFileHandle(fileName + extension, { create: false });
        fileName = fileName + "_";
      } catch (e) {
        if (e instanceof DOMException) {
          isExists = false;
        }
      }
    }

    return fileName + extension;
  }
}
