export const useTitle = (): {
  setTitle: (title: string) => void;
  setSearchPageTitle: (title: string) => void;
} => {
  const setTitle = (title: string) => {
    document.title = "ImageBox - " + title;
  };

  const setSearchPageTitle = (title: string) => {
    document.title = "IMB - " + title;
  };

  return { setTitle, setSearchPageTitle };
};
