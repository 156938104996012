import React, {
  memo,
  FC,
  useRef,
  FormEvent,
  useCallback,
  ChangeEvent,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { FieldValues, useForm, Controller } from "react-hook-form";
import { Rnd } from "react-rnd";

import ClientFilter from "../../../molecules/ClientFilter";
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Separator from "../../../atoms/Separator";
import SearchDetails from "../../SearchDetails";
import SearchTags from "../../SearchTags";
import { useModal } from "../../../../hooks/useModal";
import { details } from "./DefaultValue";
import SelectOperation from "../../../molecules/SelectOperation";
import { useStateContext } from "../../../../context/StateProvider";
import { useKeyStateContext } from "../../../../context/KeyStateProvider";
import { useSubWindowStateContext } from "../../../../context/SubWindowStateProvider";

type SearchCallback = (
  searchWord: string,
  searchLogicalOperation: string,
  clientFilter: string,
  details: unknown,
  tags: Record<string | number, number>
) => void;

interface Props {
  onSearch: SearchCallback;
  onSelect: SearchCallback;
  onExclude: SearchCallback;
  isLoading: boolean;
}

const SideBar: FC<Props> = ({ onSearch, onSelect, onExclude, isLoading }) => {
  const submitTypeRef = useRef<HTMLInputElement>(null);
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const { openSearch } = useStateContext();
  const { isOpenModal } = useModal();
  const { keyPress } = useKeyStateContext();
  const { windowObjectReferences, setWindowObjectReferences } =
    useSubWindowStateContext();

  const handleOnActivate = () => {
    if (windowObjectReferences.length !== 0) {
      windowObjectReferences
        .filter((obj) => obj.window !== null)
        .map((obj) => obj.focus());
    }
    const filteredWinObjRef = windowObjectReferences.filter((obj) => {
      return obj.window !== null;
    });
    setWindowObjectReferences(filteredWinObjRef);
  };

  const clientFilters = (): string[] => {
    const clientFilter = localStorage.getItem("setting/client_filters");

    if (clientFilter === null || clientFilter.trim().length === 0) {
      return [];
    }
    return clientFilter.split("\n");
  };

  const isPickupMode = () => {
    return keyPress.keyPress.get("Shift");
  };

  const defaultValues = {
    search_word: "",
    client_filter: undefined,
    search_logical_operation: "AND",
    details: details,
  };

  const { register, setValue, watch, reset, control, getValues } =
    useForm<FieldValues>({
      defaultValues: defaultValues,
    });

  const handleOnSubmit = (e: FormEvent) => {
    e?.preventDefault();
    if (isLoading) return;

    if (isPickupMode()) {
      return pickup();
    }

    switch (submitTypeRef?.current?.value) {
      case "submit":
        return search("");
      case "select":
        return pickup();
      case "exclude":
        return exclude();
    }
  };

  const search = (clientFilter: string) => {
    if (!openSearch) return;

    const searchWord = getValues("search_word");
    const operation = getValues("search_logical_operation");
    const details = getValues("details");
    const tags = getValues("tags");

    onSearch(searchWord, operation, clientFilter, details, tags);

    handleOnActivate();
  };

  const pickup = () => {
    if (!openSearch) return;

    const searchWord = getValues("search_word");
    const operation = getValues("search_logical_operation");
    const details = getValues("details");
    const tags = getValues("tags");

    onSelect(searchWord, operation, "", details, tags);
  };

  const exclude = () => {
    if (!openSearch) return;

    const searchWord = getValues("search_word");
    const operation = getValues("search_logical_operation");
    const details = getValues("details");
    const tags = getValues("tags");

    onExclude(searchWord, operation, "", details, tags);
  };

  const selectControl = useCallback(
    (type: string) => {
      if (submitTypeRef && submitTypeRef.current) {
        submitTypeRef.current.value = type;
      }
    },
    [submitTypeRef]
  );

  const clear = useCallback(() => {
    reset(defaultValues);
    searchBoxRef?.current?.focus();
  }, [searchBoxRef]);

  const getClientFilter = () => {
    const clientFilter = getValues("client_filter");
    return clientFilter.replace("／", "/").split("/");
  };

  const onChangeClientFilter = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "---") {
      setValue("client_filter", undefined);
    } else {
      setValue("client_filter", event.target.value);
    }
  };

  useHotkeys(
    "ctrl+f, command+f",
    (e) => {
      if (isOpenModal()) return;
      e?.preventDefault();
      searchBoxRef?.current?.focus();
      searchBoxRef?.current?.select();
    },
    [searchBoxRef, isOpenModal]
  );

  useHotkeys(
    "F1",
    (e) => {
      e?.preventDefault();
      if (isLoading) return;

      if (getValues("client_filter") === undefined) {
        alert("【F1】はクライアントフィルターに設定されていません。");
        return;
      }
      const clients = getClientFilter();
      if (clients.length < 1) {
        alert("【F1】はクライアントフィルターに設定されていません。");
        return;
      }

      search(clients[0]);
    },
    { enableOnTags: ["INPUT", "SELECT"] },
    [onSearch]
  );

  useHotkeys(
    "F2",
    (e) => {
      e?.preventDefault();
      if (isLoading) return;

      if (getValues("client_filter") === undefined) {
        alert("【F2】はクライアントフィルターに設定されていません。");
        return;
      }
      const clients = getClientFilter();
      if (clients.length < 2) {
        alert("【F2】はクライアントフィルターに設定されていません。");
        return;
      }

      search(clients[1]);
    },
    { enableOnTags: ["INPUT", "SELECT"] },
    [onSearch]
  );

  useHotkeys(
    "F3",
    (e) => {
      e?.preventDefault();
      if (isLoading) return;

      if (getValues("client_filter") === undefined) {
        alert("【F3】はクライアントフィルターに設定されていません。");
        return;
      }
      const clients = getClientFilter();
      if (clients.length < 3) {
        alert("【F3】はクライアントフィルターに設定されていません。");
        return;
      }

      search(clients[2]);
    },
    { enableOnTags: ["INPUT", "SELECT"] },
    [onSearch]
  );

  useHotkeys(
    "F4",
    (e) => {
      e?.preventDefault();
      if (isLoading) return;

      if (getValues("client_filter") === undefined) {
        alert("【F4】はクライアントフィルターに設定されていません。");
        return;
      }
      const clients = getClientFilter();
      if (clients.length < 4) {
        alert("【F4】はクライアントフィルターに設定されていません。");
        return;
      }

      search(clients[3]);
    },
    { enableOnTags: ["INPUT", "SELECT"] },
    [onSearch]
  );

  useHotkeys(
    "F5",
    (e) => {
      e?.preventDefault();
      if (isLoading) return;

      if (getValues("client_filter") === undefined) {
        alert("【F5】はクライアントフィルターに設定されていません。");
        return;
      }
      const clients = getClientFilter();
      if (clients.length < 5) {
        alert("【F5】はクライアントフィルターに設定されていません。");
        return;
      }

      search(clients[4]);
    },
    { enableOnTags: ["INPUT", "SELECT"] },
    [onSearch]
  );

  return (
    <Rnd
      style={{ position: "static", transform: "translate(0%, 0%)" }}
      enableResizing={{ right: true }}
      disableDragging={true}
      default={{ x: 0, y: 0, width: 300, height: "100%" }}
      maxWidth={400}
      minWidth={270}
    >
      <aside className="flex flex-col h-full w-full bg-white border p-2">
        <form
          id="search_sidebar"
          className="flex flex-col flex-grow"
          onSubmit={handleOnSubmit}
        >
          <div className="flex flex-row divide-x-2">
            <input type="hidden" ref={submitTypeRef} />
            <Button
              className={`${isLoading ? "cursor-not-allowed" : ""}`}
              width="46%"
              primary={true}
              size="small"
              type="submit"
              onClick={() => selectControl("submit")}
            >
              画像検索
            </Button>
            <SelectOperation control={control} setValue={setValue} />
          </div>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                value={value}
                onChange={onChange}
                inputSize="small"
                width="95%"
                placeholder="検索ワード"
                height={"24px"}
                fontSize={"12px"}
                ref={searchBoxRef}
              />
            )}
            name="search_word"
            control={control}
          />
          <div className="flex flex-row justify-around">
            <Button
              className="px-1 rounded-xl border shadow-md"
              width="20%"
              primary={false}
              size="small"
              onClick={clear}
            >
              クリア
            </Button>
            <Button
              className="px-1 rounded-xl border shadow-md"
              width="40%"
              primary={false}
              size="small"
              type="submit"
              onClick={() => selectControl("select")}
            >
              条件一致から選択
            </Button>
            <Button
              className="px-1 rounded-xl border shadow-md"
              width="40%"
              primary={false}
              size="small"
              type="submit"
              onClick={() => selectControl("exclude")}
            >
              条件一致から削除
            </Button>
          </div>
          <Separator separatorType="horizontal" />
          <Controller
            render={({ field: { value } }) => (
              <ClientFilter
                clientFilters={clientFilters()}
                onChange={onChangeClientFilter}
                value={value}
              />
            )}
            name="client_filter"
            control={control}
          />
          <div className="flex flex-col flex-grow overflow-y-auto h-0">
            <SearchDetails
              setValue={setValue}
              control={control}
              watch={watch}
            />
            <SearchTags
              register={register}
              setValue={setValue}
              control={control}
              watch={watch}
            />
          </div>
        </form>
      </aside>
    </Rnd>
  );
};

export default memo(SideBar);
