import React, { FC, memo, SelectHTMLAttributes } from "react";

import Label from "../../atoms/Label";
import SelectBox from "../../atoms/SelectBox";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  clientFilters: string[];
}

const ClientFilter: FC<Props> = ({ value, clientFilters, ...props }) => {
  return (
    <>
      <Label className="text-12px">クライアントフィルター</Label>
      <SelectBox
        selectSize="small"
        options={["---", ...clientFilters]}
        width="95%"
        spacing="0.25rem"
        value={value === undefined ? "---" : value}
        {...props}
      />
    </>
  );
};

export default memo(ClientFilter);
