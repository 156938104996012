import { useCallback } from "react";
import axios, { AxiosError } from "axios";

import { ApiError, Tag, ImageTag2 } from "../types/types";
import { useError } from "./useError";

interface TagsResponse {
  status: number;
  type: string;
  data_format: string;
  data: Tag[];
}

interface ImageTagResponse {
  status: number;
  type: string;
  data_format: string;
  data: ImageTag2[];
}

export const useTags = (): {
  getTags: () => Promise<Tag[]>;
  getTagsByImageId: (imageId: number | string) => Promise<ImageTag2[]>;
  post: (imageIds: number[], tagIds: number[]) => void;
} => {
  const { setError } = useError();
  const config = { withCredentials: true };

  const getTags = useCallback(async (): Promise<Tag[]> => {
    const { data } = await axios.get<TagsResponse>(
      `${process.env.REACT_APP_API_URL}/api/tags`,
      config
    );
    return data.data;
  }, []);

  const getTagsByImageId = useCallback(
    async (imageId: number | string): Promise<ImageTag2[]> => {
      const { data } = await axios.get<ImageTagResponse>(
        `${process.env.REACT_APP_API_URL}/api/images/${imageId}/tags`,
        config
      );
      return data.data;
    },
    []
  );

  const post = useCallback((imageIds: number[], tagIds: number[]) => {
    const data = { image_ids: imageIds, tag_ids: tagIds };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/images/tags`, data, config)
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        }
      });
  }, []);

  return { getTags, getTagsByImageId, post };
};
