import React, { memo, FC, ChangeEvent, HTMLAttributes } from "react";
import { Property } from "csstype";

import CheckBox from "../../atoms/CheckBox";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  label: string;
  CheckBoxSize?: Property.Width | Property.Height;
  spacing?: Property.Width;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CheckBoxText: FC<Props> = ({
  className = "",
  id,
  label,
  CheckBoxSize = "16px",
  spacing = "0.25rem",
  checked = undefined,
  onChange = () => void 0,
  disabled,
}) => {
  return (
    <div
      className={["flex flex-row items-center", className].join(" ")}
      style={{ margin: spacing }}
    >
      <CheckBox
        id={id}
        checkBoxSize={CheckBoxSize}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label
        className={[
          "text-12px ml-1 cursor-pointer",
          disabled ? "text-gray-text" : "",
        ].join(" ")}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default memo(CheckBoxText);
