import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useState,
} from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
} from "@chakra-ui/react";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Label from "../../../atoms/Label";
import { useModalStateContext } from "../../../../context/ModalStateProvider";

interface Props {
  handleOnSubmitPassword: (password: string) => Promise<void>;
}

const PasswordModal: FC<Props> = ({ handleOnSubmitPassword }) => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const { modal, setModal } = useModalStateContext();

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleOnClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, passwordModal: false };
    });
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e?.preventDefault();
      setIsLoading(true);

      handleOnSubmitPassword(password).catch(() => {
        setError(true);
        setIsLoading(false);
      });
    },
    [handleOnSubmitPassword, password]
  );

  const handleOnReturn = useCallback(
    () =>
      setModal((prevState) => {
        return {
          ...prevState,
          passwordModal: false,
          clientSelectModal: true,
        };
      }),
    []
  );

  return (
    <Modal
      isOpen={modal.passwordModal}
      onClose={handleOnClose}
      isCentered
      closeOnOverlayClick={false}
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent minWidth={640} minHeight={240} m={0}>
        <ModalHeader>
          <Label className="font-bold text-18px" spacing="0.5rem 0">
            パスワードを入力してください
          </Label>
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Label className="text-12px">
              「管理用特殊クライアント」が選択されています
            </Label>
            <Label className="text-12px">
              業務上の使用禁止画像を表示する恐れがありますので、この条件で表示するためにはパスワードが必要です。
            </Label>
            <Label className="text-12px">
              パスワードは画像管理責任者までお問い合わせください。
            </Label>
            {error && (
              <Label className="text-12px text-red-500">
                パスワードが間違っています。
              </Label>
            )}
            <form
              className="w-full flex flex-row justify-center"
              onSubmit={handleSubmit}
            >
              <Input
                type="password"
                placeholder="パスワード"
                value={password}
                onChange={onChangePassword}
                inputSize="large"
                width="100%"
                spacing="1rem 0"
              />
            </form>
          </Stack>
        </ModalBody>
        <ModalFooter className="flex flex-row w-full items-end justify-end">
          <Button
            width="70px"
            primary={false}
            size="large"
            onClick={handleOnReturn}
          >
            戻る
          </Button>
          <Button
            type="submit"
            width="70px"
            primary={true}
            size="large"
            onClick={handleSubmit}
          >
            {loading ? <Spinner /> : "開く"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PasswordModal;
