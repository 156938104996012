import { useCallback, useState } from "react";
import { Property } from "csstype";

interface UseSubWindowProp {
  showImageWindows: (
    url: string,
    client_id: string | number,
    image_id: string | number
  ) => void;
  showSubWindow: (
    url: string,
    width?: Property.Width,
    height?: Property.Height,
    clientId?: string
  ) => void;
}

export const useSubWindow = (): UseSubWindowProp => {
  const [previousUrl, setPreviousUrl] = useState<string>("");
  const [windowObjectReference, setWindowObjectReference] =
    useState<Window | null>(null);

  const showSubWindow = useCallback(
    (
      url: string,
      width?: Property.Width,
      height?: Property.Height,
      target?: string
    ) => {
      if (windowObjectReference === null || windowObjectReference.closed) {
        const windowReference = window.open(
          url,
          target,
          `${width && `width=${width},`}${height && `height=${height},`}`
        );
        setWindowObjectReference(windowReference);
      } else if (previousUrl !== url) {
        const windowReference = window.open(
          url,
          target,
          `${width && `width=${width},`}${height && `height=${height},`}`
        );
        setWindowObjectReference(windowReference);
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }
      setPreviousUrl(url);
    },
    [previousUrl, windowObjectReference]
  );

  /**
   * @param url URL
   * @param client_id クライアントID
   * @param image_id 画像ID
   */
  const showImageWindows = useCallback(
    (url: string, client_id: string | number, image_id: string | number) => {
      const target = `imagebox/${client_id}/large_image/${image_id}`;
      showSubWindow(url, "760px", "850px", target);
    },
    [previousUrl, windowObjectReference, showSubWindow]
  );

  return { showImageWindows, showSubWindow };
};
