import React, { FC, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FieldValues, useForm, Controller } from "react-hook-form";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import SelectBox from "../../../atoms/SelectBox";
import Label from "../../../atoms/Label";
import Textarea from "../../../atoms/Textarea";

import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { SortItem } from "../../../../util/SortItem";
import { Order } from "../../../../util/Order";
import axios from "axios";

const TokenModal: FC = () => {
  const { modal, setModal } = useModalStateContext();

  const defaultValues = {
    limit: 20000,
    sort: "システム登録日時",
    order: "降順",
    client_filters: "",
  };

  const { setValue, control, getValues } = useForm<FieldValues>({
    defaultValues: defaultValues,
  });

  const getSortItem = () => {
    const sortItem = [];
    for (const [, value] of Object.entries(SortItem)) {
      sortItem.push(value.toString());
    }
    return sortItem;
  };

  const getOrder = () => {
    const order = [];
    for (const [key] of Object.entries(Order)) {
      order.push(key);
    }
    return order;
  };

  const handleOnSave = useCallback(async () => {
    const config = { withCredentials: true };
    await axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/token`, {}, config)
        .then((data) => {
          setModal((prevState) => {
            console.log(data.data);
            return { ...prevState, tokenModal: false, tokenResultModal: true, tokenResultDataIP: data.data.ipaddress, tokenResultDataToken: data.data.token };
          });
        });
  }, []);

  const handleOnClose = useCallback(() => {
    setModal({ ...modal, tokenModal: false, tokenResultDataIP: null, tokenResultDataToken: null });
  }, [modal, setModal]);

  return (
    <Modal
      isOpen={modal.tokenModal}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent rounded={"md"} shadow={"md"} minWidth="600px" m={0}>
        <ModalHeader>
          <Label className="font-bold">パスワードトークン発行</Label>
        </ModalHeader>
        <ModalBody width="100%">
          <div className="flex flex-col">
            <div className="flex flex-row text-12px">
              ImageBoxAssistにログインする際にパスワードの代わりとして利用するパスワードトークンを発行します。<br/>
              <br/>
              パスワードトークンは1アカウントあたり1件まで・最新の発行内容のみ有効になります。<br/>
              パスワードトークンはグローバルIPと紐付けされます。作業拠点が変わる場合は再度発行してください。<br/>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-row items-end">
          <Button
            width="100px"
            primary={false}
            size="medium"
            onClick={handleOnClose}
          >
            キャンセル
          </Button>
          <Button
            width="100px"
            primary={true}
            size="medium"
            onClick={handleOnSave}
          >
            発行
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TokenModal;
