import React, { FC } from "react";

import { useAuthContext } from "../../../../context/AuthProvider";

const RegisterPageHeader: FC = () => {
  const { user } = useAuthContext();

  return (
    <header className="bg-black-header items-center justify-between inline-flex px-2 shadow-2xl top-0 w-full h-30px">
      <p className="ml-l text-20px text-white font-sans inline-flex items-center">
        ImageBox
      </p>
      <div>
        <p className="font-sans text-white text-14px mr-2">
          {user?.username ?? ""}
        </p>
      </div>
    </header>
  );
};

export default RegisterPageHeader;
