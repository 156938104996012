import { useCallback } from "react";
import dayjs from "dayjs";

export const useDate = (): {
  formatDate: (strDate: string, formatType: string) => undefined | string;
  getDate: (template?: string | undefined) => string;
} => {
  const formatDate = useCallback(
    (strDate: string | undefined, formatType: string) => {
      const date = dayjs(strDate);
      if (!date.isValid()) return "";

      return date.format(formatType);
    },
    []
  );

  const getDate = useCallback((template: string | undefined = undefined) => {
    return dayjs().format(template);
  }, []);

  return { formatDate, getDate };
};
