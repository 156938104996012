import React, { FC, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FieldValues, useForm, Controller } from "react-hook-form";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import SelectBox from "../../../atoms/SelectBox";
import Label from "../../../atoms/Label";
import Textarea from "../../../atoms/Textarea";

import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { SortItem } from "../../../../util/SortItem";
import { Order } from "../../../../util/Order";

const TokenResultModal: FC = () => {
  const { modal, setModal } = useModalStateContext();

  const getSortItem = () => {
    const sortItem = [];
    for (const [, value] of Object.entries(SortItem)) {
      sortItem.push(value.toString());
    }
    return sortItem;
  };

  const getOrder = () => {
    const order = [];
    for (const [key] of Object.entries(Order)) {
      order.push(key);
    }
    return order;
  };

  const handleOnClose = useCallback(() => {
    setModal({ ...modal, tokenResultModal: false });
  }, [modal, setModal]);

  return (
    <Modal
      isOpen={modal.tokenResultModal}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent rounded={"md"} shadow={"md"} minWidth="600px" m={0}>
        <ModalHeader>
          <Label className="font-bold">パスワードトークン発行完了</Label>
        </ModalHeader>
        <ModalBody width="100%">
          <div className="flex flex-col">
            <div className="flex flex-col text-12px">
              パスワードトークンを拠点（{modal.tokenResultDataIP}）に紐付けて発行しました。<br/>
              <br/>
              <Input inputSize={"large"}
                  value={modal.tokenResultDataToken ?? ''}
                  readOnly={true}></Input>
              <br/>
              <div className={"font-bold text-red-500"}>
                発行したパスワードトークンは今後表示されません。<br/>
                発行したパスワードトークンはパスワードと同じ様に厳重に取り扱ってください。<br/>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-row items-end">
          <Button
            width="100px"
            primary={false}
            size="medium"
            onClick={handleOnClose}
          >
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TokenResultModal;
