import React, { FC, memo } from "react";

import Page403 from "./Page403";
import SearchTemp from "../templates/SearchTemp";
import { useTitle } from "../../hooks/useTitle";
import { useAuthContext } from "../../context/AuthProvider";

const Search: FC = () => {
  useTitle().setTitle("画像検索");

  const { user } = useAuthContext();

  const isIncludes = (arr: string[] | undefined, target: string[]): boolean => {
    if (arr === undefined) return false;
    return arr.some((el) => target.includes(el));
  };

  if (
    user !== undefined &&
    !isIncludes(user?.permission, ["ImageAdmin", "Browser"])
  ) {
    return <Page403 />;
  }

  return <SearchTemp />;
};

export default memo(Search);
