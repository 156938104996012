import React, { FC, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FieldValues, useForm, Controller } from "react-hook-form";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import SelectBox from "../../../atoms/SelectBox";
import Label from "../../../atoms/Label";
import Textarea from "../../../atoms/Textarea";

import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { SortItem } from "../../../../util/SortItem";
import { Order } from "../../../../util/Order";

const SettingModal: FC = () => {
  const { modal, setModal } = useModalStateContext();

  const defaultValues = {
    limit: 20000,
    sort: "システム登録日時",
    order: "降順",
    client_filters: "",
  };

  const { setValue, control, getValues } = useForm<FieldValues>({
    defaultValues: defaultValues,
  });

  const getSortItem = () => {
    const sortItem = [];
    for (const [, value] of Object.entries(SortItem)) {
      sortItem.push(value.toString());
    }
    return sortItem;
  };

  const getOrder = () => {
    const order = [];
    for (const [key] of Object.entries(Order)) {
      order.push(key);
    }
    return order;
  };

  useEffect(() => {
    const limit = localStorage.getItem("setting/limit") ?? 20000;
    const sort = localStorage.getItem("setting/sort") ?? "システム登録日時";
    const order = localStorage.getItem("setting/order") ?? "降順";
    const client_filters = localStorage.getItem("setting/client_filters") ?? "";

    setValue("limit", limit);
    setValue("sort", sort);
    setValue("order", order);
    setValue("client_filters", client_filters);
  }, []);

  const handleOnSave = useCallback(() => {
    const limit = getValues("limit") ?? 20000;
    const client_filters = getValues("client_filters") ?? "";
    const sort = getValues("sort") ?? "システム登録日時";
    const order = getValues("order") ?? "降順";

    localStorage.setItem("setting/limit", limit);
    localStorage.setItem("setting/sort", sort);
    localStorage.setItem("setting/order", order);
    localStorage.setItem("setting/client_filters", client_filters);

    alert("保存が完了しました");
    handleOnClose();
  }, []);

  const handleOnClose = useCallback(() => {
    setModal({ ...modal, settingModal: false });
  }, [modal, setModal]);

  return (
    <Modal
      isOpen={modal.settingModal}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent rounded={"md"} shadow={"md"} minWidth="600px" m={0}>
        <ModalHeader>
          <Label className="font-bold">設定変更</Label>
        </ModalHeader>
        <ModalBody width="100%">
          <div className="flex flex-col">
            <div className="flex flex-row justify-around">
              <div className="inline-flex items-center justify-end text-12px font-sans w-70px">
                表示件数
              </div>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Input
                    placeholder="20000"
                    type="number"
                    value={value}
                    onChange={onChange}
                    spacing="0"
                    inputSize="large"
                    width="440px"
                  />
                )}
                name="limit"
                control={control}
              />
            </div>
            <div className="flex flex-row justify-around my-2">
              <div className="inline-flex items-center justify-end text-12px font-sans w-70px">
                並び順
              </div>
              <div className="flex flex-row justify-between w-440px">
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <SelectBox
                      options={getSortItem()}
                      value={value}
                      onChange={onChange}
                      spacing="0"
                      width="330px"
                      selectSize="large"
                    />
                  )}
                  name="sort"
                  control={control}
                />
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <SelectBox
                      options={getOrder()}
                      value={value}
                      onChange={onChange}
                      spacing="0"
                      width="90px"
                      selectSize="large"
                    />
                  )}
                  name="order"
                  control={control}
                />
              </div>
            </div>
            <div className="flex flex-row justify-around">
              <div className="inline-flex justify-end items-center text-12px font-sans w-70px">
                フィルタ
              </div>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Textarea
                    className="w-440px text-12px px-2"
                    value={value}
                    onChange={onChange}
                  />
                )}
                name="client_filters"
                control={control}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-row items-end">
          <Button
            width="100px"
            primary={false}
            size="large"
            onClick={handleOnClose}
          >
            キャンセル
          </Button>
          <Button
            width="100px"
            primary={true}
            size="large"
            onClick={handleOnSave}
          >
            保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SettingModal;
