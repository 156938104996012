import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Mode } from "../../../types/types";
import Button from "../../atoms/Button";
import { useAuthContext } from "../../../context/AuthProvider";

interface Props {
  mode?: Mode;
  handleOnEdit: () => void;
  handleOnSave: () => void;
  handleOnReference: () => void;
  handleOnMulti: () => void;
  handleOfUpdateDetail: (event: StorageEvent) => void;
}

const SelectMode: FC<Props> = ({
  mode,
  handleOnEdit,
  handleOnSave,
  handleOnReference,
  handleOnMulti,
  handleOfUpdateDetail,
}) => {
  const [lock, setIsLock] = useState<boolean>(false);
  const { user } = useAuthContext();
  const settings = localStorage.getItem("detail/display");

  const defaultButtonClassName = useMemo(() => {
    return "inline-flex items-center justify-center border border-gray-border w-50px h-22px p-1";
  }, []);

  const editButtonClassName = useMemo(() => {
    if (!user?.permission?.includes("ImageAdmin")) {
      return "bg-gray-300 text-gray-text cursor-not-allowed";
    }

    switch (mode) {
      case "edit":
        return "bg-gray-500 text-white cursor-auto";
      default:
        return "bg-green-teal text-white hover:bg-opacity-50 cursor-pointer";
    }
  }, [mode, user]);

  const saveButtonClassName = useMemo(() => {
    if (!user?.permission?.includes("ImageAdmin")) {
      return "bg-gray-300 text-gray-text cursor-not-allowed";
    }

    switch (mode) {
      case "edit":
        return "bg-green-teal text-white hover:bg-opacity-50 cursor-pointer";
      default:
        return "bg-gray-300 text-gray-text";
    }
  }, [mode, user]);

  const referenceButtonClassName = useMemo(() => {
    switch (mode) {
      case "edit":
        return "bg-green-teal text-white hover:bg-opacity-50 cursor-pointer";
      default:
        return "bg-gray-500 text-white cursor-auto";
    }
  }, [mode]);

  const multiButtonClassName = useMemo(() => {
    switch (mode) {
      case "edit":
        return "bg-gray-300 text-gray-text cursor-auto";
      default:
        return "bg-green-teal text-white hover:bg-opacity-50 cursor-pointer";
    }
  }, [mode]);

  // ロック処理
  const handleOnLock = useCallback(() => {
    setIsLock((prevState) => !prevState);
  }, []);

  // ショートカットキー
  useHotkeys(
    "F2",
    (e) => {
      e?.preventDefault();
      handleOnEdit();
    },
    { enableOnTags: ["INPUT", "TEXTAREA"] },
    [handleOnEdit]
  );

  useHotkeys(
    "esc",
    (e) => {
      e?.preventDefault();
      handleOnReference();
    },
    { enableOnTags: ["INPUT", "TEXTAREA"] },
    [handleOnReference]
  );

  useHotkeys(
    "ctrl+s, command+s",
    (e) => {
      e?.preventDefault();
      handleOnSave();
    },
    { enableOnTags: ["INPUT", "TEXTAREA"] },
    [handleOnEdit]
  );

  useEffect(() => {
    if (lock) {
      window.removeEventListener("storage", handleOfUpdateDetail);
    } else {
      window.addEventListener("storage", handleOfUpdateDetail);
    }

    return () => window.removeEventListener("storage", handleOfUpdateDetail);
  }, [lock, handleOfUpdateDetail]);

  return (
    <div className="flex flex-row p-1 bg-white w-full">
      <Button
        className={`${[editButtonClassName, defaultButtonClassName].join(" ")}`}
        onClick={handleOnEdit}
        disabled={mode === "edit"}
        rounded="none"
        spacing="0"
      >
        編集
      </Button>
      <Button
        className={`${[saveButtonClassName, defaultButtonClassName].join(" ")}`}
        onClick={handleOnSave}
        disabled={mode !== "edit"}
        rounded="none"
        spacing="0"
      >
        保存
      </Button>
      <Button
        className={`${[referenceButtonClassName, defaultButtonClassName].join(
          " "
        )}`}
        onClick={handleOnReference}
        disabled={mode !== "edit"}
        rounded="none"
        spacing="0"
      >
        参照
      </Button>
      <div className="w-50px h-22px" />
      <Button
        className={[
          "bg-green-teal hover:bg-opacity-50 text-white",
          defaultButtonClassName,
        ].join(" ")}
        onClick={handleOnLock}
        rounded="none"
        spacing="0"
      >
        {lock ? "解除" : "ロック"}
      </Button>
      <Button
        className={`${[multiButtonClassName, defaultButtonClassName].join(
          " "
        )}`}
        onClick={handleOnMulti}
        disabled={mode === "edit"}
        rounded="none"
        spacing="0"
      >
        {settings === "multi" ? "単体表示" : "複数表示"}
      </Button>
    </div>
  );
};

export default memo(SelectMode);
