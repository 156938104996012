import axios, { AxiosError } from "axios";

import { ApiError, ImageBoxImageDetail } from "../types/types";
import { useError } from "./useError";

interface MultiUpdateProps {
  image_ids: number[];
  color: number | null;
  new_old_type: number | null;
  maker: string | null;
  supplier_name: string | null;
  locality: string | null;
  plan_end_date: string | null;
}

export const useImageDetails = (): {
  get: (
    image_id: string | number,
    client_id: string | number
  ) => Promise<ImageBoxImageDetail>;
  post: (data: unknown) => Promise<boolean>;
  multiUpdate: (data: MultiUpdateProps) => void;
} => {
  const { setError } = useError();

  const config = { withCredentials: true };

  const get = async (
    image_id: string | number,
    client_id: string | number
  ): Promise<ImageBoxImageDetail> => {
    const { data } = await axios.get<ImageBoxImageDetail>(
      `${process.env.REACT_APP_API_URL}/api/images/${image_id}?cid=${client_id}`,
      config
    );
    return data;
  };

  const post = async (data: unknown): Promise<boolean> => {
    let result = true;
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/detail/update`, data, config)
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          if (e.response.status === 400) {
            alert(e.response.data.message);
          } else {
            setError(e.response.data);
          }
        } else {
          setError(e.message);
        }
        result = false;
      });
    return result;
  };

  const multiUpdate = (data: MultiUpdateProps) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/detail/multiUpdate`,
        data,
        config
      )
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        } else {
          setError(e);
        }
      });
  };

  return { get, post, multiUpdate };
};
