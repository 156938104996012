import React, { memo, FC, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import Label from "../../../atoms/Label";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useSearchStateContext } from "../../../../context/SearchStateProvider";
import { useDownloadStateContext } from "../../../../context/DownloadStateProvider";
import { useSearch } from "../../../../hooks/useSearch";
import { useError } from "../../../../hooks/useError";
import { ApiError } from "../../../../types/types";
import { useStateContext } from "../../../../context/StateProvider";

interface DownloadState {
  state: string;
  tasks: number;
  completed: number;
}

const ImageCardListFooter: FC = () => {
  const [count, setCount] = useState<number>(0);
  const [clientCount, setClientCount] = useState<number>(0);

  const selected = useSelectedItemStateContext();
  const { searchData } = useSearchStateContext();
  const { progressMap } = useDownloadStateContext();
  const { openSearch } = useStateContext();

  const { client_id } = useParams<{ client_id: string }>();

  const { imageCount } = useSearch();
  const { setError } = useError();

  const sort = localStorage.getItem("setting/sort") ?? "画像タイトル";
  const order = localStorage.getItem("setting/order") ?? "降順";

  const selectedItemCount = useMemo(() => {
    return selected.selectedItem.length.toLocaleString();
  }, [selected]);
  const searchDataCount = useMemo(() => {
    return searchData?.length !== undefined
      ? searchData.length.toLocaleString()
      : "0";
  }, [searchData]);
  const clientAvailableImageCount = useMemo(() => {
    return clientCount.toLocaleString();
  }, [clientCount]);
  const allImageCount = useMemo(() => {
    return count.toLocaleString();
  }, [count]);

  const progressS = function (progressMap: Map<string, DownloadState>) {
    const arrays = Array.from(progressMap.values());
    return arrays
      .filter((progress) => progress.tasks > progress.completed)
      .map((progress, i) => {
        // eslint-disable-next-line react/jsx-key
        return (
          <div
            className={[
              `inline-flex items-center mr-2`,
              progress.state === "error" ? "text-red-500" : "",
            ].join(" ")}
            key={i}
          >{`${progress.completed} / ${progress.tasks} ${
            progress.state === "error" ? " （エラー発生）" : ""
          }`}</div>
        );
      })
      .values()
      .next().value;
  };

  useEffect(() => {
    if (!openSearch) return;

    (async () => {
      if (client_id) {
        try {
          const data = await imageCount(client_id);

          setCount(data.count);
          setClientCount(data.client_count);
        } catch (e) {
          const response = (e as AxiosError<ApiError>).response;
          if (response) {
            setError(response.data);
          }
        }
      }
    })();
  }, [client_id, openSearch]);

  return (
    <div className="flex flex-row mt-auto shadow-md bg-white h-22px justify-between items-center px-2 text-12px">
      <div className="flex flex-row whitespace-nowrap">
        <Label className="">{`${selectedItemCount} / ${searchDataCount} / ${clientAvailableImageCount} / ${allImageCount}`}</Label>
        <Label>{`${sort}／${order}`}</Label>
      </div>
      {progressS(progressMap)}
    </div>
  );
};

export default memo(ImageCardListFooter);
