import React, { FC, HTMLAttributes, memo } from "react";

const TableHeader: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return <div {...props}>{children}</div>;
};

export default memo(TableHeader);
