import React, { FC, memo } from "react";

interface Props {
  className?: string;
  separatorType: "vertical" | "horizontal";
}

const Separator: FC<Props> = ({ className = "", separatorType }) => {
  return (
    <>
      {separatorType === "vertical" ? (
        <div className={`w-1px h-full bg-gray-line mx-2 my-1 ${className}`} />
      ) : (
        <hr className={`bg-gray-line h-1px m-1 ${className}`} />
      )}
    </>
  );
};

export default memo(Separator);
