import axios, { AxiosError } from "axios";
import { useError } from "./useError";
import { ApiError, Client } from "../types/types";

interface UseClientResponse {
  data: Client[];
  data_format: string;
  status: number;
  type: string;
}

export const useUsesClient = (): {
  get: (imageId: number | string) => Promise<Client[]>;
  post: (imageId: number | string, clientIds: number[]) => void;
} => {
  const config = { withCredentials: true };
  const { setError } = useError();

  const get = async (imageId: number | string): Promise<Client[]> => {
    const { data } = await axios.get<UseClientResponse>(
      `${process.env.REACT_APP_API_URL}/api/images/${imageId}/uses`,
      config
    );
    return data.data;
  };

  const post = (imageId: number | string, clientIds: number[]) => {
    const data = { client_ids: clientIds };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/images/${imageId}/uses`,
        data,
        config
      )
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        }
      });
  };

  return { get, post };
};
