import React, { FC, useRef } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import Button from "../../../atoms/Button";
import { useHotkeys } from "react-hotkeys-hook";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onOk?: () => void;
  headerMessage?: string;
  message?: string;
}

const Confirm: FC<Props> = (props) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const onClickOk = () => {
    props.onOk !== undefined && props.onOk();
    props.onClose();
  };

  const onClickCancel = () => {
    props.onCancel !== undefined && props.onCancel();
    props.onClose();
  };

  // ショートカットキー
  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();
      onClickOk();
    },
    [onClickOk]
  );

  return (
    <AlertDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      leastDestructiveRef={cancelRef}
      motionPreset={"none"}
      onOverlayClick={undefined}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <AlertDialogOverlay className={"overflow-y-hidden"} />
      <AlertDialogContent>
        {props.headerMessage ? (
          <AlertDialogHeader>{props.headerMessage}</AlertDialogHeader>
        ) : (
          <div className={"pt-5"} />
        )}
        <AlertDialogBody>{props.message}</AlertDialogBody>
        <AlertDialogFooter>
          <Button
            primary={true}
            onClick={onClickOk}
            width={"80px"}
            size={"medium"}
          >
            OK
          </Button>
          <Button
            primary={false}
            size={"medium"}
            onClick={onClickCancel}
            ref={cancelRef}
            width={"80px"}
          >
            キャンセル
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Confirm;
