import React, { FC, memo, useEffect, useState } from "react";
import Label from "../../atoms/Label";
import Input from "../../atoms/Input";
import SelectBox from "../../atoms/SelectBox";

interface Props {
  size: number;
}

const FileSize: FC<Props> = ({ size }) => {
  const [fileSize, setFileSize] = useState<string>("");
  const [prefix, setPrefix] = useState<"KB" | "MB">("KB");

  useEffect(() => {
    switch (prefix) {
      case "KB":
        setFileSize(Math.round(size / Math.pow(1024, 1)) + "KB");
        break;
      case "MB":
        setFileSize(Math.round(size / Math.pow(1024, 2)) + "MB");
        break;
    }
  }, [prefix, size]);

  return (
    <>
      <Label spacing={0}>ファイルサイズ</Label>
      <div className="flex flex-row">
        <Input
          type="text"
          inputSize="small"
          width="85%"
          spacing="0"
          readOnly
          value={fileSize}
        />
        <SelectBox
          options={["KB", "MB"]}
          selectSize="small"
          width="15%"
          onChange={(event) => setPrefix(event.target.value as "KB" | "MB")}
        />
      </div>
    </>
  );
};

export default memo(FileSize);
