import React, { ChangeEvent, FC, useCallback, useState } from "react";

import ImageRegisterForm from "../organisms/ImageRegisterForm";
import ImageRegisterHistoryList from "../organisms/ImageRegisterHistoryList";
import RegisterPageHeader from "../organisms/Layout/RegisterPageHeader";
import { useTitle } from "../../hooks/useTitle";
import { useAuthContext } from "../../context/AuthProvider";
import Page403 from "./Page403";
import ErrorDialog from "../organisms/Modal/ErrorDialog";
import axios, { AxiosError } from "axios";
import { ApiError, RegisterTask } from "../../types/types";
import { useError } from "../../hooks/useError";

interface Response {
  status: number;
  type: string;
  data_format: string;
  data: Array<RegisterTask>;
}

const Register: FC = () => {
  useTitle().setTitle("画像登録");

  const [isContainOtherUser, setIsContainOtherUser] = useState<boolean>(false);

  const { user } = useAuthContext();
  if (user !== undefined && !user?.permission?.includes("ImageAdmin")) {
    return <Page403 />;
  }

  const [registerTasks, setRegisterTasks] = useState<Array<RegisterTask>>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const { setError } = useError();

  const onChangeIsContainOtherUser = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsContainOtherUser(e.target.checked);
    },
    []
  );

  const handleOnSearch = useCallback(
    async (date: string) => {
      if (date.trim().length === 0) {
        alert("日付が入力されていません。");
        return;
      }

      const data = { date: date, contain_other_user: isContainOtherUser };
      const config = { withCredentials: true };

      setIsLoading(true);

      await axios
        .post<Response>(
          `${process.env.REACT_APP_API_URL}/api/register/history`,
          data,
          config
        )
        .then((data) => {
          setRegisterTasks(data.data.data);
          setIsSearched(true);
        })
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            if (e.response.status === 400) {
              alert(e.response.data.message);
            } else {
              setError(e.response.data);
            }
          } else {
            setError(e);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isContainOtherUser]
  );

  return (
    <div className="flex flex-col w-screen h-screen">
      <RegisterPageHeader />
      <div className="flex flex-col flex-grow flex-1 p-2 items-center bg-gray-background h-full overflow-y-auto">
        <ImageRegisterForm onHistorySearch={handleOnSearch} />
        <div className="mb-10" />
        <ImageRegisterHistoryList
          registerTasks={registerTasks}
          isSearched={isSearched}
          loading={loading}
          onChangeIsContainOtherUser={onChangeIsContainOtherUser}
          isContainOtherUser={isContainOtherUser}
          onHistorySearch={handleOnSearch}
        />
      </div>
      <ErrorDialog />
    </div>
  );
};

export default Register;
