import React, { FC, memo, useState, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

import { useStateContext } from "../../../context/StateProvider";
import ListWrapper from "./ListWrapper";
import { useSearchStateContext } from "../../../context/SearchStateProvider";

const GAP_SIZE = 5;

const ImageCardList: FC = () => {
  const { size } = useStateContext();
  const { searchData } = useSearchStateContext();
  const [imageHeight, setImageHeight] = useState<number>(138);
  const [cardSize, setCardSize] = useState<{ height: number; width: number }>({
    height: 220,
    width: 140,
  });

  useEffect(() => {
    switch (size) {
      case "small":
        setCardSize({
          height: 140,
          width: 80,
        });
        setImageHeight(78);
        break;
      case "middle":
        setCardSize({
          height: 186,
          width: 110,
        });
        setImageHeight(108);
        break;
      case "large":
        setCardSize({
          height: 230,
          width: 140,
        });
        setImageHeight(138);
        break;
    }
  }, [size]);

  return (
    <div className="flex flex-col flex-1 mt-auto bottom-0">
      <AutoSizer className="overflow-x-hidden">
        {({ height, width }) => (
          <ListWrapper
            height={height}
            width={width}
            gapSize={GAP_SIZE}
            cardSize={cardSize}
            imageHeight={imageHeight}
            searchData={searchData}
            itemCount={searchData.length}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default memo(ImageCardList);
