import React, { FC, memo } from "react";

import { ImageBoxImageDetail } from "../../types/types";
import Label from "../atoms/Label";
import Image from "../atoms/Image";

interface Props {
  data: ImageBoxImageDetail | undefined;
}

const ScaleImageTemp: FC<Props> = ({ data }) => {
  return (
    <div className="flex flex-col font-sans">
      <div className="flex flex-col bg-gray-background border border-gray-line p-2 text-0.9rem h-100px w-750px">
        <Label spacing={0}>{`${data?.image_code ?? ""}${
          data?.title ? `(${data.title})` : ""
        }`}</Label>
        <div className="p-2">
          <Label spacing={0}>{`${data?.maker ?? ""} ${
            data?.locality ?? ""
          }`}</Label>
          <Label spacing={0}>{`${data?.product_name ?? ""}`}</Label>
          <Label spacing={0}>{`${data?.spec ?? ""}`}</Label>
        </div>
      </div>
      <Image
        src={`${process.env.REACT_APP_API_URL}/api/images/${data?.id}/thumbnail?scale=large`}
        width="750px"
        height="750px"
      />
    </div>
  );
};

export default memo(ScaleImageTemp);
