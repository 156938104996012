import React, { FC, InputHTMLAttributes, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Input from "../Input";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onChangeFile: (file: File[]) => void;
}

const FileInputZone: FC<Props> = (props) => {
  const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
    props.onChangeFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    multiple: undefined,
    onDragEnter: undefined,
    onDragOver: undefined,
    onDragLeave: undefined,
    accept: props.accept,
  });

  return (
    <div
      className={[
        "border-2 border-dashed cursor-pointer",
        props.className,
      ].join(" ")}
      {...getRootProps()}
    >
      <Input {...getInputProps()} />
      {props.children}
    </div>
  );
};

export default FileInputZone;
