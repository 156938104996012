import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import Label from "../../atoms/Label";
import Input from "../../atoms/Input";
import NewAndOldCategory from "../../molecules/NewAndOldCategory";
import LabelSelectBox from "../../atoms/LabelSelectBox";

const initialClassName = "flex flex-col overflow-hidden";

interface Props {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

const SearchDetails: FC<Props> = ({ control, setValue, watch }) => {
  const [active, setActive] = useState<boolean>(true);
  const [height, setHeight] = useState<string>("1000px");
  const [className, setClassName] = useState<string>(initialClassName);
  const toggleRef = useRef<HTMLDivElement>(null);

  const toggleDetails = useCallback(() => {
    setActive(!active);
    setClassName(
      [initialClassName, "transition-max-height duration-100 ease-in-out"].join(
        " "
      )
    );
    if (toggleRef?.current !== null)
      setHeight(active ? "0px" : `${toggleRef.current?.scrollHeight}px`);
  }, [active]);

  useEffect(() => {
    setHeight(`${toggleRef.current?.scrollHeight}px`);
  }, []);

  const onChangeColor = useCallback((e: never) => {
    String(e) === "0"
      ? setValue("details.color", "")
      : setValue("details.color", String(e));
  }, []);

  return (
    <div className="flex flex-col bg-gray-background bg-opacity-70 text-12px">
      <div
        className="bg-white flex flex-row items-center justify-between border border-gray-border shadow-md p-1 cursor-pointer"
        onClick={toggleDetails}
      >
        <Label>詳細条件</Label>
        <FontAwesomeIcon
          icon={active ? faSortUp : faSortDown}
          style={{ margin: "0 5px 3px 0" }}
        />
      </div>
      <div className={className} style={{ maxHeight: height }} ref={toggleRef}>
        <div className="flex flex-col items-center w-full py-1 px-2">
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="画像タイトル"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.title"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="画像ID"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.image_code"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="メーカー"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.maker"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="産地"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.locality"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="品名・イメージ１"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.product_name"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="品名・イメージ２"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.product_name2"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="規格・商品詳細"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.spec"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="JANコード"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.jan_code"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="品名ファイル名"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.product_filename"
            control={control}
          />
          <NewAndOldCategory
            control={control}
            setValue={setValue}
            watch={watch}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="企画終了日"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.plan_end_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="備考"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.note"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="参考価格"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.price"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="利用開始日"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.use_start_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="売出日"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.offering_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="各種コード"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.various_code"
            control={control}
          />
          <div className="flex flex-row w-full items-center mt-1">
            <Label className="inline-flex items-center">ラベル</Label>
            <Controller
              render={({ field: { value } }) => (
                <LabelSelectBox
                  value={value !== "" ? parseInt(value) : 0}
                  onChange={onChangeColor}
                />
              )}
              name="details.color"
              control={control}
            />
          </div>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="支給元名"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.supplier_name"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="ベース画像ID"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.base_image_id"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="使用権画像区分ID"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.share_pattern"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="システム登録日時"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.create_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="システム更新日時"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.update_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="ファイル登録日時"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.file_create_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="ファイル更新日時"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.file_update_date"
            control={control}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Input
                placeholder="ファイルサイズ"
                type="text"
                inputSize="small"
                width="95%"
                spacing="0.5rem 0.1rem 0 0"
                value={value}
                onChange={onChange}
              />
            )}
            name="details.file_size"
            control={control}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(SearchDetails);
