import React, { memo, FC, ImgHTMLAttributes } from "react";
import { Property } from "csstype";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  src: string;
  height?: Property.Height;
  width?: Property.Width;
  maxWidth?: Property.MaxWidth;
}

const Image: FC<Props> = ({
  className = "",
  src,
  height = "auto",
  width = "auto",
  maxWidth = "none",
  ...props
}) => {
  const defaultClassName = "object-cover";

  return (
    <img
      className={[defaultClassName, className].join(" ")}
      src={src}
      alt="アイコン"
      style={{ height: height, width: width, maxWidth: maxWidth }}
      loading="lazy"
      {...props}
    />
  );
};

export default memo(Image);
