import React, { FC } from "react";

import { Spinner } from "@chakra-ui/react";

const SearchLoading: FC = () => {
  return (
    <div className="flex flex-1 justify-center items-center">
      <Spinner color="teal" size="xl" />
    </div>
  );
};

export default SearchLoading;
