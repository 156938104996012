import React, { FC, memo, ChangeEvent } from "react";
import { Property } from "csstype";

interface Props {
  className?: string;
  id: string;
  value: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  spacing?: Property.Margin;
}

const Radio: FC<Props> = ({
  className = "",
  id,
  value,
  checked,
  onChange,
  spacing = "0.25rem",
}) => {
  const defaultClassName = "text-green-teal h-14px w-14px cursor-pointer";
  return (
    <input
      type="radio"
      className={[defaultClassName, className].join(" ")}
      value={value}
      id={id}
      checked={checked}
      onChange={onChange}
      style={{ margin: spacing }}
    />
  );
};

export default memo(Radio);
