import { useCallback, useState } from "react";
import axios from "axios";

import { useSelectedItemStateContext } from "../context/SelectedItemStateProvider";
import { useSearchStateContext } from "../context/SearchStateProvider";
import {
  DownloadOption,
  DownloadStorageRepository,
} from "../repositories/DownloadStorageRepository";
import { ImageBoxImageSimple } from "../types/types";
import { useDownloadStateContext } from "../context/DownloadStateProvider";
import { useErrorStateContext } from "../context/ErrorStateProvider";

interface useDownloadProp {
  downloadFile: (clientId: number | string, options?: DownloadOption) => void;
  selectFolder: () => void;
  folder: FileSystemDirectoryHandle | null;
}

interface downloadHistoryResponse {
  status: number;
  type: string;
  data_format: string;
  data: number;
}

export const useDownload = (): useDownloadProp => {
  const { selectedItem } = useSelectedItemStateContext();
  const { searchData } = useSearchStateContext();
  const { addTasks, endTask, errorTask } = useDownloadStateContext();
  const { setIsError, setErrorMessage } = useErrorStateContext();

  const [folder, setFolder] = useState<FileSystemDirectoryHandle | null>(null);
  const config = { withCredentials: true };

  /**
   * フォルダを選択する
   */
  const selectFolder = useCallback(async () => {
    if ("showDirectoryPicker" in window) {
      const dh = await window.showDirectoryPicker();
      setFolder(dh);
    } else {
      alert("フォルダの選択はサポートされていません");
    }
  }, []);

  /**
   * 進捗データ込みのダウンロード処理
   *
   * @param selectedItem
   * @param options
   */
  const downloadFileOfChrome = useCallback(
    async (
      selectedItem: ImageBoxImageSimple[],
      historyId: number,
      currentFolder: FileSystemDirectoryHandle,
      options?: DownloadOption
    ) => {
      const length = selectedItem.length;

      const repository = new DownloadStorageRepository();
      const chunks = repository.chunk(selectedItem, 10);
      addTasks(length);

      for (const chunk of chunks) {
        const requests = DownloadStorageRepository.makeRequests(
          currentFolder,
          chunk,
          historyId,
          options
        );
        requests.forEach((p) => {
          p.then(() => {
            endTask();
          }).catch((e) => {
            if (e instanceof DOMException) {
              setErrorMessage(
                [
                  "選択したフォルダに問題が発生しました。再度フォルダを選択してください。",
                  String(e),
                ].join("\n")
              );
              setFolder(null);
            } else {
              setErrorMessage(
                ["ダウンロードに失敗しました。", String(e)].join("\n")
              );
            }
            setIsError(true);
            errorTask();
          });
        });
        await Promise.all(requests);
      }
    },
    []
  );

  const cacheBusting = (data: ImageBoxImageSimple) => {
    if (data != null && data.update_date) {
      return new Date(data.update_date).getTime();
    }
    return "none";
  };

  const downloadFileWithAnchor = useCallback(
    async (
      selectedItem: ImageBoxImageSimple[],
      historyId: number,
      option?: DownloadOption
    ) => {
      const prefix = makePrefix(option);

      const length = selectedItem.length;

      addTasks(length);

      for (const data of selectedItem) {
        const name = option?.product ? data.product_filename : data.image_code;
        const filename = prefix + name + "." + data.extension;
        const cache = cacheBusting(data);

        const url = `${process.env.REACT_APP_API_URL}/api/images/${data.id}/download?cb=${cache}`;
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          throw new Error(
            [
              `Filename: ${filename}`,
              `ErrorCode: ${response.status}`,
              `${
                response.statusText.length > 0
                  ? `ErrorMessage: ${response.statusText}`
                  : ""
              }`,
            ].join("\n")
          );
        }

        const a = document.createElement("a");

        document.body.appendChild(a);
        a.download = filename;
        a.href = url;
        a.click();
        a.remove();

        const requestData = JSON.stringify({
          history_id: historyId,
          image_id: data.id,
        });
        navigator.sendBeacon(
          `${process.env.REACT_APP_API_URL}/api/downloadHistory/setImg`,
          requestData
        );

        endTask();
      }
    },
    []
  );

  /**
   * ファイルのダウンロードを実施する
   */
  const downloadFile = useCallback(
    async (clientId: string | number, options?: DownloadOption) => {
      const selectedSearchItems =
        searchData?.filter((data) => selectedItem.includes(data.id)) ?? [];

      const buyers = ["BAA", "BAB", "BAC", "BAD", "BAE", "BAF", "BAG"];
      const isBuyers =
        selectedSearchItems.filter((data) =>
          buyers.includes(data.image_code.slice(0, 3))
        ).length > 0;

      if (
        isBuyers &&
        !confirm(
          "購入画像をダウンロードしようとしています。\n利用規約を守って使用します。"
        )
      ) {
        return;
      }

      let clientIds: number[];

      if (typeof clientId === "string") {
        clientIds = [parseInt(clientId)];
      } else {
        clientIds = [clientId];
      }

      const requestData = { client_ids: clientIds };
      try {
        const { data } = await axios.post<downloadHistoryResponse>(
          `${process.env.REACT_APP_API_URL}/api/downloadHistory/setDid`,
          requestData,
          config
        );

        if (folder && "showDirectoryPicker" in window) {
          await downloadFileOfChrome(
            selectedSearchItems,
            data.data,
            folder,
            options
          );
        } else {
          await downloadFileWithAnchor(selectedSearchItems, data.data, options);
        }
      } catch (e) {
        if (e instanceof DOMException) {
          setErrorMessage(
            [
              "選択したフォルダに問題が発生しました。再度フォルダを選択してください。",
              String(e),
            ].join("\n")
          );
          setFolder(null);
        } else {
          setErrorMessage(
            ["ダウンロードに失敗しました。", String(e)].join("\n")
          );
        }
        setIsError(true);
        errorTask();
      }
    },
    [searchData, selectedItem, folder]
  );

  const makePrefix = (option?: DownloadOption | null) => {
    if (option == null) return "";

    let noPrefix = "";
    if (option?.no) {
      const no = "000" + option?.no ?? "";
      if (option.no >= 1000) {
        noPrefix = no.slice(-4);
      } else {
        noPrefix = no.slice(-3);
      }
    }

    let branchPrefix = "";
    if (noPrefix.length > 0 && option?.branch) {
      branchPrefix = option?.branch ?? "";
    }

    let joinedStr = "";
    if (noPrefix.length > 0 || branchPrefix.length > 0) {
      joinedStr = ".";
    }

    return `${noPrefix}${branchPrefix}${joinedStr}`;
  };

  return {
    downloadFile: downloadFile,
    selectFolder: selectFolder,
    folder: folder,
  };
};
