import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import axios, { AxiosError } from "axios";

import CheckBoxText from "../../../molecules/CheckBoxText";
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Label from "../../../atoms/Label";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import LabelSelectBox from "../../../atoms/LabelSelectBox";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useSearchStateContext } from "../../../../context/SearchStateProvider";
import { useImageDetails } from "../../../../hooks/useImageDetails";
import NewOldTypeSelect from "../../../atoms/NewOldTypeSelect";
import { useError } from "../../../../hooks/useError";
import { ApiError } from "../../../../types/types";

interface Form {
  color: number;
  new_old_type: number;
  maker: string;
  supplier_name: string;
  locality: string;
  plan_end_date: string;
}

const defaultValue: Form = {
  color: 0,
  new_old_type: 0,
  maker: "",
  supplier_name: "",
  locality: "",
  plan_end_date: "",
};

interface Response {
  status: number;
  type: string;
  data_format: string;
  data: Form;
}

const BulkEditModal: FC = () => {
  const [checkColor, setIsCheckColor] = useState<boolean>(false);
  const [checkNewOldType, setIsCheckNewOldType] = useState<boolean>(false);
  const [checkMaker, setIsCheckMaker] = useState<boolean>(false);
  const [checkSupplierName, setIsCheckSupplierName] = useState<boolean>(false);
  const [checkLocality, setIsCheckLocality] = useState<boolean>(false);
  const [checkPlanEndDate, setIsCheckPlanEndDate] = useState<boolean>(false);

  const { modal, setModal } = useModalStateContext();
  const { control, getValues, reset } = useForm<Form>({
    defaultValues: defaultValue,
  });
  const { selectedItem } = useSelectedItemStateContext();
  const { searchData } = useSearchStateContext();
  const { multiUpdate } = useImageDetails();
  const { setError } = useError();

  const handleOnClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, bulkEditModal: false };
    });
  }, []);

  const handleOnSave = () => {
    if (searchData === null) return;

    const check =
      checkColor ||
      checkNewOldType ||
      checkMaker ||
      checkSupplierName ||
      checkLocality ||
      checkPlanEndDate;

    if (!check) {
      alert("更新する項目にチェックを入れてください");
      return;
    }

    if (confirm("保存しますか？")) {
      const data: {
        image_ids: number[];
        color: number | null;
        new_old_type: number | null;
        maker: string | null;
        supplier_name: string | null;
        locality: string | null;
        plan_end_date: string | null;
      } = {
        image_ids: selectedItem,
        color: null,
        new_old_type: null,
        maker: null,
        supplier_name: null,
        locality: null,
        plan_end_date: null,
      };

      if (checkColor) data.color = getValues("color");
      if (checkNewOldType) data.new_old_type = getValues("new_old_type");
      if (checkMaker) data.maker = getValues("maker");
      if (checkSupplierName) data.supplier_name = getValues("supplier_name");
      if (checkLocality) data.locality = getValues("locality");
      if (checkPlanEndDate) data.plan_end_date = getValues("plan_end_date");

      multiUpdate(data);

      alert("更新しました。");
      handleOnClose();
    }
  };

  useLayoutEffect(() => {
    const data = { image_ids: selectedItem };
    const config = { withCredentials: true };

    axios
      .post<Response>(
        `${process.env.REACT_APP_API_URL}/api/images/update/checkDuplicate`,
        data,
        config
      )
      .then(({ data }) => {
        reset(data.data);
      })
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        } else {
          setError(e);
        }
      });
  }, []);

  return (
    <Modal
      isOpen={modal.bulkEditModal}
      onClose={handleOnClose}
      autoFocus={false}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent maxHeight="460px" maxWidth="640px">
        <ModalHeader>
          <div>
            <Label className="font-bold text-18px">一括編集</Label>
            <Label className="font-normal text-12px my-3">
              選択した画像の設定をすることができます
            </Label>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="tag"
                label="ラベル"
                checked={checkColor}
                onChange={(e) => setIsCheckColor(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <LabelSelectBox
                  value={value}
                  onChange={(e) => {
                    if (!checkColor) setIsCheckColor(true);
                    onChange(e);
                  }}
                />
              )}
              name={"color"}
              control={control}
            />
          </div>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="newOrOld"
                label="新旧区分"
                checked={checkNewOldType}
                onChange={(e) => setIsCheckNewOldType(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <NewOldTypeSelect
                  selectSize="large"
                  width="200px"
                  spacing="0.4rem 0"
                  value={value}
                  onChange={(e) => {
                    if (!checkNewOldType) setIsCheckNewOldType(true);
                    onChange(e);
                  }}
                />
              )}
              name={"new_old_type"}
              control={control}
            />
          </div>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="maker"
                label="メーカー"
                checked={checkMaker}
                onChange={(e) => setIsCheckMaker(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="メーカー"
                  type="text"
                  value={value}
                  inputSize="large"
                  width="440px"
                  onChange={(e) => {
                    if (!checkMaker) setIsCheckMaker(true);
                    onChange(e);
                  }}
                  spacing="0"
                />
              )}
              name={"maker"}
              control={control}
            />
          </div>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="supplierName"
                label="支給元名"
                checked={checkSupplierName}
                onChange={(e) => setIsCheckSupplierName(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="支給元名"
                  type="text"
                  value={value}
                  inputSize="large"
                  width="440px"
                  onChange={(e) => {
                    if (!checkSupplierName) setIsCheckSupplierName(true);
                    onChange(e);
                  }}
                  spacing="0.4rem 0"
                />
              )}
              name="supplier_name"
              control={control}
            />
          </div>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="locality"
                label="産地"
                checked={checkLocality}
                onChange={(e) => setIsCheckLocality(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="産地"
                  type="text"
                  value={value}
                  inputSize="large"
                  width="440px"
                  onChange={(e) => {
                    if (!checkLocality) setIsCheckLocality(true);
                    onChange(e);
                  }}
                  spacing="0"
                />
              )}
              name="locality"
              control={control}
            />
          </div>
          <div className="w-full flex flex-row">
            <div className="w-140px inline-flex items-center">
              <CheckBoxText
                id="planEndDate"
                label="企画終了日"
                checked={checkPlanEndDate}
                onChange={(e) => setIsCheckPlanEndDate(e.target.checked)}
              />
            </div>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="企画終了日"
                  type="text"
                  value={value}
                  inputSize="large"
                  width="440px"
                  onChange={(e) => {
                    if (!checkPlanEndDate) setIsCheckPlanEndDate(true);
                    onChange(e);
                  }}
                  spacing="0.4rem 0"
                />
              )}
              name="plan_end_date"
              control={control}
            />
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-row items-end">
          <Button
            width="100px"
            primary={false}
            size="large"
            onClick={handleOnClose}
          >
            キャンセル
          </Button>
          <Button
            width="100px"
            primary={true}
            size="large"
            onClick={handleOnSave}
          >
            保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BulkEditModal;
