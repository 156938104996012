import React, { FC, memo, ChangeEvent, FormEvent } from "react";

import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import CheckBoxText from "../CheckBoxText";

interface Props {
  onChangeDate: (e: ChangeEvent<HTMLInputElement>) => void;
  date: string;
  onChangeChecked: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  handleOnSearch: () => void;
}

const HistorySearchBox: FC<Props> = ({
  onChangeDate,
  onChangeChecked,
  date,
  checked,
  handleOnSearch,
}) => {
  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleOnSearch();
  };

  return (
    <form className="flex flex-row items-center" onSubmit={handleOnSubmit}>
      <Label className="text-12px" spacing="0.25rem">
        登録日
      </Label>
      <Input
        type="text"
        placeholder="例）2021/08/04"
        onChange={onChangeDate}
        value={date}
        inputSize="small"
        width="200px"
      />
      <Button
        className="w-70px"
        primary={true}
        size="small"
        onClick={handleOnSearch}
      >
        表示
      </Button>
      <CheckBoxText
        id={"isGetDataOtherUserRegistered"}
        onChange={onChangeChecked}
        checked={checked}
        label={"他ユーザーの分も取り込む"}
      />
    </form>
  );
};

export default memo(HistorySearchBox);
