import axios, { AxiosError } from "axios";
import { Account, ApiError } from "../types/types";
import { useCallback } from "react";
import { useError } from "./useError";

interface ListResponse {
  status: number;
  type: string;
  data_format: string;
  data: Account[];
}

export const useScreenLock = (): {
  getList: () => Promise<Account[]>;
  putSession: (accountId: string, imageCode: string) => void;
  deleteSession: (accountId: string, imageCode: string) => void;
  deleteSessionBeforeUnload: (accountId: string, imageCode: string) => void;
} => {
  const { setError } = useError();
  const config = { withCredentials: true };

  const getList = useCallback(async (): Promise<Account[]> => {
    const { data } = await axios.get<ListResponse>(
      `${process.env.REACT_APP_API_URL}/api/session/list`,
      config
    );
    return data.data;
  }, []);

  const putSession = useCallback((accountId: string, imageCode: string) => {
    const data = { account_id: accountId, image_code: imageCode };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/session/putdata`,
        data,
        config
      )
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        } else {
          setError(e);
        }
      });
  }, []);

  const deleteSession = useCallback(
    (accountId: string | number, imageCode: string) => {
      const data = { account_id: accountId, image_code: imageCode };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/session/deldata`,
          data,
          config
        )
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            setError(e.response.data);
          } else {
            setError(e.message);
          }
        });
    },
    []
  );

  const deleteSessionBeforeUnload = useCallback(
    (accountId: string | number, imageCode: string) => {
      const data = JSON.stringify({
        account_id: accountId,
        image_code: imageCode,
      });
      navigator.sendBeacon(
        `${process.env.REACT_APP_API_URL}/api/session/deldata`,
        data
      );
    },
    []
  );

  return { getList, putSession, deleteSession, deleteSessionBeforeUnload };
};
