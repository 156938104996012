import React, { FC, memo } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Search from "../components/pages/Search";
import ImageDetail from "../components/pages/ImageDetail";
import ScaleImage from "../components/pages/ScaleImage";
import Register from "../components/pages/Register";
import { useAuth } from "../hooks/useAuth";
import Page404 from "../components/pages/Page404";

const AuthRouter: FC = () => {
  const { error } = useAuth();

  return error ? (
    <Redirect to={"/login"} />
  ) : (
    <>
      <Switch>
        <Route exact path="/clients" component={Search} />
        <Route exact path="/clients/:client_id/search" component={Search} />
        <Route
          exact
          path="/clients/:client_id/image/:image_id"
          component={ImageDetail}
        />
        <Route exact path="/images/:image_id" component={ScaleImage} />
        <Route exact path="/register" component={Register} />
        <Route component={Page404} />
      </Switch>
    </>
  );
};

export default memo(AuthRouter);
