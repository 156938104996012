import React, { FC, HTMLAttributes, memo } from "react";

const Table: FC<HTMLAttributes<HTMLDivElement>> = ({
  className = "",
  children,
  ...props
}) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default memo(Table);
