import React, { FC, useContext, useReducer, createContext } from "react";

interface DownLoadStateContextType {
  progressMap: Map<string, DownloadState>;
  addTasks: (tasks: number) => void;
  endTask: () => void;
  errorTask: () => void;
}

const DownloadStateContext = createContext({} as DownLoadStateContextType);

interface DownloadState {
  state: string;
  tasks: number;
  completed: number;
}

const downloadReducer = (
  state: Map<string, DownloadState>,
  action: { type: "ADD" | "END" | "ERROR"; addTasks: number }
): Map<string, DownloadState> => {
  if (action.type === "ADD") {
    const errorTaskKeys = Array.from(state)
      .filter((item) => {
        return item[1].state === "error";
      })
      .map((item) => {
        return item[0];
      });

    const inCompleteTaskKeys = Array.from(state)
      .filter((item) => {
        return item[1].state === "progress";
      })
      .map((item) => {
        return item[0];
      });

    if (inCompleteTaskKeys.length === 0) {
      const deletedErrorTasks = deleteErrorTasks(state, errorTaskKeys);

      const unique_id = getUniqueStr();
      return new Map([
        ...deletedErrorTasks,
        [
          unique_id,
          { state: "progress", tasks: action.addTasks, completed: 0 },
        ],
      ]);
    } else {
      const updateItem = state.get(inCompleteTaskKeys[0]);
      if (updateItem === undefined) {
        return state;
      }

      const deletedErrorTasks = deleteErrorTasks(state, errorTaskKeys);
      const updatedItem: DownloadState = {
        state: updateItem.state,
        tasks: updateItem.tasks + action.addTasks,
        completed: updateItem.completed,
      };
      return new Map([
        ...deletedErrorTasks,
        [inCompleteTaskKeys[0], updatedItem],
      ]);
    }
    return state;
  }

  if (action.type === "END") {
    const inCompleteTaskKeys = Array.from(state)
      .filter((item) => {
        return item[1].state === "progress";
      })
      .map((item) => {
        return item[0];
      });

    if (inCompleteTaskKeys.length === 0) {
      return state;
    }

    const updateItem = state.get(inCompleteTaskKeys[0]);
    if (updateItem === undefined) {
      return state;
    }

    const updatedItem: DownloadState = {
      state:
        updateItem.tasks === updateItem.completed + 1 ? "complete" : "progress",
      tasks: updateItem.tasks,
      completed: updateItem.completed + 1,
    };
    return new Map([...state, [inCompleteTaskKeys[0], updatedItem]]);
  }

  if (action.type === "ERROR") {
    const updatedTasks: Map<string, DownloadState> = new Map();
    Array.from(state)
      .filter((item) => {
        return item[1].state === "progress";
      })
      .map((item) => {
        const updatedState: DownloadState = {
          state: "error",
          tasks: item[1].tasks,
          completed: item[1].completed,
        };
        return updatedTasks.set(item[0], updatedState);
      });

    return new Map([...state, ...updatedTasks]);
  }
  return state;
};

const getUniqueStr = function (myStrong?: number): string {
  let strong = 1000;
  if (myStrong) strong = myStrong;
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  );
};

const deleteErrorTasks = function (
  tasks: Map<string, DownloadState>,
  keys: string[]
): Map<string, DownloadState> {
  if (keys.length !== 0) {
    const returnValue = tasks;
    keys.map((key) => {
      returnValue.delete(key);
    });
    return returnValue;
  }

  return tasks;
};

export const DownloadStateProvider: FC = ({ children }) => {
  const [progressMap, dispatchProgressMap] = useReducer(
    downloadReducer,
    new Map()
  );

  const addTasks = (tasks: number) => {
    dispatchProgressMap({ type: "ADD", addTasks: tasks });
  };
  const endTask = () => {
    dispatchProgressMap({ type: "END", addTasks: 0 });
  };
  const errorTask = () => {
    dispatchProgressMap({ type: "ERROR", addTasks: 0 });
  };

  return (
    <DownloadStateContext.Provider
      value={{
        progressMap,
        addTasks,
        endTask,
        errorTask,
      }}
    >
      {children}
    </DownloadStateContext.Provider>
  );
};

export const useDownloadStateContext = (): DownLoadStateContextType =>
  useContext(DownloadStateContext);
