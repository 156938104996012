import React, { memo, FC, ChangeEvent, useCallback } from "react";
import {
  Control,
  FieldValues,
  UseFormSetValue,
  Controller,
  UseFormWatch,
} from "react-hook-form";

import Label from "../../atoms/Label";
import CheckBoxText from "../CheckBoxText";

interface Props {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

const NewAndOldCategory: FC<Props> = ({ control, setValue, watch }) => {
  const onCheckCategory = useCallback(
    (category: string, e: ChangeEvent<HTMLInputElement>) => {
      setValue(category, e.target.checked ? "1" : "");
    },
    []
  );

  return (
    <div className="flex flex-col w-full mt-1">
      <Label>新旧区分</Label>
      <div className="flex flex-row justify-around">
        <Controller
          render={() => (
            <CheckBoxText
              id="new"
              label="Ｎｏｗ"
              spacing="0"
              checked={watch("details.new_old_type_now")}
              onChange={(e) => onCheckCategory("details.new_old_type_now", e)}
            />
          )}
          name={"details.new_old_type_now"}
          control={control}
        />
        <Controller
          render={() => (
            <CheckBoxText
              id="maybe"
              label="古いかも？"
              spacing="0"
              checked={watch("details.new_old_type_maybe")}
              onChange={(e) => onCheckCategory("details.new_old_type_maybe", e)}
            />
          )}
          name={"details.new_old_type_maybe"}
          control={control}
        />
        <Controller
          render={() => (
            <CheckBoxText
              id="old"
              label="古いです"
              spacing="0"
              checked={watch("details.new_old_type_old")}
              onChange={(e) => onCheckCategory("details.new_old_type_old", e)}
            />
          )}
          name={"details.new_old_type_old"}
          control={control}
        />
      </div>
    </div>
  );
};

export default memo(NewAndOldCategory);
