import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import { ChakraProvider } from "@chakra-ui/react";

import { ModalStateProvider } from "./context/ModalStateProvider";
import { AuthProvider } from "./context/AuthProvider";
import { StateProvider } from "./context/StateProvider";
import { SelectedItemStateProvider } from "./context/SelectedItemStateProvider";
import { ErrorStateProvider } from "./context/ErrorStateProvider";
import { SearchStateProvider } from "./context/SearchStateProvider";
import { KeyStateProvider } from "./context/KeyStateProvider";
import { DownloadStateProvider } from "./context/DownloadStateProvider";
import { SubWindowStateProvider } from "./context/SubWindowStateProvider";

const App: FC = () => {
  return (
    <KeyStateProvider>
      <ChakraProvider>
        <BrowserRouter>
          <AuthProvider>
            <StateProvider>
              <ErrorStateProvider>
                <SelectedItemStateProvider>
                  <DownloadStateProvider>
                    <SearchStateProvider>
                      <ModalStateProvider>
                        <SubWindowStateProvider>
                          <Router />
                        </SubWindowStateProvider>
                      </ModalStateProvider>
                    </SearchStateProvider>
                  </DownloadStateProvider>
                </SelectedItemStateProvider>
              </ErrorStateProvider>
            </StateProvider>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </KeyStateProvider>
  );
};

export default App;
