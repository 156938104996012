import React, { FC, useState, useCallback, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  Control,
  UseFormWatch,
} from "react-hook-form";

import Label from "../../atoms/Label";
import SelectTags from "../../molecules/SelectTags";
import { Genre } from "../../../types/types";

const initialClassName = "flex flex-col overflow-hidden";

interface Props {
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  data: Genre;
}

const SearchCategories: FC<Props> = ({
  control,
  register,
  setValue,
  watch,
  data,
}) => {
  const [active, setActive] = useState<boolean>(true);
  const [height, setHeight] = useState<string>("2000px");
  const [className, setClassName] = useState<string>(initialClassName);

  const { category_groups } = data;
  const toggleCategory = useCallback(() => {
    setActive(!active);
    setClassName(
      [initialClassName, "transition-max-height duration-100 ease-in-out"].join(
        " "
      )
    );
    setHeight(active ? "0px" : "2000px");
  }, [active]);

  return (
    <>
      <div
        className="bg-white flex flex-row justify-between border border-gray-border shadow-sm cursor-pointer p-1 my-1 ml-2"
        onClick={toggleCategory}
      >
        <Label spacing={0}>{data.category_name}</Label>
        <FontAwesomeIcon
          icon={active ? faSortUp : faSortDown}
          style={{ margin: "0 5px 3px 0" }}
        />
      </div>
      <div className={className} style={{ maxHeight: height }}>
        <div className="flex flex-col px-2 py-1">
          {category_groups.map((categoryGroup) => (
            <SelectTags
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
              data={categoryGroup}
              key={categoryGroup.group_id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(SearchCategories);
