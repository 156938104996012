import React, { FC, useState, useRef, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import Label from "../../atoms/Label";
import { RegisterTask } from "../../../types/types";
import { useDate } from "../../../hooks/useDate";

interface Props {
  registerTask: RegisterTask;
}

const ImageRegisterHistory: FC<Props> = ({ registerTask }) => {
  const [active, setActive] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");

  const toggleRef = useRef<HTMLDivElement>(null);
  const { formatDate } = useDate();

  const toggleAccordion = useCallback(() => {
    setActive(!active);
    setHeight(active ? "0px" : `${toggleRef?.current?.scrollHeight}px`);
  }, [active, setActive]);

  const getJobProgress = useMemo(() => {
    const taskImageList = registerTask.register_task_image_list;

    const completeTaskNum = taskImageList.filter(
      (taskImage) => taskImage.status !== "pending"
    ).length;
    const taskNum = taskImageList.length;

    switch (registerTask.status) {
      case "pending":
        return "登録処理待ち";
      case "running":
        return `登録処理中（${completeTaskNum}/${taskNum}）`;
      case "done":
        return `完了（${completeTaskNum}/${taskNum}）`;
    }
  }, [registerTask]);

  const getJobStatus = useMemo(() => {
    const taskImageList = registerTask.register_task_image_list;

    const completeTaskNum = taskImageList.filter(
      (taskImage) => taskImage.status !== "pending"
    ).length;
    const taskNum = taskImageList.length;
    const errorCount = taskImageList.filter(taskImage => taskImage && taskImage.status === "error").length;

    const strErrorSummary = () => {
      if (errorCount == 0) {
        return "";
      }
      return "（エラー" + errorCount + "件）";
    }

    const strImageCodeRange = () => {
      if (taskImageList[0].image_code.trim().length === 0) {
        return "";
      }
      if (taskImageList.length === 1) {
        return ` （${taskImageList[0].image_code}）`;
      }
      return ` （${taskImageList[0].image_code}〜${
        taskImageList[taskImageList.length - 1].image_code
      }）`;
    };

    switch (registerTask.status) {
      case "pending":
        return "登録処理待ち";
      case "running":
        return `登録処理中（${completeTaskNum}/${taskNum})`;
      case "done":
        return `完了${strErrorSummary()}${strImageCodeRange()}`;
    }
  }, [registerTask]);

  const taskImageList = registerTask.register_task_image_list;
  const hasErrorImage = taskImageList.filter(taskImage => taskImage && taskImage.status === "error").length > 0;

  const getStatus = (status: string) => {
    switch (status) {
      case "pending":
        return "登録処理待ち";
      case "running":
        return "登録処理中";
      case "success":
        return "完了";
      case "error":
        return "エラー";
      default:
        return "";
    }
  };

  const getRegistrationDate = () => {
    if (registerTask.start_date == null) {
      return "";
    }

    return `登録日時：${formatDate(
      registerTask.start_date,
      "YYYY/MM/DD HH:mm:ss"
    )} ~ ${formatDate(registerTask.finish_date, "YYYY/MM/DD HH:mm:ss")} (${
      registerTask.create_account_id ?? ""
    })`;
  };

  return (
    <div className="flex flex-col w-full bg-white rounded-md shadow-md p-4 my-2 text-8px">
      <div
        className="flex flex-row cursor-pointer justify-between"
        onClick={toggleAccordion}
      >
        <Label
          className="font-bold truncate"
          spacing={0}
          style={{ width: "250px" }}
        >
          {registerTask.job_id}
        </Label>
        <Label className={hasErrorImage ? "truncate mx-4 text-red-500 font-bold" : "truncate mx-4" } spacing={0} style={{ width: "250px" }}>
          {getJobStatus}
        </Label>
        <Label className="truncate flex-1" spacing={0}>
          {getRegistrationDate()}
        </Label>
        <FontAwesomeIcon
          className="inline-flex items-center cursor-pointer"
          icon={active ? faSortUp : faSortDown}
          onClick={toggleAccordion}
        />
      </div>
      <div
        className="overflow-hidden transition-max-height duration-200 ease-in-out text-8px sm:text-9px md:text-12px"
        style={{ height: `${height}` }}
        ref={toggleRef}
      >
        <div>
          <div className="py-4">
            <hr className="h-1px" />
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/3">
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  JOB ID
                </Label>
                <Label className="truncate" spacing={0}>
                  {registerTask.job_id}
                </Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  登録ZIPファイル
                </Label>
                <Label className="truncate" spacing={0}>
                  {registerTask.zip_filename}
                </Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  開始日時
                </Label>
                <Label className="truncate" spacing={0}>
                  {formatDate(registerTask.start_date, "YYYY/MM/DD HH:mm:ss")}
                </Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  終了日時
                </Label>
                <Label className="truncate" spacing={0}>
                  {formatDate(registerTask.finish_date, "YYYY/MM/DD HH:mm:ss")}
                </Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  ステータス
                </Label>
                <Label className="truncate" spacing={0}>
                  {getJobProgress}
                </Label>
              </div>
              <div className="flex flex-row justify-between">
                <Label className="font-bold" spacing={0}>
                  ユーザー名
                </Label>
                <Label spacing={0}>{registerTask.create_account_id}</Label>
              </div>
            </div>
            <div className="flex flex-col flex-1 ml-4">
              <Label className="font-bold" spacing={0}>
                画像登録状況
              </Label>
              <table>
                <thead>
                  <tr className="border border-gray-line">
                    <td className="w-130px border-r p-1 ">画像ID</td>
                    <td className="w-130px border-r p-1">
                      登録時画像ファイル名
                    </td>
                    <td className="w-130px border-r p-1">
                      オリジナル画像ファイル名
                    </td>
                    <td className="w-130px p-1">画像処理ステータス</td>
                  </tr>
                </thead>
                <tbody>
                  {registerTask.register_task_image_list.map((taskImage) => (
                    <tr key={taskImage.id} className={taskImage.status === 'error' ? 'font-bold text-red-500' : ''}>
                      <td className="truncate">{taskImage.image_code}</td>
                      <td className="truncate">{taskImage.filename}</td>
                      <td className="truncate">
                        {taskImage.original_filename}
                      </td>
                      <td className="truncate">
                        {getStatus(taskImage.status)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageRegisterHistory;
