import React, { FC, memo, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faSortDown } from "@fortawesome/free-solid-svg-icons";

import Label from "../../atoms/Label";
import { useStateContext } from "../../../context/StateProvider";

const SelectImageSize: FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [sizeProperty, setSizeProperty] = useState<string>("");
  const { size, setSize } = useStateContext();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpenMenu) {
      if (menuRef.current !== undefined) menuRef.current?.focus();
    }
  }, [isOpenMenu]);

  useEffect(() => {
    switch (localStorage.getItem("setting/size")) {
      case "small":
        setSize("small");
        break;
      case "middle":
        setSize("middle");
        break;
      case "large":
        setSize("large");
        break;
    }
  }, []);

  useEffect(() => {
    switch (size) {
      case "small":
        setSizeProperty("小");
        localStorage.setItem("setting/size", "small");
        break;
      case "middle":
        setSizeProperty("中");
        localStorage.setItem("setting/size", "middle");
        break;
      case "large":
        setSizeProperty("大");
        localStorage.setItem("setting/size", "large");
        break;
    }
  }, [size]);

  return (
    <div
      className="relative w-100px mx-1 z-10 whitespace-nowrap"
      onBlur={() => setIsOpenMenu(false)}
      onClick={() => setIsOpenMenu(!isOpenMenu)}
      tabIndex={0}
      ref={menuRef}
    >
      <div className="flex flex-row items-center justify-around cursor-pointer">
        <FontAwesomeIcon
          className="text-white"
          icon={faImage}
          size="lg"
          style={{ margin: "0 3px 0 0" }}
        />
        <Label className="text-white text-12px">{`サイズ：${sizeProperty}`}</Label>
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faSortDown}
          color="white"
          style={{ margin: "0 3px 3px 0" }}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        />
      </div>
      <ul
        className="absolute bg-white border border-black w-full cursor-pointer"
        hidden={!isOpenMenu}
      >
        <li
          className={[
            size === "small" ? "bg-green-teal bg-opacity-30" : "",
            "hover:bg-green-teal hover:bg-opacity-50 pl-1",
          ].join(" ")}
        >
          <div
            className="flex flex-row items-center"
            onClick={() => setSize("small")}
          >
            <FontAwesomeIcon
              icon={faImage}
              size="lg"
              style={{ margin: "0 3px 0 0" }}
            />
            <Label className="text-12px">サイズ：小</Label>
          </div>
        </li>
        <li
          className={[
            size === "middle" ? "bg-green-teal bg-opacity-30" : "",
            "hover:bg-green-teal hover:bg-opacity-50 pl-1",
          ].join(" ")}
        >
          <div
            className="flex flex-row items-center"
            onClick={() => setSize("middle")}
          >
            <FontAwesomeIcon
              icon={faImage}
              size="lg"
              style={{ margin: "0 3px 0 0" }}
            />
            <Label className="text-12px">サイズ：中</Label>
          </div>
        </li>
        <li
          className={[
            size === "large" ? "bg-green-teal bg-opacity-30" : "",
            "hover:bg-green-teal hover:bg-opacity-50 pl-1",
          ].join(" ")}
        >
          <div
            className="flex flex-row items-center"
            onClick={() => setSize("large")}
          >
            <FontAwesomeIcon
              icon={faImage}
              size="lg"
              style={{ margin: "0 3px 0 0" }}
            />
            <Label className="text-12px">サイズ：大</Label>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default memo(SelectImageSize);
