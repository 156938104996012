import React, { FC } from "react";

import Header from "../organisms/Layout/Header";
import Image from "../atoms/Image";
import Label from "../atoms/Label";
import { useTitle } from "../../hooks/useTitle";

import image from "../../images/undraw_blank_canvas_3rbb.svg";

const Page404: FC = () => {
  useTitle().setTitle("リソースが見つかりませんでした。(404:Not Found)");

  return (
    <div className="flex flex-col w-screen h-screen bg-gray-background">
      <Header />
      <div className="flex flex-col flex-grow items-center justify-center">
        <Image src={image} width="290px" height="240px" />
        <Label className="font-bold">リソースが見つかりませんでした。</Label>
        <Label>
          アクセスされたリソースに紐づくリソースが見つかりませんでした。
        </Label>
        <Label>
          URLを確認するかタブ・ウィンドウを閉じてログインからやり直してください。
        </Label>
      </div>
    </div>
  );
};

export default Page404;
