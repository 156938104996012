import { useCallback } from "react";
import axios, { AxiosError } from "axios";
import { ApiError, SharePattern } from "../types/types";
import { useError } from "./useError";

interface SharePatternResponse {
  data: SharePattern[];
  data_format: string;
  status: number;
  type: string;
}

interface SharePatternResponse2 {
  data: SharePattern;
  data_format: string;
  status: number;
  type: string;
}

interface CheckResponse {
  data: string;
  data_format: string;
  status: number;
  type: string;
}

export const useSharePattern = (): {
  getSharePatternById: (id: number) => Promise<SharePattern>;
  getSharePatternByClientId: (
    clientId: number | string
  ) => Promise<SharePattern[]>;
  useCheck: (clientId: number | string) => Promise<string>;
  updateSharePattern: (imageIds: number[], sharePatternId: number) => void;
} => {
  const { setError } = useError();
  const config = { withCredentials: true };

  const getSharePatternById = useCallback(
    async (id: number): Promise<SharePattern> => {
      const { data } = await axios.get<SharePatternResponse2>(
        `${process.env.REACT_APP_API_URL}/api/sharePattern/get?id=${id}`,
        config
      );
      return data.data;
    },
    []
  );

  const getSharePatternByClientId = useCallback(
    async (clientId: number | string): Promise<SharePattern[]> => {
      const { data } = await axios.get<SharePatternResponse>(
        `${process.env.REACT_APP_API_URL}/api/sharePattern/listPattern?clientId=${clientId}`,
        config
      );
      return data.data;
    },
    []
  );

  const updateSharePattern = useCallback(
    (imageIds: number[], sharePatternId: number) => {
      const data = { image_ids: imageIds, share_pattern_id: sharePatternId };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/detail/update_sp`,
          data,
          config
        )
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            setError(e.response.data);
          } else {
            setError(e);
          }
        });
    },
    []
  );

  const useCheck = useCallback(
    async (checkId: number | string): Promise<string> => {
      const { data } = await axios.get<CheckResponse>(
        `${process.env.REACT_APP_API_URL}/api/sharePattern/usechk?chkid=${checkId}`,
        config
      );
      return data.data;
    },
    []
  );

  return {
    getSharePatternByClientId,
    updateSharePattern,
    getSharePatternById,
    useCheck,
  };
};
