import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import Label from "../../atoms/Label";
import SearchCategories from "../SearchCategories";
import { Genre } from "../../../types/types";
import { useGenres } from "../../../hooks/useGenres";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../../context/StateProvider";

interface Props {
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

const initialClassName = "flex flex-col overflow-hidden";

const SearchTags: FC<Props> = ({ control, register, setValue, watch }) => {
  const [active, setActive] = useState<boolean>(true);
  const [height, setHeight] = useState<string>("9999px");
  const [className, setClassName] = useState<string>(initialClassName);
  const [genres, setGenres] = useState<Genre[]>();
  const { openSearch } = useStateContext();
  const { client_id } = useParams<{ client_id: string }>();
  const { getGenres } = useGenres();

  const toggleTags = useCallback(() => {
    setActive(!active);
    setClassName(
      [initialClassName, "transition-max-height duration-100 ease-in-out"].join(
        " "
      )
    );
    setHeight(active ? "0px" : "9999px");
  }, [active]);

  useEffect(() => {
    if (client_id === undefined || !openSearch) return;
    getGenres().then((data) => {
      setGenres(data);
    });
  }, [client_id, openSearch]);

  return (
    <div className="flex flex-col bg-gray-background bg-opacity-70 text-12px">
      <div
        className="bg-white flex flex-row items-center justify-between border border-gray-border shadow-md p-1 cursor-pointer"
        onClick={toggleTags}
      >
        <Label>タグ</Label>
        <FontAwesomeIcon
          icon={active ? faSortUp : faSortDown}
          style={{ margin: "0 5px 3px 0" }}
        />
      </div>
      <div className={className} style={{ maxHeight: height }}>
        {genres?.map((category, index) => (
          <SearchCategories
            register={register}
            setValue={setValue}
            control={control}
            watch={watch}
            data={category}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(SearchTags);
