import { useCallback } from "react";
import axios from "axios";

import { Genre } from "../types/types";

export interface ApiResponse {
  status: number;
  type: string;
  data_format: string;
  data: Genre[];
}

export const useGenres = (): { getGenres: () => Promise<Genre[]> } => {
  const getGenres = useCallback(async () => {
    const config = { withCredentials: true };
    const { data } = await axios.get<ApiResponse>(
      `${process.env.REACT_APP_API_URL}/api/tags/genres`,
      config
    );
    return data.data;
  }, []);

  return { getGenres };
};
