import React, { CSSProperties, memo } from "react";

import ImageCard from "../../ImageCard";
import { ImageBoxImageSimple } from "../../../../types/types";

interface Props {
  data: {
    searchData: ImageBoxImageSimple[];
    columnCount: number;
    itemCount: number;
    gapSize: number;
    cardSize: {
      height: number;
      width: number;
    };
    imageHeight: number;
    selectedItem: number[];
    focusItem: number | null;
  };
  index: number;
  style: CSSProperties;
}

const ListItem = ({ data, index, style }: Props): JSX.Element => {
  const {
    searchData,
    columnCount,
    gapSize,
    itemCount,
    cardSize,
    imageHeight,
    selectedItem,
    focusItem,
  } = data;

  const startIndex = index * columnCount;
  const stopIndex = Math.min(itemCount - 1, startIndex + columnCount - 1);

  const cards: JSX.Element[] = [];

  for (let i = startIndex; i <= stopIndex; i++) {
    if (searchData === null) return <div />;
    else {
      cards.push(
        <ImageCard
          index={i}
          imageId={searchData[i].id}
          key={searchData[i].id}
          data={searchData[i]}
          cardSize={cardSize}
          imageHeight={imageHeight}
          spacing={`${gapSize / 2}px`}
          isSelect={selectedItem.includes(searchData[i].id)}
          isFocus={focusItem === i}
        />
      );
    }
  }

  return (
    <div className="flex flex-row overflow-hidden" style={style}>
      {cards}
    </div>
  );
};

export default memo(ListItem);
