import React, { FC, memo, useState } from "react";
import axios, { AxiosError } from "axios";

import Button from "../../atoms/Button";
import Label from "../../atoms/Label";
import SelectUploadAction from "../../molecules/SelectUploadAction";
import FileInput from "../../atoms/FileInputZone";
import { useDate } from "../../../hooks/useDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "@chakra-ui/react";
import { ApiError } from "../../../types/types";
import { useError } from "../../../hooks/useError";

interface Props {
  onHistorySearch: (date: string) => void;
}

const ImageRegisterForm: FC<Props> = (props) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [action, setAction] = useState<"register" | "update">("register");
  const [isRegenerate, setIsRegenerate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getDate } = useDate();

  const { setError } = useError();

  const onChangeFile = (value: File[]) => {
    setFiles(value);
  };

  const handleUpload = async () => {
    if (files === null || files.length === 0) {
      alert("アップロードするファイルを選択してください");
      return;
    }

    const file = files[0];
    if (!files[0].name.includes(".zip")) {
      alert("zipファイルを選択してください。");
      return;
    }
    const headers = { "content-type": "multipart/form-data" };

    const data = new FormData();
    data.append("file", file);

    setIsLoading(true);
    await new Promise((s) => setTimeout(s, 1000));

    let url: string;
    if (action === "register") {
      url = `${process.env.REACT_APP_API_URL}/api/register/firstUpload`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/api/register/upload`;
      data.append("regenerate_thumbnail", String(isRegenerate));
    }

    await axios
      .post(url, data, {
        withCredentials: true,
        headers: headers,
      })
      .then(() => {
        setFiles(null);
        setTimeout(() => {
          const today = getDate("YYYY/MM/DD");
          props.onHistorySearch(today);
        }, 300);
      })
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          if (e.response.status === 400) {
            alert(e.response.data.message);
          } else {
            setError(e.response.data);
          }
        } else {
          setError(e);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeAction = (action: "register" | "update") => {
    setAction(action);
  };

  const getFileSize = () => {
    if (files === null || files.length === 0) {
      return 0;
    }
    return files[0].size / Math.pow(1024, 2);
  };

  const onChangeRegenerateFlag = (flag: boolean) => {
    setIsRegenerate(flag);
  };

  return (
    <div className="flex flex-col w-4/5">
      <Label className="font-bold text-14px mb-3">画像登録</Label>
      <div className="w-full bg-white rounded-md shadow-md p-4">
        <Label className="text-14px">
          登録ZIPファイル（画像データ + 登録指示ファイル）
        </Label>
        <div className="flex flex-row">
          <FileInput
            onChangeFile={onChangeFile}
            className={
              "w-full h-100px flex flex-col items-center justify-center bg-green-upload"
            }
          >
            <FontAwesomeIcon className="mx-2" icon={faFileImport} size={"2x"} />
            {files !== null && files.length !== 0 ? (
              <div className="flex flex-col ml-2 text-10px items-center">
                <p>{files[0].name}</p>
                <p>{Math.round(getFileSize() * 100) / 100 + "MB"}</p>
              </div>
            ) : (
              <p className={"inline-flex text-10px ml-2"}>
                ファイルを選択してください
              </p>
            )}
          </FileInput>
        </div>
        <div className={"flex flex-col items-end"}>
          <SelectUploadAction
            action={action}
            onChangeAction={onChangeAction}
            isRegenerate={isRegenerate}
            onChangeRegenerateFlag={onChangeRegenerateFlag}
          />
          <Button
            className={`w-125px ml-auto ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            primary={true}
            size="small"
            onClick={handleUpload}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "登録"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ImageRegisterForm);
