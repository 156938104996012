import React, { FC, Fragment, InputHTMLAttributes, memo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { LabelColor } from "../../../util/LabelColor";

interface Props extends InputHTMLAttributes<HTMLElement> {
  value: number;
  onChange?: (e: never) => void;
}

const LabelSelectBox: FC<Props> = ({ onChange = () => void 0, ...props }) => {
  const pickColors = () => {
    return [
      LabelColor.None,
      LabelColor.Red,
      LabelColor.Orange,
      LabelColor.Yellow,
      LabelColor.Green,
      LabelColor.Blue,
      LabelColor.Magenta,
      LabelColor.Gray,
      LabelColor.White,
    ];
  };

  const pickColorLabel = (v: LabelColor) => {
    switch (v) {
      case LabelColor.Red:
        return "bg-label-red text-label-red";
      case LabelColor.Orange:
        return "bg-label-orange text-label-orange";
      case LabelColor.Yellow:
        return "bg-label-yellow text-label-yellow";
      case LabelColor.Green:
        return "bg-label-green text-label-green";
      case LabelColor.Blue:
        return "bg-label-blue text-label-blue";
      case LabelColor.Magenta:
        return "bg-label-magenta text-label-magenta";
      case LabelColor.Gray:
        return "bg-label-gray text-label-gray";
      case LabelColor.White:
        return "bg-label-white text-label-white";
      default:
        return "text-black text-12px";
    }
  };

  return (
    <Listbox value={props.value} onChange={onChange} disabled={props.disabled}>
      <div className="mt-1 relative">
        <Listbox.Button
          className={
            "relative h-20px text-12px w-full bg-white border border-gray-300 rounded-md shadow-sm pl-2 pr-10 " +
            pickColorLabel(props.value)
          }
        >
          {"なし"}
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {pickColors().map((color: LabelColor) => (
              <Listbox.Option
                key={color}
                className={pickColorLabel(color)}
                value={color}
              >
                <>
                  <div className="flex items-center pl-2 pr-10">
                    <span className={"font-semibold"}>
                      {color === 0 ? "なし" : color}
                    </span>
                  </div>
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

LabelSelectBox.displayName = "LabelSelectBox";

export default memo(LabelSelectBox);
