import React, { FC, memo, useState, useEffect } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Label from "../../../atoms/Label";
import Image from "../../../atoms/Image";
import { useLogin } from "../../../../hooks/useLogin";

import logo from "../../../../images/logo.png";
import { Spinner } from "@chakra-ui/react";

const LoginForm: FC = () => {
  const { register, handleSubmit, getValues, setFocus } = useForm<FieldValues>();
  const { login, error, loading } = useLogin();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmitLogin: SubmitHandler<FieldValues> = () => {
    if (loading) return;
    onSubmitLoginAnyway();
  };

  const onEnterIDInputField = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      if (loading) return;

      e.preventDefault();
      onSubmitLoginAnyway();
    }
  };

  const onSubmitLoginAnyway = () => {
    const mail = getValues("id");
    if (mail == '') return false;

    const is_sso = isSSOAccountDomain(mail);

    if (showPassword && !is_sso) {
      submitNativeAccountLogin();
    } else {
      submitSSOLoginWhenNeeded();
    }
  }

  const isSSOAccountDomain = (mail: string) => {
    try {
      const domains = `${process.env.REACT_APP_SSO_DOMAINS}`.split(',');
      const mail_strs = mail.split('@');
      return domains.includes(mail_strs[1]);
    } catch (e) {
      return false;
    }
  }

  const submitSSOLoginWhenNeeded = () => {
    const mail = getValues("id");
    if (mail == '') return false;

    const is_sso = isSSOAccountDomain(mail);
    if (is_sso) {
      location.href = `${process.env.REACT_APP_API_URL}/auth/sso`;
    } else {
      setShowPassword(true);
    }
  }

  const submitNativeAccountLogin = () => {
    const id = getValues("id");
    const password = getValues("password");
    login(id, password);
  }

  useEffect(() => {
    setFocus("password");
  }, [showPassword]);

  return (
    <div className="bg-white p-4 shadow-md border-2 border-gray-300 rounded-md w-380px h-330px md:w-480px md:h-330px">
      <div className="flex flex-col justify-around items-center w-full h-full">
        <Image src={logo} width="240px" height="50px" />
        <div className="flex flex-col items-center" style={{ height: "160px" }}>
          <form
            className="flex flex-col justify-center"
            onSubmit={handleSubmit(onSubmitLogin)}
          >
            <div hidden={false}>
              <Input
                className="w-250px md:w-350px"
                type="text"
                inputSize="large"
                placeholder="ID"
                onKeyPress={onEnterIDInputField}
                {...register("id")}
              />
            </div>
            <div style={{display: showPassword ? '' : 'none'}}>
              <Input
                  className="w-250px md:w-350px"
                  type="password"
                  inputSize="large"
                  placeholder="パスワード"
                  {...register("password")}
              />
            </div>
            <Label className="text-red-500 text-12px md:text-14px">
              {error ? ('IDもしくはパスワードが間違っています。') : '　'}
            </Label>
            <Button
              type="submit"
              className={[
                "w-250px md:w-350px",
                loading ? "cursor-not-allowed" : "",
              ].join(" ")}
              primary={true}
              size="large"
              spacing="0.5rem 0.25rem"
              disabled={loading}
            >
              {loading ? <Spinner /> : "ログイン"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(LoginForm);
