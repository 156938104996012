import axios, { AxiosError } from "axios";

import { useError } from "./useError";
import { ApiError } from "../types/types";

export const useDeleteImage = (): {
  post: (image_id: number | string) => void;
} => {
  const { setError } = useError();
  const config = { withCredentials: true };

  const post = (image_id: number | string) => {
    const data = { image_id: image_id };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/deleteImage/delete`,
        data,
        config
      )
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        } else {
          setError(e);
        }
      });
  };

  return { post };
};
