import React, { FC, memo, SelectHTMLAttributes, useMemo } from "react";
import { Property } from "csstype";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<number | string>;
  selectSize?: "small" | "large";
  width?: Property.Width;
  spacing?: Property.Margin;
}

const SelectBox: FC<Props> = ({
  options,
  width = "280px",
  spacing = undefined,
  selectSize,
  className = undefined,
  ...props
}) => {
  const defaultClassName =
    "font-sans border border-gray-line rounded-md py-0 text-12px focus:outline-none outline-none";
  const sizeProperty = useMemo(() => {
    switch (selectSize) {
      case "small":
        return "h-20px px-1";
      case "large":
        return "h-32px px-2";
      default:
        return "";
    }
  }, [selectSize]);

  return (
    <select
      className={[defaultClassName, sizeProperty, className].join(" ")}
      style={{ width: width, margin: spacing }}
      {...props}
    >
      {options.map((value, index) => {
        return (
          <option value={value} key={index}>
            {value}
          </option>
        );
      })}
    </select>
  );
};

export default memo(SelectBox);
