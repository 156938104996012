import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

interface KeyState {
  keyPress: Map<string, boolean>;
}

interface KeyStateContextType {
  keyPress: KeyState;
  setKeyPress: Dispatch<SetStateAction<KeyState>>;
}

const InitialKeyState: KeyState = {
  keyPress: new Map<string, boolean>(),
};

const KeyStateContext = createContext({} as KeyStateContextType);

export const KeyStateProvider: FC = ({ children }) => {
  const [keyPress, setKeyPress] = useState<KeyState>(InitialKeyState);

  return (
    <KeyStateContext.Provider value={{ keyPress, setKeyPress }}>
      {children}
    </KeyStateContext.Provider>
  );
};

export const useKeyStateContext = (): KeyStateContextType =>
  useContext(KeyStateContext);
