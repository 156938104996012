import React, {
  ButtonHTMLAttributes,
  forwardRef,
  memo,
  useMemo,
  useRef,
} from "react";
import { Property } from "csstype";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  primary?: boolean;
  size?: "small" | "medium" | "large";
  width?: Property.Width;
  spacing?: Property.Margin;
  rounded?: "none" | "sm" | "md" | "lg" | "full";
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className = "",
      type = "button",
      primary,
      size,
      width = undefined,
      spacing = "0.25rem",
      onClick = () => void 0,
      rounded = "md",
      ...props
    },
    ref
  ) => {
    const defaultClassName = "font-sans";

    const localRef = useRef(null);
    const buttonRef = ref || localRef;

    const mode = useMemo(() => {
      switch (primary) {
        case true:
          return "bg-green-teal bg-opacity-100 text-white hover:bg-opacity-70";
        case false:
          return "bg-white border hover:bg-gray-background";
        default:
          return "";
      }
    }, [primary]);

    const sizeProperty = useMemo(() => {
      switch (size) {
        case "small":
          return "h-22px text-12px";
        case "medium":
          return "h-28px text-12px";
        case "large":
          return "h-34px text-13px";
        default:
          return "";
      }
    }, [size]);

    const round = useMemo(() => {
      switch (rounded) {
        case "sm":
          return "rounded";
        case "md":
          return "rounded-md";
        case "lg":
          return "rounded-lg";
        case "full":
          return "rounded-lg";
        default:
          return "";
      }
    }, [rounded]);

    return (
      <button
        type={type}
        className={[
          defaultClassName,
          mode,
          sizeProperty,
          round,
          className,
        ].join(" ")}
        onClick={onClick}
        style={{ width: width, margin: spacing }}
        ref={buttonRef}
        {...props}
      >
        {props.children}
      </button>
    );
  }
);

Button.displayName = "Button";
export default memo(Button);
