import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { User } from "../types/types";

interface AuthContextType {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  isLogin: boolean;
  setIsLogin: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext({} as AuthContextType);

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isLogin, setIsLogin] = useState<boolean>(false);

  return (
    <AuthContext.Provider value={{ user, setUser, isLogin, setIsLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => useContext(AuthContext);
