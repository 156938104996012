import React, { FC, useMemo, memo } from "react";
import { FixedSizeList as List } from "react-window";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";

import ListItem from "../ListItem";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useSearchStateContext } from "../../../../context/SearchStateProvider";
import { useModal } from "../../../../hooks/useModal";
import { useDetailWindow } from "../../../../hooks/useDetailWindow";
import { useSubWindow } from "../../../../hooks/useSubWindow";
import { ImageBoxImageSimple } from "../../../../types/types";

interface Props {
  height: number;
  width: number;
  gapSize: number;
  cardSize: {
    height: number;
    width: number;
  };
  imageHeight: number;
  searchData: ImageBoxImageSimple[];
  itemCount: number;
}

const ListWrapper: FC<Props> = ({
  width,
  height,
  gapSize,
  cardSize,
  imageHeight,
  searchData,
  itemCount,
}) => {
  const { setSelectedItem, selectedItem, setFocusItem, focusItem } =
    useSelectedItemStateContext();
  const { setSearchData } = useSearchStateContext();
  const { isOpenModal } = useModal();
  const { showDetailWindows } = useDetailWindow();
  const { showImageWindows } = useSubWindow();
  const { client_id } = useParams<{ client_id: string }>();

  const columnCount = Math.floor(
    (width - gapSize) / (cardSize.width + gapSize)
  );
  const rowCount = Math.ceil(itemCount / columnCount);

  const handleKeyPressRight = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();

    if (focusItem === null || focusItem >= searchData.length - 1) return;
    setFocusItem(focusItem + 1);
    setSelectedItem([searchData[focusItem + 1].id]);
  };

  const handleKeyPressLeft = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();

    if (focusItem === null || focusItem === 0) return;
    setFocusItem(focusItem - 1);
    setSelectedItem([searchData[focusItem - 1].id]);
  };

  const handleKeyPressUp = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e.preventDefault();
    if (focusItem === null || focusItem === 0) return;

    if (focusItem - columnCount < 0) {
      setFocusItem(0);
      setSelectedItem([searchData[0].id]);
    } else {
      setFocusItem(focusItem - columnCount);
      setSelectedItem([searchData[focusItem - columnCount].id]);
    }
  };

  const handleKeyPressDown = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e.preventDefault();
    if (focusItem === null || focusItem === searchData.length - 1) return;

    if (focusItem + columnCount > searchData.length - 1) {
      setFocusItem(searchData.length - 1);
      setSelectedItem([searchData[searchData.length - 1].id]);
    } else {
      setFocusItem(focusItem + columnCount);
      setSelectedItem([searchData[focusItem + columnCount].id]);
    }
  };

  const handleKeyPressHome = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();
    if (searchData === null) return;
    setFocusItem(0);
    setSelectedItem([searchData[0].id]);
  };

  const handleKeyPressEnd = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();
    if (searchData === null) return;
    setFocusItem(searchData.length - 1);
    setSelectedItem([searchData[searchData.length - 1].id]);
  };

  const handleAllSelect = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e.preventDefault();
    const id: number[] = [];
    searchData.map((data) => id.push(data.id));
    setSelectedItem(id);
    setFocusItem(0);
  };

  const handleDeleteData = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();

    if (selectedItem.length === 0) return;
    const filteredData = searchData.filter(
      (data) => !selectedItem.includes(data.id)
    );
    setSearchData(filteredData);

    setSelectedItem([]);
  };

  const handleRangeSelectRight = (e: KeyboardEvent) => {
    if (isOpenModal() || searchData === null) return;
    e?.preventDefault();
    if (focusItem === null || focusItem === searchData.length - 1) return;

    const ids = selectedItem.concat();
    const index = ids.indexOf(searchData[focusItem + 1].id);
    if (index !== -1) {
      ids.splice(ids.indexOf(searchData[focusItem].id));
    } else {
      ids.push(searchData[focusItem + 1].id);
    }
    setSelectedItem(ids);
    setFocusItem(focusItem + 1);
  };

  const handleRangeSelectLeft = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();
    if (focusItem === null || focusItem === 0) return;

    const ids = selectedItem.concat();
    const index = ids.indexOf(searchData[focusItem - 1].id);
    if (index !== -1) {
      ids.splice(ids.indexOf(searchData[focusItem].id));
    } else {
      ids.push(searchData[focusItem - 1].id);
    }
    setSelectedItem(ids);
    setFocusItem(focusItem - 1);
  };

  const handleRangeSelectUp = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();
    if (focusItem === null || focusItem === 0) return;

    let ids = selectedItem.concat();
    const stopIndex = focusItem < columnCount ? 0 : focusItem - columnCount;

    /***
     * 1. 前の要素を取得
     * 2. 前の要素が選択済み要素である場合、選択範囲の巻き戻しであるため選択
     * 3. 前の要素が選択されていない場合選択済みの要素として追加する
     */
    for (let i = focusItem; stopIndex < i; i--) {
      const index = ids.indexOf(searchData[i - 1].id);
      if (index !== -1) {
        ids.splice(ids.indexOf(searchData[i].id));
      } else {
        ids = [...ids, searchData[i - 1].id];
      }
    }
    setSelectedItem(ids);
    setFocusItem(stopIndex);
  };

  const handleRangeSelectDown = (e: KeyboardEvent) => {
    if (isOpenModal()) return;
    e?.preventDefault();
    if (focusItem === null || focusItem === searchData.length - 1) return;

    let ids = selectedItem.concat();
    const stopIndex =
      focusItem + columnCount > searchData.length - 1
        ? searchData.length - 1
        : focusItem + columnCount;

    /***
     * 1. 後の要素を取得
     * 2. 後の要素が選択済み要素である場合、選択範囲の巻き戻しであるため選択
     * 3. 後の要素が選択されていない場合選択済みの要素として追加する
     */
    for (let i = focusItem; i < stopIndex; i++) {
      const index = ids.indexOf(searchData[i + 1].id);
      if (index !== -1) {
        ids.splice(ids.indexOf(searchData[i].id));
      } else {
        ids = [...ids, searchData[i + 1].id];
      }
    }
    setSelectedItem(ids);
    setFocusItem(stopIndex);
  };

  const showScaleImagePage = (e: KeyboardEvent) => {
    if (isOpenModal() && selectedItem.length === 0) return;
    e.preventDefault();

    selectedItem.map((id) => {
      showImageWindows(
        `${process.env.REACT_APP_URL}/images/${id}?cid=${client_id}`,
        client_id,
        id
      );
    });
  };

  const showDetailPage = (e: KeyboardEvent) => {
    if (isOpenModal() && selectedItem.length === 0) return;
    e.preventDefault();

    const setting = localStorage.getItem("detail/display");
    selectedItem.map((id) => {
      if (setting !== null && ["single", "multi"].includes(setting)) {
        showDetailWindows(
          `${process.env.REACT_APP_URL}/clients/${client_id}/image/${id}?mode=${setting}`,
          client_id
        );
      } else {
        showDetailWindows(
          `${process.env.REACT_APP_URL}/clients/${client_id}/image/${id}?mode=single`,
          client_id
        );
      }
    });
  };

  useHotkeys("right", handleKeyPressRight, [handleKeyPressRight]);
  useHotkeys("left", handleKeyPressLeft, [handleKeyPressLeft]);
  useHotkeys("Up", handleKeyPressUp, [handleKeyPressUp]);
  useHotkeys("Down", handleKeyPressDown, [handleKeyPressDown]);
  useHotkeys("home", handleKeyPressHome, [handleKeyPressHome]);
  useHotkeys("end", handleKeyPressEnd, [handleKeyPressEnd]);
  useHotkeys("ctrl+a, command+a", handleAllSelect, [handleAllSelect]);
  useHotkeys("backspace, delete", handleDeleteData, [handleDeleteData]);
  useHotkeys("shift+right", handleRangeSelectRight, [handleRangeSelectRight]);
  useHotkeys("shift+left", handleRangeSelectLeft, [handleRangeSelectLeft]);
  useHotkeys("shift+Up", handleRangeSelectUp, [handleRangeSelectUp]);
  useHotkeys("shift+down", handleRangeSelectDown, [handleRangeSelectDown]);

  useHotkeys("ctrl+r, command+r", showScaleImagePage, [showScaleImagePage]);
  useHotkeys("ctrl+i, command+i", showDetailPage, [showDetailPage]);

  const itemData = useMemo(
    () => ({
      searchData,
      columnCount,
      itemCount,
      gapSize,
      cardSize,
      imageHeight,
      selectedItem,
      focusItem,
    }),
    [
      searchData,
      columnCount,
      itemCount,
      gapSize,
      cardSize,
      imageHeight,
      selectedItem,
      focusItem,
    ]
  );

  return (
    <>
      <List
        className="bg-gray-background border-0"
        height={height}
        itemCount={rowCount}
        itemSize={cardSize.height + gapSize}
        width={width}
        itemData={itemData}
      >
        {ListItem}
      </List>
    </>
  );
};

export default memo(ListWrapper);
