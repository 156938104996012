import React, { memo, InputHTMLAttributes, forwardRef, useRef, KeyboardEventHandler } from "react";
import { Property } from "csstype";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  placeholder?: string;
  bg?: "white" | "skyblue";
  inputSize: "small" | "large";
  width?: Property.Width;
  height?: Property.Height;
  fontSize?: Property.FontSize;
  spacing?: Property.Margin;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className = "",
      placeholder = "",
      bg = "white",
      inputSize,
      width = undefined,
      height = undefined,
      fontSize = undefined,
      spacing = "0.25rem",
      onKeyPress = undefined,
      ...props
    },
    ref
  ) => {
    const defaultClassName = "font-sans border-gray-line p-1 rounded-md border";
    const sizeProperty = inputSize === "small" ? "" : "px-2";
    const backgroundColor = bg === "skyblue" ? "bg-blue-skyblue" : "bg-white";

    // Refが必要ない場合の警告防止
    const localRef = useRef(null);
    const inputRef = ref || localRef;

    const getHeight = (): Property.Height => {
      if (height !== undefined) return height;
      switch (inputSize) {
        case "small":
          return "20px";
        case "large":
          return "32px";
      }
    };

    const getFontSize = (): Property.FontSize => {
      if (fontSize !== undefined) return fontSize;
      switch (inputSize) {
        case "small":
          return "10px";
        case "large":
          return "12px";
      }
    };

    return (
      <input
        className={[
          defaultClassName,
          sizeProperty,
          backgroundColor,
          className,
        ].join(" ")}
        placeholder={placeholder}
        style={{
          width: width,
          height: getHeight(),
          margin: spacing,
          fontSize: getFontSize(),
        }}
        ref={inputRef}
        autoComplete="off"
        onKeyPress={onKeyPress}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export default memo(Input);
