import React, { FC } from "react";

import Image from "../../../atoms/Image";
import Label from "../../../atoms/Label";

import image from "../../../../images/undraw_empty_xct9.svg";

const ImageNotFound: FC = () => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <Image src={image} width="280px" height="240px" />
      <Label className="font-bold">画像が見つかりませんでした</Label>
      <Label>ご指定の検索条件で画像が見つかりませんでした。</Label>
      <Label>条件を変更して画像を検索し直してください。</Label>
    </div>
  );
};

export default ImageNotFound;
