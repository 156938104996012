import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useContextMenu } from "react-contexify";
import { useParams } from "react-router-dom";
import { Property } from "csstype";

import { useDate } from "../../../hooks/useDate";
import { ImageBoxImageSimple, Size } from "../../../types/types";
import { LabelColor } from "../../../util/LabelColor";
import { useStateContext } from "../../../context/StateProvider";
import { useSelectedItemStateContext } from "../../../context/SelectedItemStateProvider";
import ContextMenu from "../../molecules/ContextMenu";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { useSearchStateContext } from "../../../context/SearchStateProvider";
import { useKeyStateContext } from "../../../context/KeyStateProvider";
import Image from "../../atoms/Image";
import { useDetailWindow } from "../../../hooks/useDetailWindow";
import { NewOldType } from "../../../util/NewOldType";

interface Props {
  index: number;
  imageId: number;
  data: ImageBoxImageSimple;
  cardSize: {
    height: number;
    width: number;
  };
  imageHeight: number;
  spacing?: Property.Margin;
  isSelect: boolean;
  isFocus: boolean;
}

const ImageCard: FC<Props> = ({
  index,
  imageId,
  data,
  cardSize,
  imageHeight,
  spacing = "0.25rem",
  isSelect,
  isFocus,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { show, hideAll } = useContextMenu({ id: String(imageId) });
  const { keyPress } = useKeyStateContext();
  const { size } = useStateContext();
  const { searchData } = useSearchStateContext();
  const { selectedItem, setSelectedItem, focusItem, setFocusItem } =
    useSelectedItemStateContext();
  const { formatDate } = useDate();
  const { showDetailWindows } = useDetailWindow();
  const { client_id } = useParams<{ client_id: string }>();

  const { width, height } = cardSize;
  const { targetViewPosition } = useOnScreen(cardRef);

  const isRangeSelectMode = () => {
    return keyPress.keyPress.get("Shift");
  };
  const isPickSelectMode = () => {
    return keyPress.keyPress.get("Control") || keyPress.keyPress.get("Meta");
  };

  const onClick = useCallback(() => {
    const isPick = isPickSelectMode();
    const isRange = isRangeSelectMode();

    if (isPick) {
      if (focusItem === index) {
        const filteredItem = selectedItem.filter((item) => item !== imageId);
        filteredItem.length === 0
          ? setFocusItem(null)
          : setFocusItem(focusItem);
        setSelectedItem(filteredItem);
      } else if (focusItem !== index && selectedItem.includes(imageId)) {
        setFocusItem(index);
        setSelectedItem(selectedItem.filter((item) => item !== imageId));
      } else {
        setSelectedItem([...selectedItem, data.id]);
        setFocusItem(index);
      }
    } else if (isRange) {
      if (searchData === null) return;
      if (focusItem === null) {
        let ids: number[] = [];
        for (let i = 0; i < index; i++) {
          ids = [searchData[i].id, ...ids];
        }
        setSelectedItem(ids);
        setFocusItem(index);
      } else if (focusItem > index) {
        let ids: number[] = selectedItem.concat();
        /***
         * 1. 前の要素を取得
         * 2. 前の要素が選択済み要素である場合、選択範囲の巻き戻しであるため選択
         * 3. 前の要素が選択されていない場合選択済みの要素として追加する
         */
        for (let i = focusItem; index < i; i--) {
          const index = ids.indexOf(searchData[i - 1].id);
          if (index !== -1) {
            ids.splice(ids.indexOf(searchData[i].id));
          } else {
            ids = [...ids, searchData[i - 1].id];
          }
        }
        setSelectedItem(ids);
        setFocusItem(index);
      } else if (focusItem < index) {
        let ids: number[] = selectedItem.concat();
        /***
         * 1. 後の要素を取得
         * 2. 後の要素が選択済み要素である場合、選択範囲の巻き戻しであるため選択
         * 3. 後の要素が選択されていない場合選択済みの要素として追加する
         */
        for (let i = focusItem; i < index; i++) {
          const index = ids.indexOf(searchData[i + 1].id);
          if (index !== -1) {
            ids.splice(ids.indexOf(searchData[i].id));
          } else {
            ids = [...ids, searchData[i + 1].id];
          }
        }
        setSelectedItem(ids);
        setFocusItem(index);
      }
    } else {
      setSelectedItem([data.id]);
      setFocusItem(index);
    }
  }, [searchData, selectedItem]);

  const onDoubleClick = useCallback(() => {
    const mode = localStorage.getItem("detail/display");

    if (mode) {
      showDetailWindows(
        `${process.env.REACT_APP_URL}/clients/${client_id}/image/${imageId}?mode=${mode}`,
        client_id
      );
    } else {
      showDetailWindows(
        `${process.env.REACT_APP_URL}/clients/${client_id}/image/${imageId}?mode=single`,
        client_id
      );
    }
  }, [showDetailWindows]);

  const pickColorLabel = (v: LabelColor) => {
    switch (v) {
      case LabelColor.Red:
        return "bg-label-red";
      case LabelColor.Orange:
        return "bg-label-orange";
      case LabelColor.Yellow:
        return "bg-label-yellow";
      case LabelColor.Green:
        return "bg-label-green";
      case LabelColor.Blue:
        return "bg-label-blue";
      case LabelColor.Magenta:
        return "bg-label-magenta";
      case LabelColor.Gray:
        return "bg-label-gray";
      case LabelColor.White:
        return "bg-label-white";
      default:
        return `${v}`;
    }
  };

  const pickColorNewOldType = (v: NewOldType) => {
    switch (v) {
      case NewOldType["古いかも？"]:
        return "bg-label-orange";
      case NewOldType.古いです:
        return "bg-label-red";
      default:
        return "";
    }
  };

  const getFontSize = (v: Size) => {
    switch (v) {
      case "large":
        return "text-12px line-clamp-3";
      case "middle":
        return "text-10px line-clamp-2";
      case "small":
        return "text-10px truncate";
    }
  };

  const cacheBusting = () => {
    if (data != null && data.update_date) {
      return new Date(data.update_date).getTime();
    }
    return "none";
  };

  const labelColor = useMemo(() => pickColorLabel(data.color), [data.color]);
  const newOldTypeColor = useMemo(
    () => pickColorNewOldType(data.new_old_type),
    [data.new_old_type]
  );
  const fontSize = useMemo(() => getFontSize(size), [size]);

  useEffect(() => {
    hideAll();
    if (focusItem !== index) return;

    switch (targetViewPosition) {
      case "ABOVE_VIEWPORT":
        cardRef?.current?.scrollIntoView({ block: "start" });
        break;
      case "BELOW_VIEWPORT":
        cardRef?.current?.scrollIntoView({ block: "end" });
        break;
      default:
        break;
    }

    const id = localStorage.getItem("client/" + client_id + "/select/image");
    if (id === `${imageId}`) {
      return;
    }

    localStorage.setItem("client/" + client_id + "/select/image", `${imageId}`);
  }, [focusItem]);

  return (
    <>
      <div
        className={[
          isSelect ? "bg-green-teal bg-opacity-30" : "bg-white",
          isFocus ? "border-2 border-green-700" : "border",
          "flex flex-col",
        ].join(" ")}
        style={{ margin: spacing, width: width, height: height }}
        onClick={() => onClick()}
        onDoubleClick={() => onDoubleClick()}
        ref={cardRef}
        onContextMenu={show}
      >
        <div
          className={[
            size === "large" ? "text-12px" : "text-10px",
            "m-1 truncate font-bold",
            newOldTypeColor,
          ].join(" ")}
        >
          {data.title}
        </div>
        <Image
          src={`${
            process.env.REACT_APP_API_URL
          }/api/images/${imageId}/thumbnail?scale=small&cb=${cacheBusting()}`}
          height={`${imageHeight}px`}
          width="100%"
        />
        <div className="flex flex-col flex-1 justify-between">
          <div className={[fontSize, labelColor, "flex-1 m-1"].join(" ")}>
            {[data.maker, data.locality, data.product_name, data.spec].join(
              " "
            )}
          </div>
          <div
            className={`${size === "large" ? "text-10px" : "text-8px"} mx-1`}
          >
            {size === "large"
              ? formatDate(data.create_date, "YYYY.MM.DD HH:mm:ss")
              : formatDate(data.create_date, "YYYY.MM.DD")}
          </div>
        </div>
      </div>
      <ContextMenu id={imageId} />
    </>
  );
};

export default memo(ImageCard);
