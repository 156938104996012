import React, { FC, memo, useCallback, ChangeEvent } from "react";

import RadioText from "../RadioText";
import {
  FieldValues,
  Control,
  UseFormSetValue,
  Controller,
} from "react-hook-form";

interface Props {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const SelectOperation: FC<Props> = ({ control, setValue }) => {
  const onChangeRadio = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue("search_logical_operation", e.target.value);
    },
    [setValue]
  );

  return (
    <div className="flex flex-row justify-start">
      <Controller
        render={({ field: { value } }) => (
          <RadioText
            id="AND"
            value="AND"
            checked={value === "AND"}
            onChange={onChangeRadio}
            label="AND"
          />
        )}
        name="search_logical_operation"
        control={control}
      />
      <Controller
        render={({ field: { value } }) => (
          <RadioText
            id="OR"
            value="OR"
            checked={value === "OR"}
            onChange={onChangeRadio}
            label="OR"
          />
        )}
        name="search_logical_operation"
        control={control}
      />
    </div>
  );
};

export default memo(SelectOperation);
