import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { Size } from "../types/types";

interface StateContextType {
  openSearch: boolean;
  setIsOpenSearch: Dispatch<SetStateAction<boolean>>;
  size: Size;
  setSize: Dispatch<SetStateAction<Size>>;
}

const StateContext = createContext({} as StateContextType);

export const StateProvider: FC = ({ children }) => {
  const [openSearch, setIsOpenSearch] = useState<boolean>(false);
  const [size, setSize] = useState<Size>("large");

  return (
    <StateContext.Provider
      value={{
        openSearch,
        setIsOpenSearch,
        size,
        setSize,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = (): StateContextType => useContext(StateContext);
