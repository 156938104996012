import React, { FC } from "react";

const Header: FC = () => {
  return (
    <header className="bg-black-header items-center justify-between inline-flex px-2 shadow-2xl top-0 w-full h-30px">
      <p className="ml-l text-20px text-white font-sans inline-flex items-center">
        ImageBox
      </p>
    </header>
  );
};

export default Header;
