import React, { FC, useEffect, useState, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import SearchPageHeader from "../organisms/Layout/SearchPageHeader";
import SideBar from "../organisms/Layout/SideBar";
import SettingModal from "../organisms/Modal/SettingModal";
import ClientSelectModal from "../organisms/Modal/ClientSelectModal";
import SearchResult from "../organisms/Layout/SearchResult";
import ErrorDialog from "../organisms/Modal/ErrorDialog";
import PatternSelectModal from "../organisms/Modal/PatternSelectModal";
import BulkEditModal from "../organisms/Modal/BulkEditModal";
import PasswordModal from "../organisms/Modal/PasswordModal";
import TagSelectModal from "../organisms/Modal/TagSelectModal";
import TokenModal from "../organisms/Modal/TokenModal";
import TokenResultModal from "../organisms/Modal/TokenResultModal";
import SharePatternSelectModal from "../organisms/Modal/SharePatternSelectModal";
import { useClient } from "../../hooks/useClient";
import { useModalStateContext } from "../../context/ModalStateProvider";
import { useSearch } from "../../hooks/useSearch";
import { useErrorStateContext } from "../../context/ErrorStateProvider";
import { ApiError, Client } from "../../types/types";
import axios, { AxiosError } from "axios";
import { useStateContext } from "../../context/StateProvider";
import { useTitle } from "../../hooks/useTitle";
import { useModal } from "../../hooks/useModal";
import { useError } from "../../hooks/useError";
import Page403 from "../pages/Page403";
import Page404 from "../pages/Page404";

const SearchTemp: FC = () => {
  const [client, setClient] = useState<Client>();
  const { modal, setModal } = useModalStateContext();
  const { error } = useErrorStateContext();
  const { setIsOpenSearch } = useStateContext();
  const { search, select, exclude, loading } = useSearch();
  const { getClient, getSpecialsClients } = useClient();
  const { isOpenModal } = useModal();

  const [errorSt, setErrorSt] = useState<number>();
  const { setError } = useError();

  // URLパラメータの取得
  const { client_id } = useParams<{ client_id: string }>();

  const handleOnSubmitPassword = useCallback(
    async (password: string) => {
      const data = { short_name: client?.short_name, password: password };
      const config = { withCredentials: true };

      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/clients/auth`, data, config)
        .then(() => {
          setIsOpenSearch(true);
          setModal((prevState) => {
            return { ...prevState, passwordModal: false };
          });
        });
    },
    [client]
  );

  useEffect(() => {
    (async () => {
      try {
        if (client_id) {
          const client = await getClient(client_id);
          const specialClients = await getSpecialsClients();

          setClient(client);
          useTitle().setSearchPageTitle(client.short_name + "：" + client.name);

          if (specialClients.includes(client.short_name)) {
            setModal({ ...modal, passwordModal: true });
            setIsOpenSearch(false);
          } else {
            setIsOpenSearch(true);
          }
        } else {
          setModal({ ...modal, clientSelectModal: true });
        }
      } catch (e) {
        const response = (e as AxiosError<ApiError>).response;
        if (response) {
          switch (response.status) {
            case 403:
              setErrorSt(response.request.status);
              break;
            case 404:
              setErrorSt(response.request.status);
              break;
            default:
              setError(response.data);
          }
        }
      }
    })();
  }, [client_id]);

  useEffect(() => {
    if (localStorage.getItem("setting/limit") === null)
      localStorage.setItem("setting/limit", "20000");
    if (localStorage.getItem("setting/sort") === null)
      localStorage.setItem("setting/sort", "システム登録日時");
    if (localStorage.getItem("setting/order") === null)
      localStorage.setItem("setting/order", "降順");
    if (localStorage.getItem("setting/client_filters") === null)
      localStorage.setItem("setting/client_filters", "");
  }, []);

  const onOpenClientSelectModal = (e: Event) => {
    if (isOpenModal()) return;

    e?.preventDefault();
    setModal({ ...modal, clientSelectModal: true });
  };

  useHotkeys("ctrl+e", onOpenClientSelectModal, [onOpenClientSelectModal]);
  useHotkeys("command+e", onOpenClientSelectModal, [onOpenClientSelectModal]);

  // APIエラー時
  if (errorSt !== undefined) {
    switch (errorSt) {
      case 403:
        return <Page403 />;
      case 404:
        return <Page404 />;
    }
  }

  return (
    <div className="flex flex-col h-screen w-screen">
      <SearchPageHeader client={client} />
      <div className="flex flex-row flex-grow flex-1 bg-gray-background shadow-md overflow-y-hidden overflow-x-auto">
        <SideBar
          onSearch={search}
          onSelect={select}
          onExclude={exclude}
          isLoading={loading}
        />
        <SearchResult isLoading={loading} />
      </div>
      {modal.settingModal && <SettingModal />}
      {modal.clientSelectModal && <ClientSelectModal />}
      {modal.patternSelectModal && <PatternSelectModal />}
      {modal.sharePatternSelectModal && <SharePatternSelectModal />}
      {modal.tagSelectModal && <TagSelectModal />}
      {modal.bulkEditModal && <BulkEditModal />}
      {modal.passwordModal && (
        <PasswordModal handleOnSubmitPassword={handleOnSubmitPassword} />
      )}
      {modal.tokenModal && <TokenModal />}
      {modal.tokenResultModal && <TokenResultModal />}
      {error && <ErrorDialog />}
    </div>
  );
};

export default memo(SearchTemp);
