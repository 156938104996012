import React, { FC, useEffect } from "react";

import LoginForm from "../organisms/Layout/LoginForm";
import Header from "../organisms/Layout/Header";
import { useTitle } from "../../hooks/useTitle";
import { useAuth } from "../../hooks/useAuth";

const Login: FC = () => {
  const { isLogin } = useAuth();

  useTitle().setTitle("ログイン");

  useEffect(() => {
    if (isLogin) {
      location.href = `${process.env.REACT_APP_API_URL}/auth/sso`;
    }
  }, [isLogin]);

  return (
    <div className="flex flex-col w-screen h-screen">
      <Header />
      <div className="flex flex-grow flex-1 justify-center items-center bg-gray-background">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
