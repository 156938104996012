import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";

import CheckBox from "../../../atoms/CheckBox";
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Separator from "../../../atoms/Separator";
import Label from "../../../atoms/Label";
import { useDownload } from "../../../../hooks/useDownload";
import { DownloadOption } from "../../../../repositories/DownloadStorageRepository";
import { useModal } from "../../../../hooks/useModal";
import DetailActivateButton from "../../../molecules/DetailActivateButton";
import { useAuthContext } from "../../../../context/AuthProvider";

const ImageCardListHeader: FC = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [spinNumber, setSpinNumber] = useState<number>(1);
  const [spinBranch, setSpinBranch] = useState<string>("");
  const [product, setProduct] = useState<boolean>(false);
  const { isOpenModal } = useModal();
  const { client_id } = useParams<{ client_id: string }>();
  const { user } = useAuthContext();

  const download = useDownload();
  const onChangeCheck = useCallback(
    () => setChecked((prevState) => !prevState),
    []
  );
  const onChangeProduct = useCallback(
    () => setProduct((prevState) => !prevState),
    []
  );

  const isIncludes = (arr: string[] | undefined, target: string[]): boolean => {
    if (arr === undefined) return false;
    return arr.some((el) => target.includes(el));
  };

  const onChangeSpinNo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      setSpinNumber(1);
    } else {
      if (parseInt(e.target.value) >= 9999) {
        setSpinNumber(9999);
      } else {
        setSpinNumber(parseInt(e.target.value));
      }
    }
  }, []);

  const onChangeSpinBranch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSpinBranch(e.target.value);
  }, []);

  const onKeyDownAlphabet = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (spinBranch === "" || spinBranch === " ") {
        setSpinBranch("A");
        return;
      }

      const regex = new RegExp(/^[A-Z]*$/);
      if (regex.test(spinBranch)) {
        if (e.key === "ArrowUp") {
          spinBranch === "Z"
            ? setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) - 25))
            : setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) + 1));
        } else if (e.key === "ArrowDown") {
          spinBranch === "A"
            ? setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) + 25))
            : setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) - 1));
        }
      } else {
        return false;
      }
    },
    [spinBranch]
  );

  const onDownloadFile = () => {
    if (!isIncludes(user?.permission, ["Browser", "ImageAdmin"])) {
      alert("ダウンロード権限を所有していません。");
      return;
    }

    const options = checked
      ? {
          no: spinNumber,
          branch: spinBranch,
          product: product,
        }
      : {
          no: undefined,
          branch: undefined,
          product: undefined,
        };

    if (!checked && product) options.product = true;

    download.downloadFile(client_id, options as DownloadOption);

    if (checked && spinNumber < 9999) setSpinNumber(spinNumber + 1);
  };

  const onDownloadEvent = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (isOpenModal()) return;
      onDownloadFile();
    },
    [isOpenModal, onDownloadFile]
  );

  const onIncrementNumber = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinNumber <= 9999) setSpinNumber((prevState) => prevState + 1);
    },
    [checked, isOpenModal, spinNumber]
  );

  const onDecrementNumber = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinNumber !== 1) setSpinNumber((prevState) => prevState - 1);
    },
    [spinNumber, checked, isOpenModal]
  );

  const onResetNumber = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinNumber !== 1) setSpinNumber(1);
    },
    [checked, spinNumber, spinNumber]
  );

  const onIncrementBranch = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinBranch === "") {
        setSpinBranch("A");
      } else {
        spinBranch === "Z"
          ? setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) - 25))
          : setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) + 1));
      }
    },
    [checked, isOpenModal, spinBranch]
  );

  const onDecrementBranch = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinBranch === "") {
        setSpinBranch("A");
      } else {
        spinBranch === "A"
          ? setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) + 25))
          : setSpinBranch(String.fromCharCode(spinBranch.charCodeAt(0) - 1));
      }
    },
    [checked, isOpenModal, spinBranch]
  );

  const onResetBranch = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      if (!checked || isOpenModal()) return;
      if (spinBranch !== "") {
        setSpinBranch("");
      }
    },
    [checked, isOpenModal, spinBranch]
  );

  useHotkeys("ctrl+d", onDownloadEvent, [onDownloadEvent]);
  useHotkeys("ctrl+Up", onIncrementNumber, [onIncrementNumber]);
  useHotkeys("ctrl+Down", onDecrementNumber, [onDecrementNumber]);
  useHotkeys("ctrl+right", onIncrementBranch, [onIncrementBranch]);
  useHotkeys("ctrl+left", onDecrementBranch, [onDecrementBranch]);
  useHotkeys("ctrl+shift+Up, ctrl+shift+Down", onResetNumber, [onResetNumber]);
  useHotkeys("ctrl+shift+right, ctrl+shift+left", onResetBranch, [
    onResetBranch,
  ]);

  useHotkeys("command+d", onDownloadEvent, [onDownloadEvent]);
  useHotkeys("command+Up", onIncrementNumber, [onIncrementNumber]);
  useHotkeys("command+Down", onDecrementNumber, [onDecrementNumber]);
  useHotkeys("command+right", onIncrementBranch, [onIncrementBranch]);
  useHotkeys("command+left", onDecrementBranch, [onDecrementBranch]);
  useHotkeys("command+shift+Up, command+shift+Down", onResetNumber, [
    onResetNumber,
  ]);
  useHotkeys("command+shift+right, command+shift+left", onResetBranch, [
    onResetBranch,
  ]);

  return (
    <div className="flex flex-row bg-white h-40px shadow-md items-center top-0 w-full py-2 whitespace-nowrap">
      <Button
        className="px-2 text-12px w-100px"
        primary={true}
        size="small"
        onClick={onDownloadFile}
      >
        ダウンロード
      </Button>
      <Separator separatorType="vertical" />
      <CheckBox
        className="inline-flex items-center"
        onChange={onChangeCheck}
        checked={checked}
      />
      <Label className="text-9px md:text-12px">No</Label>
      <Input
        type="number"
        value={spinNumber}
        inputSize="small"
        disabled={!checked}
        width="60px"
        min={1}
        max={9999}
        onChange={onChangeSpinNo}
      />
      <div className="inline-flex items-center">-</div>
      <Input
        type="text"
        value={spinBranch}
        inputSize="small"
        disabled={!checked}
        width="60px"
        onChange={onChangeSpinBranch}
        onKeyDown={onKeyDownAlphabet}
        maxLength={1}
        pattern={"^[A-Z]+$"}
      />
      <Separator separatorType="vertical" />
      <CheckBox
        className="inline-flex items-center"
        onChange={onChangeProduct}
        checked={product}
      />
      <Label className="text-12px px-1" spacing={0}>
        品名
      </Label>
      <Separator separatorType="vertical" />
      <DetailActivateButton />
      <Separator separatorType="vertical" />
      <Button
        className="px-2 text-12px w-100px"
        primary={false}
        size="small"
        onClick={download.selectFolder}
      >
        DLフォルダ選択
      </Button>
      <Label className="text-12px">{`DLフォルダ : ${
        download.folder === null ? "未選択" : download.folder.name
      }`}</Label>
    </div>
  );
};

export default memo(ImageCardListHeader);
