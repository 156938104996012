import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useTitle } from "../../hooks/useTitle";
import { useImageDetails } from "../../hooks/useImageDetails";
import ScaleImageTemp from "../templates/ScaleImageTemp";
import Page403 from "./Page403";
import Page404 from "./Page404";
import { ApiError, ImageBoxImageDetail } from "../../types/types";
import { AxiosError } from "axios";
import { useError } from "../../hooks/useError";

interface ImageParam {
  image_id: string;
}

const ScaleImage: FC = () => {
  useTitle().setTitle("画像拡大");

  const [imageData, setImageData] = useState<ImageBoxImageDetail>();
  const [errorSt, setErrorSt] = useState<number>();
  const { image_id } = useParams<ImageParam>();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const client_id = params.get("cid");
  const { setError } = useError();

  // 画像データ取得
  const { get } = useImageDetails();

  useEffect(() => {
    (async () => {
      try {
        const data = await get(image_id, client_id !== null ? client_id : 1);
        setImageData(data);
      } catch (e) {
        const response = (e as AxiosError<ApiError>).response;
        if (response) {
          switch (response.status) {
            case 403:
              setErrorSt(response.request.status);
              break;
            case 404:
              setErrorSt(response.request.status);
              break;
            default:
              setError(response.data);
          }
        }
      }
    })();
  }, []);

  // APIエラー時
  if (errorSt !== undefined) {
    switch (errorSt) {
      case 403:
        return <Page403 />;
      case 404:
        return <Page404 />;
    }
  }

  return <ScaleImageTemp data={imageData} />;
};

export default ScaleImage;
