import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

import { ImageBoxImageSimple } from "../types/types";

interface SearchStateContextType {
  isSearched: boolean;
  setIsSearched: Dispatch<SetStateAction<boolean>>;
  searchData: ImageBoxImageSimple[];
  setSearchData: Dispatch<SetStateAction<ImageBoxImageSimple[]>>;
}

const SearchStateContext = createContext({} as SearchStateContextType);

export const SearchStateProvider: FC = ({ children }) => {
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<ImageBoxImageSimple[]>([]);

  return (
    <SearchStateContext.Provider
      value={{
        isSearched,
        setIsSearched,
        searchData,
        setSearchData,
      }}
    >
      {children}
    </SearchStateContext.Provider>
  );
};

export const useSearchStateContext = (): SearchStateContextType =>
  useContext(SearchStateContext);
