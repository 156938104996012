import { Client, ClientAttribute, ImageBoxImageDetail } from "../types/types";
import { EditableData } from "../components/templates/ImageDetailTemp";

interface AttributesData {
  useStartDate: Array<string>;
  title: Array<string>;
  productName: Array<string>;
  spec: Array<string>;
  productFilename: Array<string>;
  price: Array<string>;
  variousCode: Array<string>;
}

export class DetailRepository {
  private readonly _image: ImageBoxImageDetail;
  private readonly _client: Client;

  constructor(image: ImageBoxImageDetail, client: Client) {
    this._image = image;
    this._client = client;
  }

  public createAttributeDataList(): AttributesData {
    const image = this._image;
    const client = this._client;
    const clientAttributes = this._image.client_attributes;
    const shortName = image.image_code.substr(0, 3);

    const useStartDate: Array<string> = [];
    const title: Array<string> = [];
    const productName: Array<string> = [];
    const spec: Array<string> = [];
    const productFilename: Array<string> = [];
    const price: Array<string> = [];
    const variousCode: Array<string> = [];

    const parentData: ClientAttribute | undefined = clientAttributes[shortName];

    if (parentData) {
      useStartDate[0] = parentData.use_start_date;
      title[0] = parentData.title;
      productName[0] = parentData.product_name;
      spec[0] = parentData.spec;
      productFilename[0] = parentData.product_filename;
      price[0] = parentData.price;
      variousCode[0] = parentData.various_code;
    } else {
      useStartDate[0] = image.use_start_date;
      title[0] = image.title;
      productName[0] = image.product_name;
      spec[0] = image.spec;
      productFilename[0] = image.product_filename;
      price[0] = image.price;
      variousCode[0] = image.various_code;
    }

    const sca: ClientAttribute | undefined =
      clientAttributes[client.short_name];

    if (sca && sca.client_short_name !== shortName) {
      if (sca.use_start_date) {
        useStartDate.push(`${sca.client_short_name}:${sca.use_start_date}`);
      }
      if (sca.title) {
        title.push(`${sca.client_short_name}:${sca.title}`);
      }
      if (sca.product_name) {
        productName.push(`${sca.client_short_name}:${sca.product_name}`);
      }
      if (sca.spec) {
        spec.push(`${sca.client_short_name}:${sca.spec}`);
      }
      if (sca.product_filename) {
        productFilename.push(
          `${sca.client_short_name}:${sca.product_filename}`
        );
      }
      if (sca.price) {
        price.push(`${sca.client_short_name}:${sca.price}`);
      }
      if (sca.various_code) {
        variousCode.push(`${sca.client_short_name}:${sca.various_code}`);
      }
    }

    for (const [key, value] of Object.entries(clientAttributes)) {
      if (key === shortName || key === sca?.client_short_name) {
        continue;
      }

      if (value.use_start_date) {
        useStartDate.push(`${key}:${value.use_start_date}`);
      }
      if (value.title) {
        title.push(`${key}:${value.title}`);
      }
      if (value.product_name) {
        productName.push(`${key}:${value.product_name}`);
      }
      if (value.spec) {
        spec.push(`${key}:${value.spec}`);
      }
      if (value.product_filename) {
        productFilename.push(`${key}:${value.product_filename}`);
      }
      if (value.price) {
        price.push(`${key}:${value.price}`);
      }
      if (value.various_code) {
        variousCode.push(`${key}:${value.various_code}`);
      }
    }

    return {
      useStartDate: useStartDate,
      title: title,
      productName: productName,
      spec: spec,
      productFilename: productFilename,
      price: price,
      variousCode: variousCode,
    } as AttributesData;
  }

  public createInitialData(clientId: number | null): EditableData {
    const data = this._image;

    if (!Object.keys(data.client_attributes).length) {
      return { ...data, title2: "", title3: data.title };
    }
    if (clientId !== null && data.client_attributes) {
      for (const [, value] of Object.entries(data.client_attributes)) {
        if (value.client_id === clientId) {
          let attrTitle = "";
          let index;

          if (value.title.indexOf("/") !== -1) {
            index = value.title.lastIndexOf("/");
          } else if (value.title.indexOf("／") !== -1) {
            index = value.title.lastIndexOf("／");
          }

          if (index !== -1 && index !== undefined) {
            attrTitle = value.title.substr(index + 1);
          } else {
            attrTitle = "";
          }

          return {
            ...data,
            use_start_date: value.use_start_date,
            product_filename: value.product_filename,
            product_name: value.product_name,
            spec: value.spec,
            various_code: value.various_code,
            title2: attrTitle,
            title3: value.title,
          };
        }
      }
    }
    return { ...data, title2: "", title3: data.title };
  }

  public createComboList(useClients: Client[]): Map<number, string> {
    const client = this._client;
    const clientAttribute = this._image.client_attributes;
    const masterClientId = this._image.master_client_id;

    const returnValue = new Map<number, string>();

    for (const [, value] of Object.entries(clientAttribute)) {
      const isSpecialOrMaster = masterClientId === value.client_id;

      if (isSpecialOrMaster) {
        returnValue.set(
          value.client_id,
          `＊${value.client_short_name}：${value.client_name}`
        );
      } else {
        returnValue.set(
          value.client_id,
          // eslint-disable-next-line
          `　${value.client_short_name}：${value.client_name}`
        );
      }
    }

    useClients.map((client) => {
      if (masterClientId === client.id) {
        returnValue.set(client.id, `＊${client.short_name}：${client.name}`);
      } else {
        // eslint-disable-next-line
        returnValue.set(client.id, `　${client.short_name}：${client.name}`);
      }
    });

    if (!returnValue.has(client.id)) {
      if (masterClientId === client.id) {
        returnValue.set(client.id, `＊${client.short_name}：${client.name}`);
      } else {
        // eslint-disable-next-line
        returnValue.set(client.id, `　${client.short_name}：${client.name}`);
      }
    }

    return returnValue;
  }

  public createTagList(): Array<string> {
    const data = this._image.image_tags;
    const returnValue: Array<string> = [];

    data.map((tag) => {
      if (tag.name) {
        returnValue.push(tag.name);
      }
    });

    return returnValue;
  }
}
