import React, {
  FC,
  memo,
  useState,
  ChangeEvent,
  useCallback,
  useEffect,
} from "react";

import ImageRegisterHistory from "../ImageRegisterHistory";
import HistorySearchBox from "../../molecules/HistorySearchBox";
import Label from "../../atoms/Label";
import { RegisterTask } from "../../../types/types";
import SearchLoading from "../Layout/SearchLoading";
import { useDate } from "../../../hooks/useDate";

interface Props {
  registerTasks: Array<RegisterTask>;
  loading: boolean;
  isSearched: boolean;
  isContainOtherUser: boolean;
  onChangeIsContainOtherUser: (e: ChangeEvent<HTMLInputElement>) => void;
  onHistorySearch: (date: string) => void;
}

const ImageRegisterHistoryList: FC<Props> = (props) => {
  const [date, setDate] = useState<string>("");
  const { getDate } = useDate();

  const onChangeDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setDate(e.target.value),
    [setDate]
  );

  useEffect(() => {
    setDate(getDate("YYYY/MM/DD"));
  }, []);

  return (
    <div className="flex flex-col w-4/5 font-sans text-9px md:text-12px">
      <Label className="font-bold text-14px">登録履歴</Label>
      <div className="flex flex-row items-center my-3">
        <HistorySearchBox
          onChangeDate={onChangeDate}
          date={date}
          checked={props.isContainOtherUser}
          onChangeChecked={props.onChangeIsContainOtherUser}
          handleOnSearch={() => props.onHistorySearch(date)}
        />
      </div>
      {props.loading ? (
        <SearchLoading />
      ) : props.isSearched ? (
        props.registerTasks.length !== 0 ? (
          props.registerTasks.map((task) => (
            <ImageRegisterHistory registerTask={task} key={task.id} />
          ))
        ) : (
          <div className="flex flex-col justify-center items-center text-14px">
            <p className="font-bold text-16px">
              登録履歴が見つかりませんでした
            </p>
            <p>ご指定の日付で履歴が見つかりませんでした</p>
            <p>日付を変更し検索してください。</p>
          </div>
        )
      ) : (
        <div className="flex flex-col justify-center items-center text-14px">
          <p className="font-bold text-16px">登録履歴の検索をしましょう</p>
          <p>登録履歴を探すには</p>
          <p>上部入力欄に日付を設定し検索してください。</p>
        </div>
      )}
    </div>
  );
};

export default memo(ImageRegisterHistoryList);
