import React, { FC } from "react";

import Image from "../../../atoms/Image";
import Label from "../../../atoms/Label";

import image from "../../../../images/undraw_folder_39kl.svg";

const BeforeSearch: FC = () => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <Image src={image} width="280px" height="240px" />
      <Label className="font-bold">画像を検索しましょう</Label>
      <Label>管理されている画像を探すには</Label>
      <Label>左メニューから検索条件を設定し検索してください。</Label>
    </div>
  );
};

export default BeforeSearch;
