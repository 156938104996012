import React, { memo, FC, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Label from "../../../atoms/Label";
import SelectImageSize from "../../../molecules/SelectImageSize";

import { useSubWindow } from "../../../../hooks/useSubWindow";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { useAuthContext } from "../../../../context/AuthProvider";
import { Client } from "../../../../types/types";

interface Props {
  client?: Client;
}

const SearchPageHeader: FC<Props> = (props) => {
  const { setModal } = useModalStateContext();
  const { showSubWindow } = useSubWindow();

  const { user } = useAuthContext();

  const onClickRegister = useCallback(
    () => showSubWindow(`${process.env.REACT_APP_URL}/register`),
    []
  );

  const onOpenSettingModal = useCallback(
    () =>
      setModal((prevState) => {
        return { ...prevState, settingModal: true };
      }),
    []
  );

  const onOpenClientSelectModal = useCallback(
    () =>
      setModal((prevState) => {
        return { ...prevState, clientSelectModal: true };
      }),
    []
  );

  const onOpenTokenModal = useCallback(
    () => {
      if (user?.permission?.includes('ImageAdmin') == false) {
        return;
      }
      setModal((prevState) => {
        return { ...prevState, tokenModal: true }
      })
    },
    [user]
  );


    return (
    <header className="bg-black-header items-center justify-between inline-flex px-2 shadow-2xl top-0 w-full h-30px">
      <p className="ml-l text-20px text-white font-sans inline-flex items-center">
        ImageBox
      </p>
      <Button
        className="inline-flex justify-center mx-1 items-center w-125px"
        primary={true}
        size="small"
        onClick={onOpenClientSelectModal}
      >
        新規クライアントを開く
      </Button>
      <Input
        className="flex-1 inline-flex items-center justify-center font-bold w-full"
        defaultValue={
          props.client !== undefined
            ? props.client?.short_name + "：" + props.client?.name
            : ""
        }
        inputSize="small"
        type="text"
        spacing=""
        readOnly={true}
      />
      <SelectImageSize />
      <div className="inline-flex items-center justify-center mx-1">
        <Label className="text-12px text-white" onClick={onOpenTokenModal}>{`${
          user?.username ?? ""
        }`}</Label>
        <FontAwesomeIcon
          className="flex items-center pl-1 cursor-pointer"
          icon={faCog}
          size="2x"
          color="white"
          onClick={onOpenSettingModal}
        />
      </div>
      <Button
        className="w-100px"
        primary={true}
        size="small"
        onClick={onClickRegister}
      >
        画像登録
      </Button>
    </header>
  );
};

export default memo(SearchPageHeader);
