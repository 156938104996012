export enum LabelColor {
  None,
  Red,
  Orange,
  Yellow,
  Green,
  Blue,
  Magenta,
  Gray,
  White,
}
