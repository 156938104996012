import React, { FC, useRef, useEffect, memo, HTMLAttributes } from "react";
import { useOnScreen } from "../../../hooks/useOnScreen";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isFocus?: boolean;
}

const TableRow: FC<Props> = ({
  children,
  className = "",
  isFocus = false,
  ...props
}) => {
  const tableRowRef = useRef<HTMLDivElement>(null);
  const { targetViewPosition } = useOnScreen(tableRowRef);

  useEffect(() => {
    if (isFocus === false || isFocus === undefined) return;

    switch (targetViewPosition) {
      case "ABOVE_VIEWPORT":
        tableRowRef?.current?.scrollIntoView({ block: "center" });
        break;
      case "BELOW_VIEWPORT":
        tableRowRef?.current?.scrollIntoView({ block: "center" });
        break;
      default:
        break;
    }
  }, [isFocus]);

  return (
    <div className={className} {...props} ref={tableRowRef}>
      {children}
    </div>
  );
};

export default memo(TableRow);
