import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";

import { useSelectedItemStateContext } from "../context/SelectedItemStateProvider";
import { useError } from "./useError";
import { useSearchStateContext } from "../context/SearchStateProvider";
import { ApiError, ImageBoxImageSimple } from "../types/types";
import { SortItem } from "../util/SortItem";
import { Order } from "../util/Order";

export interface ApiResponse {
  status: number;
  type: string;
  data_format: string;
  data: ImageBoxImageSimple[];
}

export interface ApiResponse2 {
  status: number;
  type: string;
  data_format: string;
  // eslint-disable-next-line
  data: any;
}

interface CountResponse {
  status: number;
  type: string;
  data_format: string;
  data: {
    count: number;
    client_count: number;
  };
}

type SearchRequest = (
  searchWord: string,
  searchLogicalOperation: string,
  clientFilter: string,
  details: unknown,
  tags: Record<string | number, number>
) => void;

export const useSearch = (): {
  search: SearchRequest;
  select: SearchRequest;
  exclude: SearchRequest;
  loading: boolean;
  imageCount: (
    clientId: number | string
  ) => Promise<{ count: number; client_count: number }>;
} => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const { client_id } = useParams<{ client_id: string }>();
  const { setSearchData, setIsSearched, searchData } = useSearchStateContext();
  const { setError } = useError();
  const { setSelectedItem, setFocusItem } = useSelectedItemStateContext();

  const search = useCallback(
    (
      searchWord: string,
      searchLogicalOperation: string,
      clientFilter: string,
      details: unknown,
      tags: Record<string | number, number>
    ) => {
      const filter_tags = [];
      for (const [key, value] of Object.entries(tags)) {
        if (value === 0 || value == null) continue;
        filter_tags.push(Number(key));
      }

      const sSort = localStorage.getItem("setting/sort") ?? "画像タイトル";
      const sort =
        Object.keys(SortItem)[
          (Object.values(SortItem) as string[]).indexOf(sSort)
        ].toUpperCase();

      const sOrder = localStorage.getItem("setting/order") ?? "降順";
      const order =
        Object.values(Order)[
          (Object.keys(Order) as string[]).indexOf(sOrder)
        ].toUpperCase();

      const data = {
        client_id: client_id,
        response_limit: localStorage.getItem("setting/limit") ?? 20000,
        sort: sort,
        order: order,
        search_word: searchWord,
        search_logical_operation: searchLogicalOperation,
        client_filter: clientFilter,
        details: details === undefined ? {} : details,
        tags: filter_tags,
      };
      const config = { withCredentials: true };
      setIsLoading(true);
      setIsSearched(true);

      axios
        .post<ApiResponse>(
          `${process.env.REACT_APP_API_URL}/api/search`,
          data,
          config
        )
        .then((res) => {
          setSelectedItem([]);
          setFocusItem(null);
          setSearchData(res.data.data);
        })
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            if (e.response.status === 400) {
              alert(e.response.data.message);
            } else {
              setError(e.response.data);
            }
          } else {
            setError(e);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [client_id]
  );

  const select = useCallback(
    (
      searchWord: string,
      searchLogicalOperation: string,
      clientFilter: string,
      details: unknown,
      tags: Record<string | number, number>
    ) => {
      const filter_tags = [];
      for (const [key, value] of Object.entries(tags)) {
        if (value === 0 || value == null) continue;
        filter_tags.push(Number(key));
      }
      const sSort = localStorage.getItem("setting/sort") ?? "画像タイトル";
      const sort =
        Object.keys(SortItem)[
          (Object.values(SortItem) as string[]).indexOf(sSort)
        ].toUpperCase();

      const sOrder = localStorage.getItem("setting/order") ?? "降順";
      const order =
        Object.values(Order)[
          (Object.keys(Order) as string[]).indexOf(sOrder)
        ].toUpperCase();

      const data = {
        client_id: client_id,
        response_limit: localStorage.getItem("setting/limit") ?? 20000,
        sort: sort,
        order: order,
        search_word: searchWord,
        search_logical_operation: searchLogicalOperation,
        client_filter: clientFilter,
        details: details === undefined ? {} : details,
        tags: filter_tags,
      };
      const config = { withCredentials: true };

      axios
        .post<ApiResponse2>(
          `${process.env.REACT_APP_API_URL}/api/search/select`,
          data,
          config
        )
        .then((res) => {
          const ids = res.data.data["ids"] as number[];
          const select_ids = (searchData ?? [])
            .filter((data) => ids.includes(data.id))
            .map((data) => data.id);
          setFocusItem(select_ids.length > 0 ? select_ids[0] : null);
          setSelectedItem(select_ids);
        })
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            if (e.response.status === 400) {
              alert(e.response.data.message);
            } else {
              setError(e.response.data);
            }
          } else {
            setError(e.message);
          }
        });
    },
    [client_id, searchData]
  );

  const exclude = useCallback(
    (
      searchWord: string,
      searchLogicalOperation: string,
      clientFilter: string,
      details: unknown,
      tags: Record<string | number, number>
    ) => {
      const filter_tags = [];
      for (const [key, value] of Object.entries(tags)) {
        if (value === 0 || value == null) continue;
        filter_tags.push(Number(key));
      }
      const sSort = localStorage.getItem("setting/sort") ?? "画像タイトル";
      const sort =
        Object.keys(SortItem)[
          (Object.values(SortItem) as string[]).indexOf(sSort)
        ].toUpperCase();

      const sOrder = localStorage.getItem("setting/order") ?? "降順";
      const order =
        Object.values(Order)[
          (Object.keys(Order) as string[]).indexOf(sOrder)
        ].toUpperCase();

      const data = {
        client_id: client_id,
        response_limit: localStorage.getItem("setting/limit") ?? 20000,
        sort: sort,
        order: order,
        search_word: searchWord,
        search_logical_operation: searchLogicalOperation,
        client_filter: clientFilter,
        details: details === undefined ? {} : details,
        tags: filter_tags,
      };
      const config = { withCredentials: true };

      axios
        .post<ApiResponse2>(
          `${process.env.REACT_APP_API_URL}/api/search/select`,
          data,
          config
        )
        .then((res) => {
          const ids = res.data.data["ids"] as number[];
          setSelectedItem([]);
          setFocusItem(null);
          setSearchData(
            (searchData ?? []).filter((data) => !ids.includes(data.id))
          );
        })
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            if (e.response.status === 400) {
              alert(e.response.data.message);
            } else {
              setError(e.response.data);
            }
          } else {
            setError(e.message);
          }
        });
    },
    [client_id, searchData]
  );

  const imageCount = useCallback(
    async (
      clientId: string | number
    ): Promise<{ count: number; client_count: number }> => {
      const config = { withCredentials: true };
      const { data } = await axios.get<CountResponse>(
        `${process.env.REACT_APP_API_URL}/api/images/count?cid=${clientId}`,
        config
      );

      return data.data;
    },
    []
  );

  return { loading, search, select, exclude, imageCount };
};
