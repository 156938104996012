import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { AxiosError } from "axios";

import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import LeftButton from "../../../atoms/LeftButton";
import RightButton from "../../../atoms/RightButton";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { ApiError, Client } from "../../../../types/types";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useClient } from "../../../../hooks/useClient";
import { useError } from "../../../../hooks/useError";
import { useSearchStateContext } from "../../../../context/SearchStateProvider";
import Table from "../../../molecules/Table";
import TableHeader from "../../../atoms/TableHeader";
import TableRow from "../../../atoms/TableRow";
import TableHeaderCell from "../../../atoms/TableHeaderCell";
import TableBody from "../../../atoms/TableBody";
import TableDataCell from "../../../atoms/TableDataCell";
import { useUsesClient } from "../../../../hooks/useUsesClient";
import { useKeyStateContext } from "../../../../context/KeyStateProvider";

const PatternSelectModal: FC = () => {
  const [clients, setClients] = useState<Client[] | null>(null);

  const [registerClient, setRegisterClient] = useState<Client | null>(null);

  // 使用クライアント・未使用クライアントのそれぞれのIDを格納
  const [selectedUseClient, setSelectedUseClient] = useState<number[]>([]);
  const [selectedNotUseClient, setSelectedNotUseClient] = useState<number[]>(
    []
  );

  // テーブルのカラムの幅の状態
  const [useClientSymbolWidth, setUseClientSymbolWidth] =
    useState<string>("20%");
  const [useClientWidth, setUseClientWidth] = useState<string>("80%");
  const [notUseClientSymbolWidth, setNotUseClientSymbolWidth] =
    useState<string>("20%");
  const [notUseClientWidth, setNotUseClientWidth] = useState<string>("80%");

  const { modal, setModal } = useModalStateContext();

  const { selectedItem, focusItem } = useSelectedItemStateContext();
  const [usedClient, setUsedClient] = useState<number[]>([]);

  const { keyPress } = useKeyStateContext();

  const { searchData } = useSearchStateContext();
  const { getSharePatternClientsById, getAllClients } = useClient();
  const { setError } = useError();
  const { get, post } = useUsesClient();

  const isPickSelectMode = () => {
    return keyPress.keyPress.get("Control") || keyPress.keyPress.get("Meta");
  };

  useLayoutEffect(() => {
    (async () => {
      if (selectedItem.length >= 1) {
        try {
          if (focusItem === null) return;

          const selectItem = searchData[focusItem];

          const clients = await getSharePatternClientsById(
            selectItem.share_pattern_id
          );
          const allClients = await getAllClients();

          const ids =
            searchData
              ?.filter((data) => selectedItem.includes(data.id))
              .map((data) => data.master_client_id) ?? [];
          const uniqueIds = Array.from(new Set(ids));
          const client = allClients?.filter((c) => uniqueIds.includes(c.id));

          const usedClients = await get(selectItem.id);

          setUsedClient(usedClients.map((client) => client.id));
          setClients(clients);
          setRegisterClient(client ? client[0] : null);
        } catch (e) {
          if ((e as AxiosError<ApiError>).response) {
            setError(e.response.data);
          } else {
            setError(e);
          }
        }
      }
    })();
  }, []);

  const handleOnClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, patternSelectModal: false };
    });
  }, []);

  const handleOnSave = useCallback(() => {
    if (focusItem === null) return;
    const selectId = searchData[focusItem].id;
    if (confirm("設定内容を保存しますか？")) {
      post(selectId, usedClient);
    } else {
      return;
    }

    alert("保存が完了しました");
    handleOnClose();
  }, [focusItem, selectedItem, usedClient]);

  const onClickUseClient = useCallback(
    (id: number) => {
      const isPick = isPickSelectMode();
      if (isPick) {
        selectedUseClient.includes(id)
          ? setSelectedUseClient(selectedUseClient.filter((n) => n !== id))
          : setSelectedUseClient([...selectedUseClient, id]);
      } else {
        setSelectedUseClient([id]);
      }
    },
    [selectedUseClient]
  );

  const onClickNotUseClient = useCallback(
    (id: number) => {
      const isPick = isPickSelectMode();
      if (isPick) {
        selectedNotUseClient.includes(id)
          ? setSelectedNotUseClient(
              selectedNotUseClient.filter((n) => n !== id)
            )
          : setSelectedNotUseClient([...selectedNotUseClient, id]);
      } else {
        setSelectedNotUseClient([id]);
      }
    },
    [selectedNotUseClient]
  );

  const onClickRight = useCallback(() => {
    const filteredClients = usedClient.filter(
      (clientId) => !selectedUseClient.includes(clientId)
    );
    setUsedClient(filteredClients);
    setSelectedUseClient([]);
  }, [usedClient, selectedUseClient]);

  const onClickLeft = useCallback(() => {
    setUsedClient([...usedClient, ...selectedNotUseClient]);
    setSelectedNotUseClient([]);
  }, [usedClient, selectedNotUseClient]);

  return (
    <Modal
      isOpen={modal.patternSelectModal}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay>
        <ModalContent
          style={{
            height: "calc(100vh - 50px)",
            minHeight: "200px",
            maxWidth: "900px",
          }}
          m={0}
        >
          <ModalHeader className="flex flex-row">
            <Label className="font-bold text-18px" spacing="0 0.25rem">
              使用クライアント変更
            </Label>
            <Label
              className="font-normal inline-flex items-center text-12px"
              spacing="0.3rem"
            >
              {[
                "登録クライアント",
                registerClient !== null
                  ? `${registerClient?.short_name} - ${registerClient?.name}`
                  : "",
              ].join("　")}
            </Label>
          </ModalHeader>
          <ModalBody className="flex flex-1">
            <div className="flex flex-col flex-1 w-400px">
              <Label className="text-12px pb-1">
                選択画像を使用（検索で表示）するクライアント
              </Label>
              <Table className="flex flex-col h-full border border-gray-line font-sans font-normal">
                <TableHeader className="flex block w-full bg-gray-background text-12px border-b border-gray-line">
                  <TableRow className="flex w-full">
                    <Rnd
                      style={{
                        position: "static",
                        transform: "translate(0%, 0%)",
                      }}
                      enableResizing={{ right: true }}
                      disableDragging={true}
                      default={{
                        x: 0,
                        y: 0,
                        width: useClientSymbolWidth,
                        height: "auto",
                      }}
                      minWidth="15%"
                      maxWidth="65%"
                      onResize={(e, direction, ref) => {
                        setUseClientSymbolWidth(ref.style.width);
                        const clientWidth =
                          100 - parseFloat(ref.style.width.replace("%", ""));
                        setUseClientWidth(`${clientWidth}%`);
                      }}
                    >
                      <TableDataCell className="w-full inline-flex items-center justify-center p-2">
                        記号
                      </TableDataCell>
                    </Rnd>
                    <TableDataCell
                      className="p-2 border-l border-gray-line"
                      style={{ width: useClientWidth }}
                    >
                      クライアント
                    </TableDataCell>
                  </TableRow>
                </TableHeader>
                <TableBody className="flex flex-col flex-grow block w-full font-sans overflow-y-auto h-0">
                  {(clients ?? [])
                    .filter((client) => usedClient.includes(client.id))
                    .map((value) => (
                      <TableRow
                        className={`flex w-full text-10px ${
                          selectedUseClient.includes(value.id)
                            ? "bg-green-teal text-white bg-opacity-80"
                            : "hover:bg-green-teal hover:bg-opacity-10"
                        }`}
                        onClick={() => onClickUseClient(value.id)}
                        key={value.id}
                      >
                        <TableDataCell
                          className="p-1 truncate inline-flex justify-center"
                          style={{ width: useClientSymbolWidth }}
                        >
                          {value.short_name}
                        </TableDataCell>
                        <TableDataCell
                          className="p-1 pl-2 truncate"
                          style={{ width: useClientWidth }}
                        >
                          {value.name}
                        </TableDataCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
            <div className="flex flex-col justify-center items-center mx-1">
              <div className="flex flex-col justify-around h-140px">
                <LeftButton onClick={onClickLeft} />
                <RightButton onClick={onClickRight} />
              </div>
            </div>
            <div className="flex flex-col flex-1 w-400px">
              <Label className="text-12px pb-1">
                選択画像を使用（検索で表示）しないクライアント
              </Label>
              <Table className="flex flex-col h-full border border-gray-line font-sans font-normal">
                <TableHeader className="flex block w-full bg-gray-background text-12px border-b border-gray-line">
                  <TableRow className="flex w-full">
                    <Rnd
                      style={{
                        position: "static",
                        transform: "translate(0%, 0%)",
                      }}
                      enableResizing={{ right: true }}
                      disableDragging={true}
                      default={{
                        x: 0,
                        y: 0,
                        width: notUseClientSymbolWidth,
                        height: "auto",
                      }}
                      minWidth="15%"
                      maxWidth="65%"
                      onResize={(e, direction, ref) => {
                        setNotUseClientSymbolWidth(ref.style.width);
                        const clientWidth =
                          100 - parseFloat(ref.style.width.replace("%", ""));
                        setNotUseClientWidth(`${clientWidth}%`);
                      }}
                    >
                      <TableHeaderCell className="w-full inline-flex items-center justify-center p-2">
                        記号
                      </TableHeaderCell>
                    </Rnd>
                    <TableHeader
                      className="inline-flex items-center p-2 border-l border-gray-line"
                      style={{ width: notUseClientWidth }}
                    >
                      クライアント
                    </TableHeader>
                  </TableRow>
                </TableHeader>
                <TableBody className="flex flex-col flex-grow block w-full overflow-y-auto h-0">
                  {(clients ?? [])
                    .filter((client) => !usedClient.includes(client.id))
                    .map((value) => (
                      <TableRow
                        className={`flex w-full text-10px ${
                          selectedNotUseClient.includes(value.id)
                            ? "bg-green-teal text-white bg-opacity-80"
                            : "hover:bg-green-teal hover:bg-opacity-10"
                        }`}
                        onClick={() => onClickNotUseClient(value.id)}
                        key={value.id}
                      >
                        <TableDataCell
                          className="p-1 truncate"
                          style={{ width: notUseClientSymbolWidth }}
                        >
                          {value.short_name}
                        </TableDataCell>
                        <TableDataCell
                          className="p-1 pl-2 truncate"
                          style={{ width: notUseClientWidth }}
                        >
                          {value.name}
                        </TableDataCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter className="flex flex-row items-end">
            <Button
              width="100px"
              primary={false}
              size="small"
              onClick={handleOnClose}
            >
              キャンセル
            </Button>
            <Button
              width="100px"
              primary={true}
              size="small"
              onClick={handleOnSave}
            >
              保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default PatternSelectModal;
