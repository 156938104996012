import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { useAuthContext } from "../context/AuthProvider";
import { useError } from "./useError";
import { useSearchStateContext } from "../context/SearchStateProvider";

interface ResponseData {
  username: string;
  permission: string[];
  sessions: {
    common: unknown;
    client: unknown;
  };
}

interface AuthApiResponse {
  status: number;
  type: string;
  data_format: string;
  data: Array<Omit<ResponseData, "sessions">>;
}

export const useLogin = (): {
  login: (username: string, password: string) => void;
  error: boolean;
  loading: boolean;
} => {
  const [error, setIsError] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { setUser, setIsLogin } = useAuthContext();
  const { setSearchData, setIsSearched } = useSearchStateContext();
  const { deleteError } = useError();

  const config = { withCredentials: true };

  const login = useCallback(
    async (username: string, password: string) => {
      const data = { username: username, password: password };

      setIsLoading(true);

      await axios
        .post<AuthApiResponse>(
          `${process.env.REACT_APP_API_URL}/auth`,
          data,
          config
        )
        .then((res) => {
          // 各種データリセット
          deleteError();
          setUser({
            username: res.data.data[0].username,
            permission: res.data.data[0].permission,
          });
          setSearchData([]);
          setIsSearched(false);

          setIsLogin(true);
          history.push("/clients");
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    },
    [history]
  );

  return { login, error, loading };
};
