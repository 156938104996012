import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { useHotkeys } from "react-hotkeys-hook";

import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import Table from "../../../molecules/Table";
import TableHeader from "../../../atoms/TableHeader";
import TableRow from "../../../atoms/TableRow";
import TableBody from "../../../atoms/TableBody";
import TableDataCell from "../../../atoms/TableDataCell";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import {
  ApiError,
  ImageBoxImageDetail,
  SharePattern,
} from "../../../../types/types";
import { useSharePattern } from "../../../../hooks/useSharePattern";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useError } from "../../../../hooks/useError";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useImageDetails } from "../../../../hooks/useImageDetails";

const SharePatternSelectModal: FC = () => {
  const [sharePatternList, setSharePatternList] = useState<
    SharePattern[] | null
  >(null);

  const [imageData, setImageData] = useState<ImageBoxImageDetail>();

  // セル幅の状態
  const [idWidth, setIdWidth] = useState<string>("25%");
  const [nameWidth, setNameWidth] = useState<string>("75%");

  // 選択したセル
  const [selectColumn, setSelectColumn] = useState<number | null>(null);

  const { selectedItem } = useSelectedItemStateContext();
  const { client_id } = useParams<{ client_id: string }>();

  const { modal, setModal } = useModalStateContext();
  const { getSharePatternByClientId, updateSharePattern } = useSharePattern();
  const { get } = useImageDetails();
  const { setError } = useError();

  const onClickClient = (column: number) => setSelectColumn(column);

  const handleOnClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, sharePatternSelectModal: false };
    });
  }, []);

  const handleOnSave = useCallback(() => {
    if (sharePatternList !== null && selectColumn !== null) {
      const imageIds = [selectedItem[0]];
      const sharePatternId = sharePatternList[selectColumn].id;

      if (
        confirm(
          `${sharePatternList[selectColumn].name}に使用権を変更しますか？`
        )
      ) {
        updateSharePattern(imageIds, sharePatternId);
        alert("使用権を変更しました。");
        handleOnClose();
      }
    } else if (selectColumn === null) {
      alert("使用権を選択してください。");
    }
  }, [sharePatternList, selectColumn]);

  useEffect(() => {
    (async () => {
      try {
        const data = await get(selectedItem[0], client_id);
        setImageData(data);

        const sharePattern = await getSharePatternByClientId(
          data.master_client_id
        );
        setSharePatternList(sharePattern);
      } catch (e) {
        const response = (e as AxiosError<ApiError>).response;
        if (response) {
          setError(response.data);
        } else {
          setError(e);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (sharePatternList !== null && imageData !== undefined) {
      sharePatternList.map((sharePattern, index) => {
        if (sharePattern.id === imageData.share_pattern_id) {
          setSelectColumn(index);
        }
      });
    }
  }, [sharePatternList, imageData]);

  useHotkeys(
    "Down",
    (e) => {
      e?.preventDefault();
      if (sharePatternList === null) return;
      if (
        selectColumn === null ||
        selectColumn === sharePatternList?.length - 1
      )
        return;
      setSelectColumn(selectColumn + 1);
    },
    [selectColumn, setSelectColumn, sharePatternList]
  );

  useHotkeys(
    "Up",
    (e) => {
      e?.preventDefault();
      if (sharePatternList === null) return;
      if (selectColumn === null || selectColumn === 0) return;
      setSelectColumn(selectColumn - 1);
    },
    [setSelectColumn, selectColumn, sharePatternList]
  );

  useHotkeys(
    "Enter",
    (e) => {
      e?.preventDefault();
      handleOnSave();
    },
    [handleOnSave]
  );

  return (
    <Modal
      isOpen={modal.sharePatternSelectModal}
      onClose={handleOnClose}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent
        style={{ height: "calc(100vh - 50px)", minHeight: "200px" }}
        m={0}
      >
        <ModalHeader>
          <Label className="font-bold text-18px" spacing="0 0.25rem">
            使用権変更
          </Label>
        </ModalHeader>
        <ModalBody className="flex flex-1 my-auto bottom-0">
          <Table className="full-width flex flex-grow flex-col border border-gray-line font-normal m-1">
            <TableHeader className="flex block w-full bg-gray-background text-12px border-b border-gray-line">
              <TableRow className="flex w-full">
                <Rnd
                  style={{ position: "static", transform: "translate(0%, 0%)" }}
                  enableResizing={{ right: true }}
                  disableDragging={true}
                  default={{ x: 0, y: 0, width: idWidth, height: "auto" }}
                  minWidth="10%"
                  maxWidth="60%"
                  onResize={(e, direction, ref) => {
                    setIdWidth(ref.style.width);
                    const propertyNameWidth =
                      100 - parseFloat(ref.style.width.replace("%", ""));
                    setNameWidth(`${propertyNameWidth}%`);
                  }}
                >
                  <TableDataCell className="w-full inline-flex items-center p-2 inline-flex justify-center">
                    ID
                  </TableDataCell>
                </Rnd>
                <TableDataCell
                  className="inline-flex items-center p-2 border-l border-gray-line"
                  style={{
                    width: nameWidth,
                  }}
                >
                  使用権名
                </TableDataCell>
              </TableRow>
            </TableHeader>
            <TableBody className="flex flex-col flex-grow block w-full font-sans overflow-y-auto h-0">
              {sharePatternList?.map((value, index) => (
                <TableRow
                  className={`flex w-full text-10px ${
                    selectColumn === index
                      ? "bg-green-teal text-white bg-opacity-80"
                      : "hover:bg-green-teal hover:bg-opacity-10"
                  }`}
                  onClick={() => onClickClient(index)}
                  key={value.id}
                  isFocus={selectColumn === index}
                >
                  <TableDataCell
                    className="py-1 px-2 truncate inline-flex justify-center"
                    style={{ width: idWidth }}
                  >
                    {value.id}
                  </TableDataCell>
                  <TableDataCell
                    className="py-1 px-2 truncate"
                    style={{ width: nameWidth }}
                  >
                    {value.name}
                  </TableDataCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter className="flex flex-row items-end">
          <Button
            width="100px"
            primary={false}
            size="small"
            onClick={handleOnClose}
          >
            キャンセル
          </Button>
          <Button
            width="100px"
            primary={true}
            size="small"
            onClick={handleOnSave}
          >
            保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SharePatternSelectModal;
