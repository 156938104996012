import React, { FC, memo, ChangeEvent } from "react";

import Radio from "../../atoms/Radio";

interface Props {
  id: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioText: FC<Props> = ({ id, label, value, checked, onChange }) => {
  return (
    <div className="flex flex-row items-center">
      <Radio id={id} value={value} checked={checked} onChange={onChange} />
      <label className="text-14px text-14px mx-1 cursor-pointer" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default memo(RadioText);
