import React, { FC, useMemo, useState, useLayoutEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { ImageBoxImageDetail, Mode, Client } from "../../types/types";
import Page403 from "./Page403";
import Page404 from "./Page404";
import ImageDetailTemp from "../templates/ImageDetailTemp";
import { useImageDetails } from "../../hooks/useImageDetails";
import ErrorDialog from "../organisms/Modal/ErrorDialog";
import { useClient } from "../../hooks/useClient";
import { useAuthContext } from "../../context/AuthProvider";

const ImageDetail: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [errorSt, setErrorSt] = useState<number>();

  const [imageData, setImageData] = useState<ImageBoxImageDetail>();
  const [client, setClient] = useState<Client>();

  const [mode, setMode] = useState<Mode>();
  const [reload, setReload] = useState<boolean>(true);
  const { getClient } = useClient();
  const { user } = useAuthContext();

  const { client_id, image_id } =
    useParams<{ client_id: string; image_id: string }>();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const { get } = useImageDetails();

  // クエリパラメータの制御
  useLayoutEffect(() => {
    const value = params.get("mode");
    const setting = localStorage.getItem("detail/display");

    if (value !== null && ["single", "multi", "edit"].includes(value)) {
      if (
        value === "edit" &&
        user !== undefined &&
        !user?.permission?.includes("ImageAdmin")
      ) {
        alert("編集権限がないため参照モードに移行します。");

        if (setting === null || !["multi", "single"].includes(setting)) {
          history.push(`${location.pathname}?mode=single`);
          localStorage.setItem("detail/display", "single");
        } else {
          history.push(`${location.pathname}?mode=${setting}`);
        }
        return;
      }

      setMode(value as Mode);
      return;
    }
    history.push(`${location.pathname}?mode=${mode ?? "single"}`);
  }, [params, user]);

  useLayoutEffect(() => {
    getClient(client_id)
      .then((data) => {
        setClient(data);
      })
      .catch((res) => {
        setErrorSt(res.status);
      });
  }, [params]);

  useLayoutEffect(() => {
    get(image_id, client_id)
      .then((data) => {
        setImageData(data);
      })
      .catch((res) => {
        setErrorSt(res.status);
      })
      .finally(() => {
        setReload(false);
      });
  }, [reload]);

  // APIエラー時
  if (errorSt !== undefined) {
    switch (errorSt) {
      case 403:
        return <Page403 />;
      case 404:
        return <Page404 />;
    }
  }

  const isIncludes = (arr: string[] | undefined, target: string[]): boolean => {
    if (arr === undefined) return false;
    return arr.some((el) => target.includes(el));
  };

  if (
    user !== undefined &&
    !isIncludes(user?.permission, ["ImageAdmin", "Browser"])
  ) {
    return <Page403 />;
  }

  return (
    <>
      <ImageDetailTemp
        imageData={imageData}
        mode={mode}
        client={client}
        onReload={setReload.bind(null, true)}
      />
      <ErrorDialog />
    </>
  );
};

export default ImageDetail;
