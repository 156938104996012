export enum SortItem {
  TITLE = "画像タイトル",
  IMAGE_CODE = "画像ID",
  MAKER = "メーカー",
  LOCALITY = "産地",
  PRODUCT_NAME = "品名・イメージ名１",
  PRODUCT_NAME2 = "品名・イメージ名２",
  SPEC = "規格・商品詳細",
  PRODUCT_FILENAME = "品名ファイル名",
  JAN_CODE = "ＪＡＮコード",
  PLAN_END_DATE = "企画終了日",
  NOTE = "備考",
  PRICE = "参考価格",
  USE_START_DATE = "利用開始日",
  OFFERING_DATE = "売出日",
  VARIOUS_CODE = "各種コード",
  SUPPLIER_NAME = "支給元名",
  BASE_IMAGE_ID = "ベース画像ID",
  NEW_OLD_TYPE = "新旧区分",
  COLOR = "ラベル",
  BASE_IMAGE_CHANGE = "ベース画像変更点",
  SHARE_PATTERN_ID = "使用権画像区分ID",
  CREATE_DATE = "システム登録日時",
  UPDATE_DATE = "システム更新日時",
  ORIGINAL_FILE_CREATION = "ファイル作成日時",
  ORIGINAL_FILE_LAST_MODIFY = "ファイル更新日時",
  ORIGINAL_FILE_SIZE = "ファイルサイズ",
}
