import { useCallback } from "react";

import { useErrorStateContext } from "../context/ErrorStateProvider";
import { ApiError } from "../types/types";
import { useDate } from "./useDate";

export const useError = (): {
  setError: (data: ApiError) => void;
  deleteError: () => void;
} => {
  const { setIsError, setErrorMessage } = useErrorStateContext();
  const { formatDate } = useDate();

  const setError = useCallback(
    (data: ApiError): void => {
      const formattedDate = formatDate(data.timestamp, "YYYY/MM/DD hh:mm:ss");
      const errorMessage = [
        `${formattedDate}   ${data.message ?? ""}`,
        `${data.trace_message ?? ""}`,
      ].join("\n");
      setErrorMessage(errorMessage);
      setIsError(true);
    },
    [setIsError, setErrorMessage]
  );

  const deleteError = useCallback((): void => {
    setErrorMessage(undefined);
    setIsError(false);
  }, [setIsError, setErrorMessage]);

  return { setError, deleteError };
};
