import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

interface ErrorStateContextType {
  error: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  errorMessage: string | undefined;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

const ErrorStateContext = createContext({} as ErrorStateContextType);

export const ErrorStateProvider: FC = ({ children }) => {
  const [error, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  return (
    <ErrorStateContext.Provider
      value={{
        error,
        setIsError,
        errorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </ErrorStateContext.Provider>
  );
};

export const useErrorStateContext = (): ErrorStateContextType =>
  useContext(ErrorStateContext);
