import React, { memo, FC, TextareaHTMLAttributes } from "react";
import { Property } from "csstype";

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  bg?: "white" | "skyblue";
  spacing?: Property.Margin;
}

const Textarea: FC<Props> = ({
  className = "",
  bg = "white",
  spacing,
  rows = 3,
  ...props
}) => {
  const defaultClassName =
    "rounded-md resize-none border border-gray-line overflow-y-auto leading-normal p-0";
  const backgroundColor = bg === "skyblue" ? "bg-blue-skyblue" : "bg-white";

  return (
    <textarea
      className={[defaultClassName, className, backgroundColor].join(" ")}
      {...props}
      spellCheck={false}
      style={{ margin: spacing }}
      rows={rows}
    />
  );
};

export default memo(Textarea);
