import React, { FC, useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { useParams, useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { useClient } from "../../../../hooks/useClient";
import Table from "../../../molecules/Table";
import TableHeader from "../../../atoms/TableHeader";
import TableRow from "../../../atoms/TableRow";
import TableBody from "../../../atoms/TableBody";
import TableDataCell from "../../../atoms/TableDataCell";
import { AxiosError } from "axios";
import { ApiError } from "../../../../types/types";
import { useError } from "../../../../hooks/useError";

import { Client } from "../../../../types/types";
import { useAuthContext } from "../../../../context/AuthProvider";
import { useStateContext } from "../../../../context/StateProvider";
import TableHeaderCell from "../../../atoms/TableHeaderCell";

const ClientSelectModal: FC = () => {
  const [clientId, setClientId] = useState<number | null>(null);
  const [clients, setClients] = useState<Client[] | null>(null);

  const [focus, setFocus] = useState<number | null>(null);
  const [symbolWidth, setSymbolWidth] = useState<string>("20%");
  const [clientWidth, setClientWidth] = useState<string>("80%");
  const params = useParams<{ client_id: string }>();
  const history = useHistory();

  const { modal, setModal } = useModalStateContext();
  const { clientSelectModal } = modal;

  const { getAllClients } = useClient();
  const { setError } = useError();
  const { isLogin } = useAuthContext();
  const { openSearch } = useStateContext();

  const onClickClient = useCallback(
    (id: number, index: number) => {
      clientId !== id ? setClientId(id) : setClientId(null);
      setFocus(index);
    },
    [clientId]
  );

  const onClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, clientSelectModal: false };
    });
    setClientId(null);
  }, []);

  const onClickOpen = useCallback(() => {
    if (clientId === null) {
      alert("クライアントを選択してください");
      return;
    }
    const selectedClient = clients?.filter((client) => {
      return client.id === clientId;
    });

    if (selectedClient === undefined) return;

    setModal((prevState) => {
      return { ...prevState, clientSelectModal: false };
    });

    if (params.client_id === undefined || !openSearch) {
      history.push(`/clients/${clientId}/search`);
    } else {
      const target =
        "imagebox/" + clientId + "?key=" + Math.random().toString(32);
      window.open(
        `${process.env.REACT_APP_URL}/clients/${clientId}/search`,
        target
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, openSearch]);

  useHotkeys(
    "Down",
    (e) => {
      e?.preventDefault();
      if (
        clientId === null ||
        focus === null ||
        clients === null ||
        focus === clients?.length - 1
      )
        return;
      setClientId(clients[focus + 1].id);
      setFocus(focus + 1);
    },
    [clientId, focus, clients]
  );

  useHotkeys(
    "Up",
    (e) => {
      e?.preventDefault();
      if (
        clientId === null ||
        focus === null ||
        clients === null ||
        focus === 0
      )
        return;
      setClientId(clients[focus - 1].id);
      setFocus(focus - 1);
    },
    [clientId, focus, clients]
  );

  useHotkeys(
    "Enter",
    (e) => {
      e?.preventDefault();
      onClickOpen();
    },
    [onClickOpen]
  );

  useHotkeys(
    "*",
    (e) => {
      if (clients === null || e.code.indexOf("Key") === -1) return;
      e.preventDefault();

      for (let i = 0; i < clients.length; i++) {
        if (clients[i].short_name[0] === e.code.replace("Key", "")) {
          if (clients[i].id === clientId) break;

          setClientId(clients[i].id);
          setFocus(i);
          break;
        }
      }
    },
    [clientId, clients]
  );

  useEffect(() => {
    (async () => {
      try {
        if (!isLogin) return;
        // 全クライアント情報の取得
        const clients = await getAllClients();
        setClients(clients);
      } catch (e) {
        const response = (e as AxiosError<ApiError>).response;
        if (response) {
          setError(response.data);
        } else {
          setError(e);
        }
      }
    })();
  }, [isLogin]);

  return (
    <Modal
      isOpen={clientSelectModal}
      onClose={onClose}
      isCentered
      motionPreset={"none"}
      closeOnOverlayClick={params.client_id !== undefined}
    >
      <ModalOverlay />
      <ModalContent
        style={{ height: "calc(100vh - 50px)", minHeight: "200px" }}
        animation="none"
        m={0}
      >
        <ModalHeader>
          <Label className="font-bold text-18px" spacing="0">
            クライアントを選択
          </Label>
        </ModalHeader>
        <ModalBody className="flex flex-1 mt-auto bottom-0">
          <Table className="full-width flex flex-grow flex-col border border-gray-line font-normal min-h-100px">
            <TableHeader className="flex block w-full bg-gray-background text-12px border-b border-gray-line">
              <TableRow className="flex w-full">
                <Rnd
                  style={{ position: "static", transform: "translate(0%, 0%)" }}
                  enableResizing={{ right: true }}
                  disableDragging={true}
                  default={{ x: 0, y: 0, width: symbolWidth, height: "auto" }}
                  minWidth="15%"
                  maxWidth="50%"
                  onResize={(e, direction, ref) => {
                    setSymbolWidth(ref.style.width);
                    const clientWidth =
                      100 - parseFloat(ref.style.width.replace("%", ""));
                    setClientWidth(`${clientWidth}%`);
                  }}
                >
                  <TableHeaderCell className="w-full inline-flex items-center justify-center p-2">
                    記号
                  </TableHeaderCell>
                </Rnd>
                <TableHeaderCell
                  className="p-2 border-l border-gray-border"
                  style={{ width: clientWidth }}
                >
                  クライアント
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody className="flex flex-col flex-grow block w-full font-sans overflow-y-auto h-0">
              {clients?.map((client, index) => (
                <TableRow
                  className={`flex w-full text-10px ${
                    clientId === client.id
                      ? "bg-green-teal text-white bg-opacity-80"
                      : "hover:bg-green-teal hover:bg-opacity-10"
                  }`}
                  onClick={() => onClickClient(client.id, index)}
                  key={client.id}
                  isFocus={focus === index}
                >
                  <TableDataCell
                    className="p-1 w-1/5 truncate inline-flex justify-center"
                    style={{ width: symbolWidth }}
                    key={client.id}
                  >
                    {client.short_name}
                  </TableDataCell>
                  <TableDataCell
                    className="p-1 pl-2 truncate"
                    style={{ width: clientWidth }}
                  >
                    {client.name}
                  </TableDataCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter className="inline-flex justify-end">
          <Button
            width="125px"
            primary={true}
            size="small"
            onClick={onClickOpen}
          >
            クライアントを開く
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClientSelectModal;
