import { useCallback } from "react";

import { useModalStateContext } from "../context/ModalStateProvider";

export const useModal = (): { isOpenModal: () => boolean } => {
  const { modal } = useModalStateContext();

  const isOpenModal = useCallback(() => {
    if (
      modal.settingModal ||
      modal.tagSelectModal ||
      modal.bulkEditModal ||
      modal.passwordModal ||
      modal.clientSelectModal ||
      modal.sharePatternSelectModal ||
      modal.patternSelectModal
    )
      return true;

    return false;
  }, [modal]);

  return { isOpenModal };
};
