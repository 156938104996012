import React, {
  FC,
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";

interface Modal {
  settingModal: boolean;
  clientSelectModal: boolean;
  sharePatternSelectModal: boolean;
  patternSelectModal: boolean;
  tagSelectModal: boolean;
  bulkEditModal: boolean;
  passwordModal: boolean;
  tokenModal: boolean;
  tokenResultModal: boolean;
  tokenResultDataIP: string|null;
  tokenResultDataToken: string|null;
}

interface ModalStateContextType {
  modal: Modal;
  setModal: Dispatch<SetStateAction<Modal>>;
}

const InitialModalState: Modal = {
  settingModal: false,
  clientSelectModal: false,
  sharePatternSelectModal: false,
  patternSelectModal: false,
  tagSelectModal: false,
  bulkEditModal: false,
  passwordModal: false,
  tokenModal: false,
  tokenResultModal: false,
  tokenResultDataIP: null,
  tokenResultDataToken: null,
};

const ModalStateContext = createContext({} as ModalStateContextType);

export const ModalStateProvider: FC = ({ children }) => {
  const [modal, setModal] = useState<Modal>(InitialModalState);

  return (
    <ModalStateContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalStateContext.Provider>
  );
};

export const useModalStateContext = (): ModalStateContextType =>
  useContext(ModalStateContext);
