import React, { FC } from "react";
import Button from "../../atoms/Button";
import { useSubWindowStateContext } from "../../../context/SubWindowStateProvider";

const DetailActivateButton: FC = () => {
  const { windowObjectReferences, setWindowObjectReferences } =
    useSubWindowStateContext();

  const handleOnActivate = () => {
    if (windowObjectReferences.length !== 0) {
      windowObjectReferences
        .filter((obj) => obj.window !== null)
        .map((obj) => obj.focus());
    }
    const filteredWinObjRef = windowObjectReferences.filter((obj) => {
      return obj.window !== null;
    });
    setWindowObjectReferences(filteredWinObjRef);
  };

  return (
    <Button
      className={"border hover:bg-gray-background"}
      size={"small"}
      onClick={handleOnActivate}
      width={"70px"}
    >
      {`詳細Act`}
    </Button>
  );
};

export default DetailActivateButton;
