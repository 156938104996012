import React, { memo, FC, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../components/pages/Login";
import Page403 from "../components/pages/Page403";
import Page404 from "../components/pages/Page404";
import AuthRouter from "./AuthRouter";
import { useKeyStateContext } from "../context/KeyStateProvider";

const Router: FC = () => {
  const { keyPress, setKeyPress } = useKeyStateContext();
  const handleKeyDown = (event: KeyboardEvent) =>
    setKeyPress({ keyPress: keyPress.keyPress.set(event.key, true) });
  const handleKeyUp = (event: KeyboardEvent) =>
    setKeyPress({ keyPress: keyPress.keyPress.set(event.key, false) });

  const removeKeyState = () => {
    keyPress.keyPress.forEach((_, key) => {
      setKeyPress({ keyPress: keyPress.keyPress.set(key, false) });
    });
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    document.addEventListener("keyup", handleKeyUp, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
      document.removeEventListener("keyup", handleKeyUp, false);
    };
  });

  useEffect(() => {
    window.addEventListener("blur", removeKeyState, false);

    return () => {
      window.removeEventListener("blur", removeKeyState, false);
    };
  }, []);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/403" component={Page403} />
      <Route exact path="/404" component={Page404} />
      <AuthRouter />
    </Switch>
  );
};

export default memo(Router);
