import React, { FC } from "react";
import CheckBoxText from "../CheckBoxText";

type Action = "register" | "update";

interface Props {
  action: Action;
  onChangeAction: (action: Action) => void;
  isRegenerate: boolean;
  onChangeRegenerateFlag: (flag: boolean) => void;
}

const SelectUploadAction: FC<Props> = (props) => {
  return (
    <div className="flex flex-row">
      <CheckBoxText
        id="INSERT"
        label={"新規登録"}
        checked={props.action === "register"}
        onChange={() => props.onChangeAction("register")}
      />
      <CheckBoxText
        id="UPDATE1"
        label={"上書き登録（サムネイル再作成あり）"}
        checked={props.action === "update" && props.isRegenerate}
        onChange={() => {
          props.onChangeAction("update");
          props.onChangeRegenerateFlag(true);
        }}
      />
      <CheckBoxText
        id="UPDATE2"
        label={"上書き登録（サムネイル再作成なし）"}
        checked={props.action === "update" && !props.isRegenerate}
        onChange={() => {
          props.onChangeAction("update");
          props.onChangeRegenerateFlag(false);
        }}
      />
    </div>
  );
};

export default SelectUploadAction;
