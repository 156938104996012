import { Property } from "csstype";
import { useSubWindowStateContext } from "../context/SubWindowStateProvider";
import { useCallback } from "react";

interface UseDetailWindowProp {
  showDetailWindows: (url: string, client_id?: string) => void;
}

export const useDetailWindow = (): UseDetailWindowProp => {
  const { windowObjectReferences, setWindowObjectReferences } =
    useSubWindowStateContext();

  const showSubWindow = useCallback(
    (
      url: string,
      width?: Property.Width,
      height?: Property.Height,
      target?: string
    ) => {
      const filteredWindowObject = windowObjectReferences.filter(
        (windowObjRef) => {
          return (
            window.window !== null && String(windowObjRef.location.href) === url
          );
        }
      );

      if (filteredWindowObject.length === 0) {
        const windowReference = window.open(
          url,
          target,
          `${width && `width=${width},`}${height && `height=${height},`}`
        );
        if (windowReference !== null)
          setWindowObjectReferences((prevState) => {
            const existWindowObj = prevState.filter((obj) => {
              return obj.window !== null;
            });
            return [...existWindowObj, windowReference];
          });
      } else {
        filteredWindowObject.map((obj) => {
          obj.focus();
        });
      }
    },
    [windowObjectReferences]
  );

  /**
   * @param url URL
   * @param client_id クライアントID
   * @param index_id IndexID
   */
  const showDetailWindows = useCallback(
    (url: string, client_id?: string) => {
      const target =
        "imagebox/" + client_id + "/detail?key=" + Math.random().toString(32);
      showSubWindow(url, "520px", "850px", target);
    },
    [showSubWindow]
  );

  return { showDetailWindows };
};
