import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { AxiosError } from "axios";

import CheckBox from "../../../atoms/CheckBox";
import Button from "../../../atoms/Button";
import Label from "../../../atoms/Label";
import { useModalStateContext } from "../../../../context/ModalStateProvider";
import { ApiError, Tag } from "../../../../types/types";
import { useError } from "../../../../hooks/useError";
import { useSelectedItemStateContext } from "../../../../context/SelectedItemStateProvider";
import { useTags } from "../../../../hooks/useTags";
import TableRow from "../../../atoms/TableRow";
import TableDataCell from "../../../atoms/TableDataCell";
import Table from "../../../molecules/Table";
import TableHeader from "../../../atoms/TableHeader";
import TableBody from "../../../atoms/TableBody";

const TagSelectModal: FC = () => {
  const [tags, setTags] = useState<Tag[]>();
  const [tagCategoryWidth, setTagCategoryWidth] = useState<string>("45%");
  const [tagWidth, setTagWidth] = useState<string>("50%");
  const [selectedTag, setSelectedTag] = useState<number[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const { modal, setModal } = useModalStateContext();
  const { selectedItem } = useSelectedItemStateContext();
  const { setError } = useError();
  const { getTags, getTagsByImageId, post } = useTags();

  const handleOnClose = useCallback(() => {
    setModal((prevState) => {
      return { ...prevState, tagSelectModal: false };
    });
  }, []);

  const isAllChecked = () =>
    allChecked ?? selectedTag.length === tags?.length ?? 0;

  const onClickAllChecked = useCallback(() => {
    if (allChecked) {
      setAllChecked((prevState) => !prevState);
      setSelectedTag([]);
    } else {
      setAllChecked((prevState) => !prevState);
      setSelectedTag(tags?.map((tag) => tag.id) ?? []);
    }
  }, [allChecked, tags]);

  const onClickCheckbox = useCallback(
    (id: number) => {
      const checked = selectedTag.includes(id);
      if (checked) {
        setSelectedTag((prevState) => {
          return prevState.filter((item) => item !== id);
        });
      } else {
        setSelectedTag((prevState) => {
          return [...prevState, id];
        });
      }
    },
    [selectedTag]
  );

  const handleOnSave = useCallback(() => {
    if (confirm("画像タグを更新しますか？")) {
      post(selectedItem, selectedTag);

      alert("画像タグを更新しました。");
      handleOnClose();
    }
  }, [selectedTag]);

  useEffect(() => {
    getTags()
      .then((data) => {
        setTags(data);
      })
      .catch((e) => {
        if ((e as AxiosError<ApiError>).response) {
          setError(e.response.data);
        }
      });

    //TODO: 要リファクタリング
    if (selectedItem.length === 1) {
      const selectId = selectedItem[0];
      getTagsByImageId(selectId)
        .then((res) => {
          setSelectedTag(res.map((s) => s.tag_id));
        })
        .catch((e) => {
          if ((e as AxiosError<ApiError>).response) {
            setError(e.response.data);
          } else {
            setError(e);
          }
        });
    }
  }, []);

  return (
    <Modal
      isOpen={modal.tagSelectModal}
      onClose={handleOnClose}
      autoFocus={false}
      isCentered
      motionPreset={"none"}
    >
      <ModalOverlay />
      <ModalContent
        style={{
          height: "calc(100vh - 50px)",
          minHeight: "200px",
          maxWidth: "600px",
        }}
        m={0}
      >
        <ModalHeader>
          {" "}
          <Label className="font-bold text-18px" spacing="0 0.25rem">
            タグ変更
          </Label>
        </ModalHeader>
        <ModalBody className="flex flex-1 mt-auto bottom-0">
          <Table className="full-width flex flex-grow flex-col border border-gray-line font-normal">
            <TableHeader className="flex flex-row block w-full bg-gray-background text-12px border-b border-gray-line">
              <TableRow className="flex w-full">
                <TableDataCell
                  className="inline-flex items-center justify-center border-r border-gray-line"
                  style={{ width: "29px" }}
                >
                  <CheckBox
                    margin="0"
                    checkBoxSize="12px"
                    checked={isAllChecked()}
                    onClick={onClickAllChecked}
                  />
                </TableDataCell>
                <Rnd
                  size={{ width: tagCategoryWidth, height: "auto" }}
                  style={{
                    position: "static",
                    transform: "translate(0px, 0px)",
                  }}
                  enableResizing={{ right: true }}
                  disableDragging={true}
                  maxWidth="65%"
                  minWidth="20%"
                  default={{
                    x: 30,
                    y: 0,
                    width: tagCategoryWidth,
                    height: "auto",
                  }}
                  onResize={(e, direction, ref) => {
                    setTagCategoryWidth(ref.style.width);
                    const tagWidth =
                      95 - parseFloat(ref.style.width.replace("%", ""));
                    setTagWidth(`${tagWidth}%`);
                  }}
                >
                  <TableDataCell className="w-full inline-flex items-center p-2">
                    タグ分類
                  </TableDataCell>
                </Rnd>
                <TableDataCell
                  className="inline-flex items-center p-2 border-l border-gray-line"
                  style={{ width: tagWidth }}
                >
                  タグ
                </TableDataCell>
              </TableRow>
            </TableHeader>
            <TableBody className="flex flex-col flex-grow block w-full font-sans overflow-y-auto h-0">
              {(tags ?? []).map((tag, index) => (
                <TableRow
                  className="flex w-full text-10px py-1 cursor-pointer"
                  key={index}
                  onClick={() => onClickCheckbox(tag.id)}
                >
                  <TableDataCell
                    className="inline-flex items-center justify-center"
                    style={{ width: "29px" }}
                  >
                    <CheckBox
                      margin="0"
                      checkBoxSize="12px"
                      checked={selectedTag.includes(tag.id)}
                      onClick={() => onClickCheckbox(tag.id)}
                    />
                  </TableDataCell>
                  <TableDataCell
                    className="px-2 py-1 truncate"
                    style={{ width: tagCategoryWidth }}
                  >
                    {tag.genre_name}
                  </TableDataCell>
                  <TableDataCell
                    className="px-2 py-1 truncate"
                    style={{ width: tagWidth }}
                  >
                    {tag.name}
                  </TableDataCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter className="flex flex-row justify-end">
          <Button
            width="100px"
            primary={false}
            size="small"
            onClick={handleOnClose}
            spacing="0 0.5rem"
          >
            キャンセル
          </Button>
          <Button
            width="100px"
            primary={true}
            size="small"
            spacing={0}
            onClick={handleOnSave}
          >
            保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TagSelectModal;
