import React, { FC, HTMLAttributes, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const LeftButton: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  return (
    <div
      className="inline-flex justify-center items-center h-40px w-40px border border-gray-line rounded-md cursor-pointer hover:bg-green-teal hover:bg-opacity-20"
      {...props}
    >
      <FontAwesomeIcon icon={faArrowLeft} size="lg" color="#00737C" />
    </div>
  );
};

export default memo(LeftButton);
