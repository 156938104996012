import React, { FC, memo } from "react";

import ImageCardListHeader from "../ImageCardListHeader";
import ImageCardLisFooter from "../ImageCardListFooter";
import ImageCardList from "../../ImageCardList";
import BeforeSearch from "../BeforeSearch";
import ImageNotFound from "../ImageNotFound";
import { useSearchStateContext } from "../../../../context/SearchStateProvider";
import SearchLoading from "../SearchLoading";

interface Props {
  isLoading: boolean;
}

const SearchResult: FC<Props> = ({ isLoading }) => {
  const { searchData, isSearched } = useSearchStateContext();

  return (
    <main className="flex flex-col flex-grow relative">
      <ImageCardListHeader />
      {isSearched ? (
        isLoading ? (
          <SearchLoading />
        ) : searchData && searchData.length !== 0 ? (
          <ImageCardList />
        ) : (
          <ImageNotFound />
        )
      ) : (
        <BeforeSearch />
      )}
      <ImageCardLisFooter />
    </main>
  );
};

export default memo(SearchResult);
