import React, { FC, memo, SelectHTMLAttributes } from "react";
import { NewOldType } from "../../../util/NewOldType";
import { Property } from "csstype";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  selectSize: "small" | "large";
  width?: Property.Width;
  spacing?: Property.Margin;
}

const NewOldTypeSelect: FC<Props> = ({ selectSize, ...props }) => {
  const defaultClassName =
    "font-sans border border-gray-line rounded-md py-0 text-12px focus:outline-none outline-none";
  const sizeProperty = selectSize === "small" ? "h-20px px-1" : "h-32px px-2";

  const pickNewOldType = () => {
    return [NewOldType.Ｎｏｗ, NewOldType["古いかも？"], NewOldType.古いです];
  };

  const getKeyName = (value: NewOldType) => {
    return NewOldType[value];
  };

  return (
    <select
      className={[defaultClassName, sizeProperty, props.className].join(" ")}
      style={{ width: props.width, margin: props.spacing }}
      {...props}
    >
      {pickNewOldType().map((value: NewOldType) => (
        <option value={value} key={value}>
          {getKeyName(value)}
        </option>
      ))}
    </select>
  );
};

export default memo(NewOldTypeSelect);
