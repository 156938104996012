import React, { ChangeEvent, FC, InputHTMLAttributes, memo } from "react";
import { Property } from "csstype";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  margin?: Property.Margin;
  checkBoxSize?: Property.Height | Property.Width;
}

const CheckBox: FC<Props> = ({
  className = "",
  id = "",
  checked = undefined,
  onChange = () => void 0,
  margin = "0.25rem",
  checkBoxSize = "16px",
  ...props
}) => {
  const defaultClassName =
    "text-green-teal rounded border-gray shadow-sm h-16px w-16px cursor-pointer";

  return (
    <input
      type="checkbox"
      id={id}
      className={[defaultClassName, className].join(" ")}
      checked={checked}
      style={{ margin: margin, width: checkBoxSize, height: checkBoxSize }}
      onChange={onChange}
      {...props}
    />
  );
};

export default memo(CheckBox);
