import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { useAuthContext } from "../context/AuthProvider";

interface ResponseData {
  username: string;
  permission: string[];
  sessions: {
    common: unknown;
    client: unknown;
  };
}

interface StatusApiResponse {
  status: number;
  type: string;
  data_format: string;
  data: ResponseData;
}

export const useAuth = (): { error: boolean, isLogin: boolean } => {
  const [error, setIsError] = useState<boolean>(false);
  const { user, setUser, setIsLogin, isLogin } = useAuthContext();
  const history = useHistory();

  const auth = useCallback(() => {
    const config = { withCredentials: true };

    axios
      .get<StatusApiResponse>(
        `${process.env.REACT_APP_API_URL}/auth/status`,
        config
      )
      .then((res) => {
        if (!isLogin) setIsLogin(true);
        if (user === undefined || user.username !== res.data.data.username)
          setUser({
            username: res.data.data.username,
            permission: res.data.data.permission,
          });
      })
      .catch(() => {
        setIsLogin(false);
        setUser(undefined);
        setIsError(true);
      });
  }, [user, history]);

  useEffect(() => {
    auth();
  }, []);

  return { error, isLogin };
};
